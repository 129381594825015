

import CanvasContext from "../core/context/CanvasContext"
import Color from "../core/geometry/Color"
import { toggleFullscreen } from "./fullscreen"
import { ShapeType } from "../core/tools/ShapeTool"


// TODO eliminate all "declare const" lines

declare const callController;
declare const cameraController;
declare const chatController;
declare const volumeController;
declare const uploadImageController;
declare const undoRedoController;
declare const textFormattingController;

declare const peersManager;
declare const toolManager;
declare const devicesManager;

declare const scene;
declare const sceneView; // update onPasteBtnClicked() to eliminate need for this

declare const userInterface;

declare const webRTCIsSupported;
declare const isMobileDevice;
declare const should_show_image_capture_directions_div;

declare const positionCameraDiv;
declare const sharingModalShareTab;

declare const pdfGenerator;

declare const $;



////////////////////////
// Template functions //
////////////////////////

function setHighlighterColor(ctx: CanvasContext, color: Color) {
  ctx.scene.onClickedAway();
  ctx.toolManager.highlighterTool.setColor(color);
  ctx.toolManager.switchToTool("highlighter"); // if nothing else, force UI to update
}
function setHighlighterSize(ctx: CanvasContext, size: number) {
  ctx.scene.onClickedAway();
  ctx.toolManager.highlighterTool.setSize(size);
  ctx.toolManager.switchToTool("highlighter"); // if nothing else, force UI to update
}
function setPenColor(ctx: CanvasContext, color: Color) {
  ctx.scene.onClickedAway();
  ctx.toolManager.penTool.setColor(color);
  ctx.toolManager.switchToTool("pen"); // if nothing else, force UI to update
}
function setPenSize(ctx: CanvasContext, size: number) {
  ctx.scene.onClickedAway();
  ctx.toolManager.penTool.setSize(size);
  ctx.toolManager.switchToTool("pen"); // if nothing else, force UI to update
}



//////////////////////////
// all button callbacks //
//////////////////////////
// MAKE SURE TO KEEP THIS LIST ALPHABETIZED!

function onAddPageBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('AddPageBtnClicked');
  userInterface.click('AddPageBtn');
};

function onAnswerWithVideoBtnClicked(ctx: CanvasContext) {
  if (webRTCIsSupported) {
    ctx.sharedContext.analytics.event('AnswerWithVideoBtnClicked', 'Web RTC supported');
    callController.answerCallWithVideo();
  } else {
    ctx.sharedContext.analytics.event('AnswerWithVideoBtnClicked', 'Web RTC not supported');
    callController.rejectCall();
    $("#rtc_call_not_possible_modal").modal("show");
  }
};

function onAnswerWithVoiceBtnClicked(ctx: CanvasContext) {
  if (webRTCIsSupported) {
    ctx.sharedContext.analytics.event('AnswerWithVoiceBtnClicked', 'Web RTC supported');
    callController.answerCallWithVoice();
  } else {
    ctx.sharedContext.analytics.event('AnswerWithVoiceBtnClicked', 'Web RTC not supported');
    callController.rejectCall();
    $("#rtc_call_not_possible_modal").modal("show");
  }
};

function onTextBackgroundColorBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('TextBackgroundColorBtnClicked');
}

//Camera button
function onCameraBtnClicked(ctx: CanvasContext) {
  positionCameraDiv();
  $('#selectImage_btn').removeClass('tool-btn-on');
  $('#selectInk_btn').addClass('tool-btn-on');
  $('#selectText_btn').removeClass('tool-btn-on');
  if (isMobileDevice()) {
    ctx.sharedContext.analytics.event('CameraBtnClicked', 'Taking picture');
    cameraController.beginImageCapture();
  }
  else if (should_show_image_capture_directions_div) {
    ctx.sharedContext.analytics.event('CameraBtnClicked', 'Show directions');
    $('#image_capture_directions_div').css({
      'display': 'block'
    });
  } else {
    ctx.sharedContext.analytics.event('CameraBtnClicked', 'Taking picture');
    //        $('#imageCapture_div').modal('show'); // TODO remove
    cameraController.beginImageCapture();
  }
  /*
  Note: the following line is deferred until the actual selection of part of an image
        see the definition of imagePreviewController.onSelection in BoardContext.js (or do a git grep)
  toolManager.pushToTool('camera');
  */
};

export function onCopyBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('CopyBtnClicked');
  ctx.sceneView.doCopy();
}

export function onCutBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('CutBtnClicked');
  ctx.sceneView.doCopy();
  ctx.sceneView.doDelete();
}

export function onDeleteBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('DeleteBtnClicked');
  ctx.sceneView.doDelete();
}

function onDownloadBtnClicked(ctx: CanvasContext, label) {
  var documents = scene.background.getDocumentsList();
  if (documents.length > 0) {
    ctx.sharedContext.analytics.event('DownloadBtnClicked', label.concat('; documents'), documents.length);
    pdfGenerator.download();
  } else {
    ctx.sharedContext.analytics.event('DownloadBtnClicked', label.concat('; no documents'));
    alert("There are no pages to download.");
  }
};

function onEndVideoCallBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('EndVideoCallBtnClicked');
  callController.endCall();
};

function onEndVoiceCallBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('EndVoiceCallBtnClicked');
  callController.endCall();
};

export function onEraserBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('EraserBtnClicked');
  ctx.scene.onClickedAway();
  ctx.toolManager.switchToTool('eraser');
};

export function onFullscreenToggleBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('FullscreenToggleBtnClicked');
  toggleFullscreen();
}

export function onHighlighterBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('HighlighterBtnClicked');
  ctx.scene.onClickedAway();
  ctx.toolManager.switchToTool('highlighter');
}

export function onHighlighterCyanBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('HighlighterCyanBtnClicked');
  setHighlighterColor(ctx, Color.fromCss('#00ffff', 0.4)); // cyan
}
export function onHighlighterGreenBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('HighlighterGreenBtnClicked');
  setHighlighterColor(ctx, Color.fromCss('#7fff00', 0.4)); // green
}
export function onHighlighterMagentaBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('HighlighterMagentaBtnClicked');
  setHighlighterColor(ctx, Color.fromCss('#ff00ff', 0.4)); // magenta
}
export function onHighlighterSize1BtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('HighlighterSize1BtnClicked');
  setHighlighterSize(ctx, 15);
}
export function onHighlighterSize2BtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('HighlighterSize2BtnClicked');
  setHighlighterSize(ctx, 20);
}
export function onHighlighterSize3BtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('HighlighterSize3BtnClicked');
  setHighlighterSize(ctx, 25);
}
export function onHighlighterSize4BtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('HighlighterSize4BtnClicked');
  setHighlighterSize(ctx, 30);
}
export function onHighlighterYellowBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('HighlighterYellowBtnClicked');
  setHighlighterColor(ctx, Color.fromCss('#ffff00', 0.4)); // yellow
}

function onIgnoreCallBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('IgnoreCallBtnClicked');
  callController.rejectCall();
};

function onImageBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('ImageBtnClicked');
  uploadImageController.startImageDialog();
};


/*
function onModalCloseBtnClicked() {

}*/

function onMuteBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('MuteBtnClicked');
  callController.mute();
};

export function onMyScriptBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('MyScriptBtnClicked');
  ctx.scene.onClickedAway();
  // ctx.toolManager.switchToTool('myscript');
  alert('This feature, which recognizes drawings as mathematical expressions, is not currently available for cost reasons. Thank you for understanding.');
}

export function onPanBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('PanBtnClicked');
  ctx.scene.onClickedAway();
  ctx.toolManager.switchToTool('pan');
};

export function onPasteBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('PasteBtnClicked');
  ctx.sceneView.doPaste();
}

//Pen Tool button
export function onPenBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('PenBtnClicked');
  ctx.scene.onClickedAway();
  ctx.toolManager.switchToTool('pen');
};

export function onPenBlackBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('PenBlackBtnClicked');
  setPenColor(ctx, Color.fromCss('#000000'));
}
export function onPenBlueBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('PenBlueBtnClicked');
  setPenColor(ctx, Color.fromCss('#0000ff'));
}
export function onPenGreenBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('PenGreenBtnClicked');
  setPenColor(ctx, Color.fromCss('#008000'));
}
export function onPenRedBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('PenRedBtnClicked');
  setPenColor(ctx, Color.fromCss('#ff0000'));
}

export function onPenSize1BtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('PenSize1BtnClicked');
  setPenSize(ctx, 1);
}
export function onPenSize2BtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('PenSize2BtnClicked');
  setPenSize(ctx, 2);
}
export function onPenSize3BtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('PenSize3BtnClicked');
  setPenSize(ctx, 4);
}
export function onPenSize4BtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('PenSize4BtnClicked');
  setPenSize(ctx, 9);
}

function onPrintBtnClicked(ctx: CanvasContext, label) {
  var documents = scene.background.getDocumentsList();
  if (documents.length > 0) {
    ctx.sharedContext.analytics.event('PrintBtnClicked', label.concat('; documents'), documents.length);
    pdfGenerator.print();
  } else {
    ctx.sharedContext.analytics.event('PrintBtnClicked', label.concat('; no documents'));
    alert("There are no pages to print.");
  }
}

function onRedoBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('RedoBtnClicked');
  undoRedoController.redo();
}

export function onShapeArrowToolBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('ShapeArrowToolBtnClicked');
  ctx.scene.onClickedAway();
  ctx.toolManager.shapeTool.setShape(ShapeType.arrow);
  ctx.toolManager.switchToTool('shape'); // if nothing else, force UI to update
}

export function onShapeEllipseToolBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('ShapeEllipseToolBtnClicked');
  ctx.scene.onClickedAway();
  ctx.toolManager.shapeTool.setShape(ShapeType.ellipse);
  ctx.toolManager.switchToTool('shape'); // if nothing else, force UI to update
}

export function onShapeRectangleToolBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('ShapeRectangleToolBtnClicked');
  ctx.scene.onClickedAway();
  ctx.toolManager.shapeTool.setShape(ShapeType.rectangle);
  ctx.toolManager.switchToTool('shape'); // if nothing else, force UI to update
}

export function onShapeSegmentToolBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('ShapeSegmentToolBtnClicked');
  ctx.scene.onClickedAway();
  ctx.toolManager.shapeTool.setShape(ShapeType.segment);
  ctx.toolManager.switchToTool('shape'); // if nothing else, force UI to update
}

export function onShapeToolsBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('ShapeToolsBtnClicked');
  ctx.scene.onClickedAway();
  ctx.toolManager.switchToTool('shape');
}

function onTextBoldFormattingBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('TextBoldFormattingBtnClicked');
  userInterface.widgets.S_textbold_btn.toggleClass('tool-btn-on');
  textFormattingController.toggleBold();
}

function onTextFontBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('TextFontBtnClicked');
}

function onTextFontSizeBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('TextFontSizeBtnClicked');
}

function onTextItalicFormattingBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('TextItalicFormattingBtnClicked');
}

function onTextStrikethroughBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('TextStrikethroughBtnClicked');
}

function onTextUnderlineFormattingBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('TextUnderlineFormattingBtnClicked');
}

// This method is only for analytics (as of 7/27/17) 
function onScheduleMeetingBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('ScheduleMeetingBtnClicked');
}

//Selector Tool button
export function onSelectorBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('SelectorBtnClicked');
  ctx.scene.onClickedAway();
  ctx.toolManager.switchToTool('selector');
};

function onShareBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('ShareBtnClicked');
  sharingModalShareTab();
  $("#sharing_modal").modal("show");
}

function onStartChatBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('StartChatBtnClicked');
  chatController.showWindow();
  $("#chat_textarea").focus();
};

function onStartVideoBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('StartVideoBtnClicked');
  callController.startVideo();
};

function onStartVideoCallBtnClicked(ctx: CanvasContext) {
  if (peersManager.countMyPeers() === 0) {
    ctx.sharedContext.analytics.event('StartVideoCallBtnClicked', 'No peers');
    alert("Someone else must visit this board before you can make a call.");
    //        alert("Someone else must visit this board before you can make a call. Please have them visit\n\n"+window.location.href);
    return;
  }
  if (webRTCIsSupported) {
    ctx.sharedContext.analytics.event('StartVideoCallBtnClicked', 'Web RTC supported', peersManager.countMyPeers());
    callController.startVideoCall();
  } else {
    ctx.sharedContext.analytics.event('StartVideoCallBtnClicked', 'Web RTC not supported');
    callController.rejectCall();
    $("#rtc_call_not_possible_modal").modal("show");
  }
};

function onStartVoiceCallBtnClicked(ctx: CanvasContext) {
  if (peersManager.countMyPeers() === 0) {
    ctx.sharedContext.analytics.event('StartVoiceCallBtnClicked', 'No peers');
    alert("Someone else must visit this board before you can make a call.");
    //        alert("Someone else must visit this board before you can make a call. Please have them visit\n\n"+window.location.href)
    return;
  }
  if (webRTCIsSupported) {
    ctx.sharedContext.analytics.event('StartVoiceCallBtnClicked', 'Web RTC supported', peersManager.countMyPeers());
    callController.startVoiceCall();
  } else {
    ctx.sharedContext.analytics.event('StartVoiceCallBtnClicked', 'Web RTC not supported');
    callController.rejectCall();
    $("#rtc_call_not_possible_modal").modal("show");
  }
};

function onStopVideoBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('StopVideoBtnClicked');
  callController.stopVideo();
};

function onTextAlignCenterBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('TextAlignCenterBtnClicked');
}

function onTextAlignJustifyBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('TextAlignJustifyBtnClicked');
}

export function onTextboxBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('TextboxBtnClicked');
  ctx.scene.onClickedAway();
  ctx.toolManager.switchToTool('text');
}

function onTextColorBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('TextColorBtnClicked');
}

function onTextHyperlinkBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('TextHyperlinkBtnClicked');
}

function onTextLaTeXBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('TextLaTeXBtnClicked');
}

function onTextLeftJustifyBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('TextLeftJustifyBtnClicked');
}

function onTextNormalFormattingBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('TextNormalFormattingBtnClicked');
}

function onTextRightJustifyBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('TextRightJustifyBtnClicked');
}

function onUndoBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('UndoBtnClicked');
  undoRedoController.undo();
}

function onUnmuteBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('UnmuteBtnClicked');
  callController.unMute();
};

function onVolumeDownBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('VolumeDownBtnClicked');
  volumeController.volumeDown();
};

function onVolumeUpBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('VolumeUpBtnClicked');
  volumeController.volumeUp();
};

function onVolumeOffBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('VolumeOffBtnClicked');
  volumeController.volumeOff();
};

export function onZoomInBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('ZoomInBtnClicked');
  ctx.sceneView.zoomIn();
};

export function onZoomOutBtnClicked(ctx: CanvasContext) {
  ctx.sharedContext.analytics.event('ZoomOutBtnClicked');
  ctx.sceneView.zoomOut();
};
