
import Analytics from "./Analytics"
import RealtimeService from "./realtime/RealtimeService";
import ServerInterface from "./ServerInterface";
import PdfFileManager from "../background/PdfFileManager";

/*
	The SharedContext owns all "global" singletons
	(it is shared amongst one or more CanvasContexts)
*/

export default class SharedContext {

	public readonly analytics: Analytics;
	public readonly realtimeService: RealtimeService;
	public readonly serverInterface: ServerInterface;
	public readonly pdfFileManager: PdfFileManager;

	constructor(analytics: Analytics, realtimeService: RealtimeService, serverInterface: ServerInterface) {
		this.analytics = analytics;
		this.realtimeService = realtimeService;
		this.serverInterface = serverInterface;
		this.pdfFileManager = new PdfFileManager(serverInterface);
	}

}