/*
  ItemTs are objects that belong to the Scene in the foreground layer only. ItemTs are similar to Items, but do not correspond to ItemStates as they are only transient UI objects. They are capable of responding to mouse and key input events, are likely to change state very frequently, and render differently. (They are given the canvas without the pre-applied context transformation so they can draw shapes with sizes independent of zoom.) For this reason, ItemTs are not as efficient at drawing as Items, but fortunately, it is unlikely that many ItemTs will be present at a given time.

  List of subclasses of ItemT
  + PrePathItemT
  + PreInkItemT (needs porting)
  + BoxPreSelectionItemT (needs to be updated)
  + LassoPreSelectionItemT (coming soon)
  + SelectionItemT (needs to be updated)
  + HaloItemT (needs porting)
  + ViewRectItemT (needs porting)
  + TextEditItemT (coming soon)
  + MathEditItemT (coming soon)
  + ShapeEditItemT (coming soon)

  TODO use mixins to support responding to hover, click, and key events

*/

import { Canvas } from '../Canvas'
import Scene from '../scene/Scene'
import Viewport from '../geometry/Viewport'

export default abstract class ItemT {

  constructor(public readonly devId: string) {
  }

  // these get read when the itemT is added to the scene
  public respondsToHoverEvents: boolean = false;
  public respondsToClickEvents: boolean = false;
  public respondsToKeyEvents: boolean = false;

  protected scene: Scene | null = null;

  abstract drawOnCanvas(canvas: Canvas, viewport: Viewport): void;

  public setScene(scene: Scene | null) {
    this.scene = scene;
  }

}