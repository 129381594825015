////////////////////////////////////////////////////////////////////////////////
// TextOutput has the following subclasses                                    //
// + TextTextOutput                                                           //
// + BoxTextOutput                                                            //
//                                                                            //
// TextOutputs have the following public methods                              //
// + drawOnCanvas(ctx, x, y, ascent)                                          //
// + closestChar(width)                                                       //
// + widthTo(charPos)                                                         //
// + addSvgData(svg, svgMatrix)                                               //
// + width                                                                    //
// + ascent                                                                   //
// + descent                                                                  //
//                                                                            //
// TextOutputs have the following instance variables                          //
// + type                                                                     //
// + bufferPos                                                                //
// + width                                                                    //
// + ascent                                                                   //
// + descent                                                                  //
////////////////////////////////////////////////////////////////////////////////

import BufferPosition from "../BufferPosition";

export default abstract class TextOutput {

  public bufferPos: BufferPosition
  private type: string;

  constructor(type: string, bufferPos: BufferPosition)
  {
    this.type = type;
    this.bufferPos = bufferPos;
  }

  public abstract get ascent(): number;

  public abstract get descent(): number;

  public abstract get width(): number;

  /**
   * @param charPos measure the width upto (but not including) this character
   * @return the width upto charPos, a distance in scene coordinates
   */
  public abstract widthTo(charPos: number): number;

  /**
   * return the character position closest to width
   * returns an integer between 0 and text.length
   * binary search
   */
  public abstract closestChar(width: number): number;

  public abstract drawOnCanvas(ctx: CanvasRenderingContext2D, x: number, y: number, ascent: number): void;

  // TODO implement for each subclass
  /*
  getPdfgenData(matrix, x, y, ascent) {
    return null;
  }
  */

}