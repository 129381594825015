
import { TextInputState } from "../../state/text"
import TextTextInput from "./TextTextInput"
import BeginFormattingTextInput from "./BeginFormattingTextInput";
import EndFormattingTextInput from "./EndFormattingTextInput";
import LatexTextInput from "./LatexTextInput";
import NewLineTextInput from "./NewLineTextInput";
import TextInput from "./TextInput"

export default function TextInput_fromState(state: TextInputState): TextInput {
	switch(state.t) {
		case 'text':
			return TextTextInput.fromState(state);
		case 'begin':
			return BeginFormattingTextInput.fromState(state);
		case 'end':
			return EndFormattingTextInput.fromState(state);
		case 'latex':
			return LatexTextInput.fromState(state);
		case 'nl':
			return NewLineTextInput.fromState(state);
	}
}