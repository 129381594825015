
// The purpose of LocalAgent is to relay changes to RemoteAgents on different devices.
//   (It is not responsible for taking actions that affect the scene.)

import Sender from './Sender'
import { DrawingSender } from '../channels/drawing'
import { HaloSender } from '../channels/halo'
import Agent from '../Agent'

import Color from '../../geometry/Color'
import Matrix from '../../geometry/Matrix'
import Point from '../../geometry/Point'
import Viewport from '../../geometry/Viewport'

import Messenger from '../../context/Messenger'
import Resource from '../../resources/Resource'
import Delta from '../../deltas/Delta'
import Item from '../../items/Item'
import { DocumentChangesSender } from '../channels/deltas'

export default class LocalAgent extends Agent {

	private _changesSender: DocumentChangesSender;
	private _drawingSender: DrawingSender;
	private _haloSender: HaloSender;
	
	constructor(id: string, messenger: Messenger) {
		super();
		const sender = new Sender(id, messenger);
		this._changesSender = new DocumentChangesSender(sender);
		this._drawingSender = new DrawingSender(sender);
		this._haloSender = new HaloSender(sender);
	}

	// TODO replace with more specific changes
	public addDocumentChanges(resources: Resource[], deltas: Delta[]) {
		this._changesSender.changes(resources, deltas);
	}

	//////////
	// dels //
	//////////

	public updateHaloXY(x: number, y: number) {
		this._haloSender.send(x, y);
	}

	public updateViewport(viewport: Viewport) {
		// TODO
	}

	public notifyPenDown(color: Color, size: number, point: Point) {
		this._drawingSender.down(color, size, point.x, point.y);
	}

	public notifyPenDrag(point: Point) {
		this._drawingSender.drag(point.x, point.y);
	}

	public notifyPenUp() {
		this._drawingSender.up();
	}

	public updateSelectionMatrix() {
		// TODO
	}

	////////////
	// deltas //
	////////////

	public addNewItems(resources: Resource[], items: Item[]) {
		// TODO
	}

	public selectItems(items: Item[]) {
		// TODO
	}

	public transformSelection(matrix: Matrix) {
		// TODO
	}

	public releaseSelection() {
		// TODO
	}

	public deleteItems(items: Item[]) {
		// TODO
	}

}