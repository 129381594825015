import Viewport from "../geometry/Viewport";
import { Canvas } from "../Canvas";
import Color from "../geometry/Color";
import { BackgroundState } from "../state/background";
import BackgroundDocument from "./BackgroundDocument";
import Rect from "../geometry/Rect";

export default class Background {

	public readonly state: BackgroundState;

	private color: Color;

  // data for drawing a grid
  private grid: boolean = false;
  private gridColor: Color | null = null;
	private gridDx: number = 72;
  private gridDy: number = 72;

  // data for drawing axes
  private axes: boolean = false;
  private axesColor: Color | null = null;

  private documents: BackgroundDocument[] = [];

	constructor(state: BackgroundState) {
		this.state = state;
		this.color = Color.fromState(state.color);

		for (const pattern of state.patterns) {
      if (pattern.type === 'Grid') {
        this.grid = true;
        this.gridColor = Color.fromState(pattern.color);
        this.gridDx = pattern.dx;
        this.gridDy = pattern.dy;
      }
      if (pattern.type === 'Axes') {
        this.axes = true;
        this.axesColor = Color.fromState(pattern.color);
      }
		}

		for (const docState of state.documents) {
			this.documents.push(new BackgroundDocument(docState));
		}
	}

	public drawOnCanvas(canvas: Canvas, viewport: Viewport) {
		const width = canvas.width();
		const height = canvas.height();
		const x = viewport.left;
		const y = viewport.top;
		const s = viewport.zoom;
    const ctx = canvas.getContext('2d');
    // draw the background color
    ctx.fillStyle = this.color.css;
    ctx.beginPath();
    ctx.rect(0, 0, width, height);
    ctx.fill();
    // draw the grid
    if (this.grid) {
      this._drawGrid(ctx, x, y, s, width, height);
    }
    // draw the axes
    if (this.axes) {
      this._drawAxes(ctx, x, y, s, width, height);
    }
		// draw the documents (if they overlap with viewable region)
		var viewRect = new Rect(x, y, width / s, height / s);
		for (const document of this.documents) {
      if (viewRect.intersects(document.rect)) {
        document.drawOnCanvas(canvas,
          x - document.rect.left,
          y - document.rect.top,
          s);
      }
		}
	}

	public getPositionForNewDocument(): [number, number] {
		let left = 0;
		for (const document of this.documents) {
			left = Math.max(left, document.rect.right());
		}
    return [left, 0];
  }

	private _drawGrid(ctx: CanvasRenderingContext2D, x: number, y: number, s: number, width: number, height: number) {
    ctx.save();
    ctx.strokeStyle = this.gridColor!.css;
    ctx.lineWidth = 1;
    var startx = s * (-(x % this.gridDx));
    var starty = s * (-(y % this.gridDy));
    for (var i = startx; i <= width; i += s * this.gridDx) {
      ctx.beginPath();
      ctx.moveTo(i, 0);
      ctx.lineTo(i, height);
      ctx.stroke();
    }
    for (var j = starty; j <= height; j += s * this.gridDy) {
      ctx.beginPath();
      ctx.moveTo(0, j);
      ctx.lineTo(width, j);
      ctx.stroke();
    }
    ctx.restore();
  }

	private _drawAxes(ctx: CanvasRenderingContext2D, x: number, y: number, s: number, width: number, height: number) {
    ctx.save();
    ctx.strokeStyle = this.axesColor!.css;
    ctx.lineWidth = 1;
    // draw x axis
    var xaxis = -y * s;
    ctx.beginPath();
    ctx.moveTo(0, xaxis);
    ctx.lineTo(width, xaxis);
    ctx.stroke();
    // draw y axis
    var yaxis = -x * s;
    ctx.beginPath();
    ctx.moveTo(yaxis, 0);
    ctx.lineTo(yaxis, height);
    ctx.stroke();
    ctx.restore();
	}

}