
import SceneView from '../SceneView'
import { LiveDocument } from '../document/LiveDocument'
import PenTool from '../tools/PenTool'
import Color from '../geometry/Color'
import MagicEraserTool from '../tools/MagicEraserTool'
import BoxSelectorTool from '../tools/BoxSelectorTool'
import UserInterface from './UserInterface'
import LocalAgent from '../agent/local/LocalAgent'
import PanTool from '../tools/PanTool'
import ShapeTool from '../tools/ShapeTool'
import TextTool from '../tools/TextTool'

type ToolName =
	| 'selector'
	| 'pen'
	| 'highlighter'
	|	'eraser'
	| 'text'
	| 'shape'
	| 'myscript'
	| 'pan'
	;

export default class ToolManager {

	private ui: UserInterface;
	private sceneView: SceneView;

	public readonly boxSelectorTool: BoxSelectorTool;
	public readonly penTool: PenTool;
	public readonly highlighterTool: PenTool;
	public readonly magicEraserTool: MagicEraserTool;
	public readonly panTool: PanTool;
	public readonly shapeTool: ShapeTool;
	public readonly textTool: TextTool;

	constructor(document: LiveDocument, sceneView: SceneView, ui: UserInterface, localAgent: LocalAgent) {
		this.sceneView = sceneView;

		this.boxSelectorTool = new BoxSelectorTool(document);

		this.penTool = new PenTool(document, localAgent, "pen");
		this.penTool.setSize(1);
		this.penTool.setColor(Color.fromCss('black'));

		this.highlighterTool = new PenTool(document, localAgent, "highlighter");
		this.highlighterTool.setSize(15);
		this.highlighterTool.setColor(Color.fromCss('yellow', 0.4));

		this.magicEraserTool = new MagicEraserTool(document);

		this.panTool = new PanTool(document, sceneView);

		this.shapeTool = new ShapeTool(document, this.penTool);

		this.textTool = new TextTool(document, document.svgCache, this);

		this.ui = ui;

		sceneView.tool = this.boxSelectorTool;

		ui.setToolManager(this);
	}

  public switchToTool(toolName: ToolName) {
		switch(toolName) {
			case 'selector':
				this.sceneView.tool = this.boxSelectorTool;
				break;
			case 'pen':
				this.sceneView.tool = this.penTool;
				break;
			case 'highlighter':
				this.sceneView.tool = this.highlighterTool;
				break;
			case 'eraser':
				this.sceneView.tool = this.magicEraserTool;
				break;
			case 'text':
				this.sceneView.tool = this.textTool;
				// TODO consider resetting textTool formatting
				break;
			case 'shape':
				this.sceneView.tool = this.shapeTool;
				break;
			case 'pan':
				this.sceneView.tool = this.panTool;
				break;
			default:
				// TODO unexpected
				console.log(`unexpected tool: ${ toolName }`);
		}
		if (this.sceneView.tool) {
			this.ui.onToolChange(this.sceneView.tool);
		}
	}
	
	public onPreTextItemTClickedAway() {
		if (this.sceneView.tool === this.textTool) {
			this.switchToTool('selector');
		}
	}

}