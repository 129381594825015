
export default class Point {

  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  public toArray(): [number, number] {
    return [this.x, this.y];
  }

  public static fromArray(a: [number, number]) {
    return new Point(a[0], a[1]);
  }

  public translate(dx: number, dy: number) {
    return new Point(this.x + dx, this.y + dy);
  }

}
