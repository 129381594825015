////////////////////////////////////////////////////////////////////////////////
// TextTextOutput                                                             //
////////////////////////////////////////////////////////////////////////////////

import TextInput, { TextInputType } from '../input/TextInput';
import TextOutput from './TextOutput';
import TextMetrics from '../TextMetrics';

import Point from '../../geometry/Point';
import TextFormatting from '../TextFormatting';
import BufferPosition from '../BufferPosition';
import Matrix from '../../geometry/Matrix';

export default class TextTextOutput extends TextOutput {

  private text: string;
  private formatting: TextFormatting;
  private _ascent: number;
  private _descent: number;
  private _width: number;

  /**
   * @param {string} text
   * @param {TextFormatting} formatting
   * @param bufferPos the start position in the original input buffer from
   *     which this output is extracted
   */
  constructor(text: string, formatting: TextFormatting, bufferPos: BufferPosition) {
    super(TextInputType.text, bufferPos);
    this.bufferPos = bufferPos;

    this.text = text;
    this.formatting = formatting.copy();

    const metrics = TextMetrics.getMetrics(text, this.formatting);
    this._ascent = metrics.ascent;
    this._descent = metrics.descent;
    this._width = metrics.width;
  }

  /**
   * @param x x-coordinate of top-left corner (scene coordinates)
   * @param ascent current line ascent
   */
  drawOnCanvas(ctx: CanvasRenderingContext2D, x: number, y: number, ascent: number) {
    var yBaseline = y + ascent;
    ctx.save();
    ctx.font = this.formatting.fontString;
    ctx.fillStyle = this.formatting.color.css;
    ctx.fillText(this.text, x, yBaseline);
    ctx.restore();
  }

  getPdfgenData(matrix: Matrix, x: number, y: number, ascent: number) {
    return {
      text: this.text,
      pt: matrix.timesPoint(new Point(x, y)).toArray(),
      fontSize: this.formatting.size,
      font: this.formatting.font,
    };
  }

  /**
   * return the character position closest to width
   * returns an integer between 0 and text.length
   * binary search
   */
  closestChar(width: number) {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    ctx!.font = this.formatting.fontString;
    // binary search for best cursor position
    var bestPos = -1;   // best cursor position seen so far
    var bestDist = Infinity;
    var lo = 0;
    var hi = this.text.length;
    while (hi >= lo) {
      var mid = lo + Math.floor((hi - lo) / 2);
      var curDist =
        ctx!.measureText((this.text.substring(0, mid))).width - width;
      var curDistAV = Math.abs(curDist);
      if (curDistAV < bestDist) {
        bestPos = mid;
        bestDist = curDistAV;
      }
      if (curDist < 0) {
        lo = mid + 1;
      } else {
        hi = mid - 1;
      }
    }
    return bestPos;
  }

  /**
   * @param charPos measure the width upto (but not including) this character
   * @return the width upto charPos, a distance in scene coordinates
   */
  widthTo(charPos: number) {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    ctx!.font = this.formatting.fontString;
    return ctx!.measureText(this.text.substring(0, charPos)).width;
  }

  /*
  addSvgData(svg, svgMatrix) {
    var text = svg.text(this.text);
    // formatting
    text.font('family', this.formatting.font);
    text.font('size', this.formatting.size);
    text.fill(this.formatting.color);
    // TODO: more later
    // transformation
    text.transform(new SVG.Matrix(svgMatrix));
  }
  */

  get width() {
    return this._width;
  }

  get ascent() {
    return this._ascent;
  }

  get descent() {
    return this._descent;
  }
}