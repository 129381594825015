
type svg = any;
type SvgCallback = (svg:svg)=>void;

export default class MathJaxWrapper {

  private mathJaxReady: boolean = false;
  private mathJaxReadyQueue: [string, SvgCallback][] = [];

  // in browser environment, global is window
  constructor(global) {
    if (!("MathJax" in global)) {
      global.MathJax = {
        jax: ['input/TeX', 'output/SVG'],
        extensions: ['tex2jax.js', 'MathMenu.js', 'MathZoom.js'],
        showMathMenu: false,
        showProcessingMessages: false,
        messageStyle: 'none',
        SVG: {
          useGlobalCache: false,
        },
        TeX: {
          extensions: ['AMSmath.js', 'AMSsymbols.js', 'autoload-all.js'],
        },
        AuthorInit: () => {this._onMathJaxInit();},
      };
      this._init();
    }
  }

  private mathJax(): any {
    // @ts-ignore
    return window.MathJax;
  }

  private _init() {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => {};
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.0/MathJax.js';
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  private _onMathJaxInit() {
    this.mathJax().Hub.Register.StartupHook('End', () => { this._onMathJaxReady();});
  }

  private _onMathJaxReady() {
    for (const [latex, cb] of this.mathJaxReadyQueue) {
      this._getSvg(latex, cb);
    }
    this.mathJaxReadyQueue = [];
  }

  private _getSvg(latex: string, cb: SvgCallback) {
    let wrapper = document.createElement('div');
    wrapper.innerHTML = `$$${ latex }$$`;
    this.mathJax().Hub.Queue(['Typeset', this.mathJax().Hub, wrapper]);
    this.mathJax().Hub.Queue(() => {
      let svg = wrapper.getElementsByTagName('svg')[0];
      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
      let url = new XMLSerializer().serializeToString(svg);
      let img = document.createElement('img');
      img.onload = () => { cb(img); };
      img.src = `data:image/svg+xml; charset=utf8, ${ encodeURIComponent(url) }`;
    });
  }

  public getSvg(latex: string, cb: SvgCallback) {
    if (!this.mathJaxReady) {
      this.mathJaxReadyQueue.push([latex, cb]);
    } else {
      this._getSvg(latex, cb);
    }
  }

}
