/**
 * LatexTextOutput
 *
 * Output representing a LaTeX item.  The source code and rendered LaTeX is stored.
 */

import TextOutput from './TextOutput';
import BufferPosition from '../BufferPosition';
import TextLines from '../TextLines';
import { SvgSource, SvgCache, SvgType } from '../_svg';

/**
 * @param {string} sourceCode the source code in LaTeX, without the delimiting $
 * @param bufferPos the start position in the original input buffer from
 *     which this output is extracted
 * @param {TextLines} lines the TextLines to which it belongs
 */
export default class LatexTextOutput extends TextOutput {

  private sourceCode: SvgSource;
  private svg: SvgType | null;
  private imageHasLoaded: boolean;

  constructor(sourceCode: SvgSource, bufferPos: BufferPosition, lines: TextLines, svgCache: SvgCache, onRelayout: ()=>void) {
    super('tex', bufferPos);
    // TODO: make a BufferPosition class and a copy constructor/copy() method
    this.bufferPos = bufferPos;
    this.sourceCode = sourceCode;
    // call the server for the rendered svg
    this.svg = svgCache.getSvgOrNull(this.sourceCode);
    if (this.svg) {
      this.imageHasLoaded = true;
    } else {
      this.imageHasLoaded = false;
      svgCache.getSvg(sourceCode, (theSvg) => {
        // The SVG is not used because we're throwing away this output.
        lines.layout(); // re-layout the lines to account for the actual width.
        onRelayout(); // replaces scene.redisplay() so scene is not necessary during construction
      });
    }
  }

  // static constant: how much of the image should be rendered above the baseline?
  static get proportionAboveBaseline() {
    return 0.65;
  }

  drawOnCanvas(ctx: CanvasRenderingContext2D, x: number, y: number, ascent: number) {
    if (!this.imageHasLoaded) {
      return;
    }
    var yBaseline = y + ascent;
    var drawY = yBaseline - (this.svg!.height * LatexTextOutput.proportionAboveBaseline);
    ctx.drawImage(this.svg as CanvasImageSource, x, drawY);
  }

  // putting the cursor at zero is reasonable
  closestChar() {
    return 0;
  }

  // this function isn't really useful for LaTeX, but we implement
  // the most sensible way
  widthTo(charPos: number) {
    if (charPos === 0 || !this.imageHasLoaded) {
      return 0;
    }
    return this.svg!.width;
  }

  /*
  addSvgData(svg, svgMatrix) {
    if (!this.imageHasLoaded) {
      return;
    }
    const svgData = this.svg.src;
    const rawSvg = svgData.substr(svgData.indexOf(',') + 1).trim();
    const escapedSvg = decodeURIComponent(rawSvg);
    var group = svg.group();
    group.svg(escapedSvg);
    group.transform(new SVG.Matrix(svgMatrix));
  }
  */

  get width() {
    if (!this.svg) {
      return 0; // don't render if the svg hasn't loaded
    }
    return this.svg.width;
  }

  get ascent() {
    if (!this.svg) {
      return 0; // don't render if the svg hasn't loaded
    }
    return this.svg.height * LatexTextOutput.proportionAboveBaseline;
  }

  get descent() {
    if (!this.svg) {
      return 0; // don't render if the svg hasn't loaded
    }
    return this.svg.height * (1 - LatexTextOutput.proportionAboveBaseline);
  }
}