
import Tool from './Tool'
import Viewport from '../geometry/Viewport'
import MutableScene from '../scene/MutableScene'
import Point from '../geometry/Point'
import Item from '../items/Item'
import { LiveDocument } from '../document/LiveDocument';
import DeleteItemDelta from '../deltas/DeleteItemDelta';

export default class MagicEraserTool extends Tool {

	private previousPoint: Point | null;
	private actId: string | null;

	constructor(document: LiveDocument) {
		super(document);
		this.previousPoint = null;
		this.actId = null;
	}

	public onDown(x: number, y: number, viewport: Viewport, scene: MutableScene) {
		this.previousPoint = viewport.viewToSceneMatrix().timesPoint(new Point(x,y));
		this.actId = null;
	}

	public onDrag(x: number, y: number, viewport: Viewport, scene: MutableScene) {
		let point = viewport.viewToSceneMatrix().timesPoint(new Point(x,y));
		let items: Array<Item> = []
		if (this.previousPoint) {
			items = scene.getItemsIntersectingSegment(point, this.previousPoint);
		}
		if (items.length > 0) {
			if (!this.actId) {
        this.actId = this.document.idSource.newId();
      }
			this.document.beginChanges();
			for (let item of items) {
				let delta = new DeleteItemDelta(this.actId!, item.state);
				delta.item = item; // avoid serialization and deserialization
				this.document.addDelta(delta);
			}
      this.document.endChanges();
    }
		this.previousPoint = point;
	}

	public onUp(scene: MutableScene) {
		// just to be safe...
		this.previousPoint = null;
		this.actId = null;
	}

	public getState() {
		return {
			type: "eraser",
			details: {
				subType: "magic",
			}
		}
	}

}