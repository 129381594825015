////////////////////////////////////////////////////////////////////////////////
// TextTextInput                                                              //
////////////////////////////////////////////////////////////////////////////////

import TextInput, { TextInputType } from './TextInput';
import { TextTextInputState } from '../../state/text';

export default class TextTextInput extends TextInput {

  constructor(public text: string) {
    super(TextInputType.text);
  }

  static fromState(state: TextTextInputState) {
    return new TextTextInput(state.text);
  }

  get state(): TextTextInputState {
    return {
      t: 'text',
      text: this.text,
    }
  }

  get length() {
    return this.text.length;
  }

  charAt(charPos: number) {
    return this.text.charAt(charPos);
  }

  toString(beginCharPos: number, endCharPos: number) {
    return this.text.substring(beginCharPos, endCharPos);
  }
}