
import ItemT from './ItemT'
import Rect from '../geometry/Rect'
import { Canvas } from '../Canvas'
import Viewport from '../geometry/Viewport'
import Color from '../geometry/Color'
import Point from '../geometry/Point'

export default class BoxPreSelectionItemT extends ItemT {

	private rect: Rect;

	constructor(devId: string, private pivotX: number, private pivotY: number) {
		super(devId);
		this.rect = Rect.fromXYXY(pivotX, pivotY, pivotX, pivotY);
	}

	public getRect() {
		return this.rect;
	}

	public moveCorner(x: number, y: number) {
		this.rect = Rect.fromXYXY(this.pivotX, this.pivotY, x, y);
		if (this.scene) {
			this.scene.redisplay();
		}
	}

	public drawOnCanvas(canvas: Canvas, viewport: Viewport) {
		let ul = new Point(this.rect.left, this.rect.top);
		let br = new Point(this.rect.right(), this.rect.bottom());
		ul = viewport.sceneToViewMatrix().timesPoint(ul);
		br = viewport.sceneToViewMatrix().timesPoint(br);
		let xs = [ul.x, br.x, br.x, ul.x, ul.x];
		let ys = [ul.y, ul.y, br.y, br.y, ul.y];
		canvas.save();
		canvas.setPathColor(new Color(0, 128, 255));
		canvas.setPathWidth(1);
		canvas.drawPath(xs, ys);
		canvas.restore();
	}

}