
import Resource from '../resources/Resource'
import { SvgCache, FakeSvgCache } from '../text/_svg';
import { ResourceState } from '../state/resources';
import Resource_fromState from '../resources/factory';

export default class SimpleDocument {

  // hacky way of sharing SvgCache
  public svgCache: SvgCache = new FakeSvgCache();

  protected resources: Map<string, Resource> = new Map();

  public loadResources(resourceStates: ResourceState[]) {
    for (const state of resourceStates) {
      const resource = Resource_fromState(state);
      this.resources.set(resource.id, resource);
    }
  }

  public getResources(): Resource[] {
    return Array.from(this.resources.values());
  }

  public getResource(id: string) {
    return this.resources.get(id);
  }

  get state() {
    return {
      state: 'not implemented',
    };
  }

}