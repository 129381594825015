
import Matrix from './Matrix'

export default class Viewport {

  // when looking at a canvas with a given width and height,
  // the following properties determine the region of the scene that is viewable
  public readonly left: number;
  public readonly top: number;
  public readonly zoom: number;

  constructor(left: number, top: number, zoom: number) {
    this.left = left;
    this.top = top;
    this.zoom = zoom;
  }

  public equals(that: Viewport) {
    if (this == that) {
      return true;
    }
    return this.left == that.left && this.top == that.top && this.zoom == that.zoom;
  }

  public pannedBy(dx: number, dy: number): Viewport {
    const left = this.left + dx / this.zoom;
    const top = this.top + dy / this.zoom;
    return new Viewport(left, top, this.zoom);
  }

  public zoomedTo(newZoom: number, x: number, y: number): Viewport {
    const s = 1/newZoom - 1/this.zoom;
    const left = this.left - s*x;
    const top = this.top - s*y;
    return new Viewport(left, top, newZoom);
  }

  public viewToSceneMatrix() {
    return new Matrix(1/this.zoom, 0, 0, 1/this.zoom, this.left, this.top);
  }

  public sceneToViewMatrix() {
    return new Matrix(this.zoom, 0, 0, this.zoom, -this.left*this.zoom, -this.top*this.zoom);
  }

}
