import TextTextOutput from './TextTextOutput';
import TextFormatting from '../TextFormatting';
import BufferPosition from '../BufferPosition';

export default class HyphenBreakTextOutput extends TextTextOutput {

  constructor(formatting: TextFormatting, bufferPos: BufferPosition) {
    super('-', formatting, bufferPos);
  }

  closestChar(remainingWidth: number) {
    return 0;
  }

}