
import Messenger from '../context/Messenger'
import RemoteAgent from './remote/RemoteAgent'
import Scene from '../scene/Scene'
import Document from '../document/Document'

// there's probably some work to do here as not all ApplicationMessages
//   are necessarily communicated by other agents

export type ApplicationMessage = {
	sender: string,
	channel: string,
	message: any,
};

export default class AgentManager {

	private agents: Map<string, RemoteAgent> = new Map();

	constructor(private _scene: Scene, private _document: Document, messenger: Messenger) {
		messenger.onMessage('agent', (message) => {
			this.receive(message);
		});
	}

	// all application messages (dels and deltas) go through this method
	public receive(message: ApplicationMessage) {
		let agent = this.agents.get(message.sender);
		if (!agent) {
			agent = new RemoteAgent(this._scene, this._document);
			this.agents.set(message.sender, agent);
		}
		agent.receive(message);
	}

}