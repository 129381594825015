
/*
  HaloItemT represents a small colored semi-transparent disk that indicates where another device's mouse is. If left inactive, the HaloItemT will become invisible.

*/


import ItemT from './ItemT'
import { Canvas } from '../Canvas'
import Viewport from '../geometry/Viewport'
import Point from '../geometry/Point'
import Color from '../geometry/Color';

const ACTIVE_TIME_INTERVAL = 1000; // milliseconds
const RADIUS = 10;
const ALPHA = .5;

export default class HaloItemT extends ItemT {

	private x: number = -1;
	private y: number = -1;
	private color: Color;
	private timer: NodeJS.Timeout | null = null;
	
	constructor(devId: string) {
		super(devId);
		this.color = Color.fromCss('yellow', ALPHA);
	}

	public updateLocation(x: number, y: number) {
		// update location
		this.x = x;
		this.y = y;
		// (re)set timer (must be before redisplaying scene)
		if (this.timer != null) {
			clearTimeout(this.timer);
		}
		this.timer = setTimeout(() => {
			this.timer = null;
			if (this.scene) {
				this.scene.redisplay();
			}
		}, ACTIVE_TIME_INTERVAL);
		// redisplay scene
		if (this.scene) {
			this.scene.redisplay();
		}
	}

	public hide() {
		if (this.timer != null) {
			clearTimeout(this.timer);
			this.timer = null;
			if (this.scene) {
				this.scene.redisplay();
			}
		}
	}

	public drawOnCanvas(canvas: Canvas, viewport: Viewport) {
		// don't draw if the halo is inactive
		if (this.timer == null) {
			return;
		}
		// apply transform to point
		const p = viewport.sceneToViewMatrix().timesPoint(new Point(this.x, this.y));
		// draw the halo
		canvas.save();
		canvas.setFillColor(this.color);
		canvas.drawHalo(p, RADIUS);
		canvas.restore();
	}

}
