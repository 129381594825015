
import Shape from "./Shape";
import Matrix from '../Matrix';
import Rect from '../Rect';
import Parallelogram from "../Parallelogram";
import { MinifiedEllipseShape } from '../../state/shapes';
import Point from '../Point';
import { Canvas } from "../../Canvas";
import Color from "../Color";

export default class EllipseShape extends Shape {

	public drawOnCanvas(canvas: Canvas, matrix: Matrix, factor: number = 1) {
		const ctx = canvas.getContext('2d');
		const p0 = matrix.timesPoint(this._p0);
		const p1 = matrix.timesPoint(this._p1);
		const centerX = (p0.x + p1.x) / 2;
		const centerY = (p0.y + p1.y) / 2;
		const radiusX = Math.abs(p0.x - p1.x) / 2;
		const radiusY = Math.abs(p0.y - p1.y) / 2;
		ctx.save();
		ctx.beginPath();
		ctx.strokeStyle = this._color.css;
		ctx.lineWidth = this._size * factor;
		ctx.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);
		ctx.stroke();
		ctx.restore();
	}

	public getPdfgenData(matrix: Matrix) {
		const p0 = matrix.timesPoint(this._p0);
		const p1 = matrix.timesPoint(this._p1);
		return {
			t: 'shape',
			st: 'ellipse',
			c: this._color.state,
			s: this._size,
			center: [(p0.x + p1.x) / 2, (p0.y + p1.y) / 2],
			radius: [Math.abs(p0.x - p1.x) / 2, Math.abs(p0.y - p1.y) / 2],
		};
	}

	public getBoundingRect(matrix: Matrix) {
		const p0 = matrix.timesPoint(this._p0);
		const p1 = matrix.timesPoint(this._p1);
		return Rect.fromXYXY(p0.x, p0.y, p1.x, p1.y);
	}

	public intersectsParallelogram(parallelogram: Parallelogram) {
		return true; // TODO
	}

	public minify(): MinifiedEllipseShape {
		return {
			type: 'ellipse',
			size: this._size,
			color: this._color.state,
			p0: [ this._p0.x, this._p0.y ],
			p1: [ this._p1.x, this._p1.y ],
		}
	}

	public static unminify(mini: MinifiedEllipseShape): EllipseShape {
		const shape = new EllipseShape(mini.size, Color.fromState(mini.color), mini.p0[0], mini.p0[1]);
		shape._p1 = new Point(mini.p1[0], mini.p1[1]);
		return shape;
	}

}
