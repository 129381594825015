
import Resource from './Resource'
import Point from '../geometry/Point'
import { PointBufferResourceState } from '../state/resources'
import Matrix from '../geometry/Matrix'

export default class PointBufferResource extends Resource {

  public readonly points: Array<Point>;

  constructor(id: string,
              private xs: Array<number>,
              private ys: Array<number>,
              private matrix: Matrix)
  {
    super(id);
    let points = [];
    for (let i=0; i<xs.length; i++) {
      points.push(matrix.timesPoint(new Point(xs[i], ys[i])));
    }
    this.points = points;
  }

  public static fromState(state: PointBufferResourceState) {
    return new PointBufferResource(state.id, state.x, state.y, Matrix.fromState(state.m));
  }

  get state(): PointBufferResourceState {
    return {
      t: 'points',
      id: this.id,
      x: this.xs,
      y: this.ys,
      m: this.matrix.state,
    }
  }

}