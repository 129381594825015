
import PreShapeItemT from '../itemTs/PreShapeItemT';
import Shape from '../geometry/shapes/Shape';
import EllipseShape from '../geometry/shapes/EllipseShape';
import RectangleShape from '../geometry/shapes/RectangleShape';
import SegmentShape from '../geometry/shapes/SegmentShape';
import Tool from './Tool';
import DrawingTool from './DrawingTool';
import Matrix from '../geometry/Matrix';
import MutableScene from '../scene/MutableScene';
import Viewport from '../geometry/Viewport';
import { LiveDocument } from '../document/LiveDocument';
import ShapeItem from '../items/ShapeItem';
import AddItemDelta from '../deltas/AddItemDelta';
import Point from '../geometry/Point';

export enum ShapeType {
	segment = 'segment',
	arrow = 'arrow',
	rectangle = 'rectangle',
	ellipse = 'ellipse',
}

export default class ShapeTool extends Tool {

	private _preShapeItemT: PreShapeItemT | null = null;
	private _shapeType: ShapeType = ShapeType.segment;

	constructor(document: LiveDocument, private _styleTool: DrawingTool) {
		super(document);
	}

	public setShape(shape: ShapeType) {
		this._shapeType = shape;
	}

	public onDown(x: number, y: number, viewport: Viewport, scene: MutableScene) {
		const p = viewport.viewToSceneMatrix().timesPoint(new Point(x, y));
		const size = this._styleTool.getSize();
		const color = this._styleTool.getColor();
		let shape: Shape | null = null;
		switch(this._shapeType) {
			case ShapeType.arrow:
				shape = new SegmentShape(size, color, p.x, p.y, 1);
				break;
			case ShapeType.ellipse:
				shape = new EllipseShape(size, color, p.x, p.y);
				break;
			case ShapeType.rectangle:
				shape = new RectangleShape(size, color, p.x, p.y);
				break;
			case ShapeType.segment:
				shape = new SegmentShape(size, color, p.x, p.y);
				break;
		}
		if (shape) {
			this._preShapeItemT = new PreShapeItemT(this.document.idSource.deviceId, shape);
			scene.addForefrontItem(this._preShapeItemT);
		}
	}

	public onDrag(x: number, y: number, viewport: Viewport) {
		if (this._preShapeItemT) {
			const p = viewport.viewToSceneMatrix().timesPoint(new Point(x, y));
			this._preShapeItemT.updateXY(p.x, p.y);
		}
	}

	public onUp(scene: MutableScene) {
		if (this._preShapeItemT) {
			scene.beginChanges();

			let shape = this._preShapeItemT.shape;
			let item = new ShapeItem(this.document.idSource.newId(), Matrix.identityMatrix(), shape);

			let delta = new AddItemDelta(this.document.idSource.newId(), item.state);
			delta.item = item; // avoid serialization and deserialization
			this.document.addDelta(delta);

			scene.removeForefrontItem(this._preShapeItemT);
			this._preShapeItemT = null;
			
			this.document.endChanges();
		}
	}

	public getState() {
		return {
			type: "shape",
			details: {
				subType: this._shapeType,
			},
		};
	}

}