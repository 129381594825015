
export default [
	{
		name: "File",
		items: [
			{ text: "Share...", onclick: () => {}, shortcut:"shortcut-file-invite" },
			{ text: "New Board", onclick: () => {}, shortcut:"shortcut-file-new" },
			{ text: "Duplicate Board", onclick: () => {}, shortcut:"shortcut-file-duplicate" },
			{ text: "Open Board", onclick: () => {}, shortcut:"shortcut-file-open" },
			{ text: "Print Pages", onclick: () => {}, shortcut:"shortcut-file-print" },
			{ text: "Pair with Tablet", onclick: () => {}, shortcut:"shortcut-file-pair" },
		],
	},
	{
		name: "Edit",
		items: [
			{ text: "Undo", onclick: () => {}, shortcut:"shortcut-edit-undo" },
			{ text: "Redo", onclick: () => {}, shortcut:"shortcut-edit-redo" },
			{ text: "Cut", onclick: () => {}, shortcut:"shortcut-edit-cut" },
			{ text: "Copy", onclick: () => {}, shortcut:"shortcut-edit-copy" },
			{ text: "Paste", onclick: () => {}, shortcut:"shortcut-edit-paste" },
			{ text: "Select All", onclick: () => {}, shortcut:"shortcut-edit-selectall" },
		],
	},
	{
		name: "View",
		items: [
			{ text: "Zoom In", onclick: () => {}, shortcut:"shortcut-view-zoomin" },
			{ text: "Zoom Out", onclick: () => {}, shortcut:"shortcut-view-zoomout" },
			{ text: "Zoom 100%", onclick: () => {}, shortcut:"shortcut-view-zoomdefault" },
			{ text: "Full Screen Mode", onclick: () => {}, shortcut:"shortcut-view-fullscreen" },
			{ text: "Go to Page...", onclick: () => {}, shortcut:"shortcut-view-gotopage" },
		],
	},
	{
		name: "Insert",
		items: [
			{ text: "Image", onclick: () => {}, shortcut:"shortcut-insert-image" },
			{ text: "Page", onclick: (ctx) => {ctx.actions.addPage()}, shortcut:"shortcut-insert-page" },
			{ text: "PDF Document", onclick: (ctx) => {ctx.actions.uploadPdf()}, shortcut:"shortcut-insert-pdf" },
		],
	},
	{
		name: "Export",
		items: [
			{ text: "Export Selection as PNG", onclick: () => {}, shortcut:"shortcut-export-exportselectionpng" },
			// the following has a span with classname .exportselectionsvg in addition to .shortcut (why?)
			{ text: "Export Selection as SVG", onclick: () => {}, shortcut:"shortcut-export" },
			// note the shortcut name contains "file"
			{ text: "Download Pages", onclick: () => {}, shortcut:"shortcut-file-download" },
		],
	},
	{
		name: "Settings",
		items: [
			{ text: "Webcam Scan", onclick: () => {}, shortcut:"shortcut-settings-webcamscan" },
		],
	},
	{
		name: "Help",
		items: [
			{ text: "Manual", onclick: () => {}, shortcut:"shortcut-manual" },
		],
	},
];