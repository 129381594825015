import React from "react"

const css = require("../toolbar.module.css");
import fa from "../../lib/fontawesome"
const texImg = require("../icons/tex.png");

export default () => (
	<div
		className={css.horizontalButtons}
		style={{position: "fixed", left:"45px", top:"37px"}}
	>
		<button className={css.btn}>
			<i className={`${fa.fa} ${fa.faBold}`} />
		</button>
		<button className={css.btn}>
			<img style={{width:"28px", height:"28px", marginLeft:"-5px"}} src={texImg} />
		</button>
	</div>
);