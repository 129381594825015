
import { ResourceState } from '../state/resources'
import PointBufferResource from './PointBufferResource'

// this helps avoid a circular dependency in which Resource must import eg. PointBufferResource
export default function Resource_fromState(state: ResourceState) {
	switch(state.t) {
		case 'points':
			return PointBufferResource.fromState(state);
	}
}