import React, { useState, useEffect } from "react"

import Button from "react-bootstrap/Button"
import From from "react-bootstrap/Form"
import Modal from "react-bootstrap/Modal"

import fa from "../lib/fontawesome"

import boards from "../../../../services/lib/boards"


const styling = {
	width: "100px",
	height: "20px",
	fontSize: "12px",
	lineHeight: "1.5",
	border: "1px #ccc solid",
	padding: "0 0 0 0",
};

type Props = {
	boardUrlId: string,
}

export const ShareBtn = (props: Props) => {
	const [state, setState] = useState({ modal: false, private: false, anyoneWithLinkCanEdit: false });

	const handleShow = () => {
		(async () => {
			const board = boards.getBoard(props.boardUrlId);
			const anyoneWithLinkCanEdit = await board.getAnyoneWithLinkCanEdit();
			setState({
				...state,
				modal: true,
				private: !anyoneWithLinkCanEdit,
				anyoneWithLinkCanEdit,
			})
		})();
		setState({...state, modal: true });
	}
	const handleClose = () => setState({...state, modal: false });
	const saveAndClose = () => {
		setState({...state, modal: false });
		const board = boards.getBoard(props.boardUrlId);
		board.setAnyoneWithLinkCanEdit(state.anyoneWithLinkCanEdit);
	}

	const change = (event) => {
		const anyoneWithLinkCanEdit = event.target.id === "link_edit";
		setState({...state, private: !anyoneWithLinkCanEdit, anyoneWithLinkCanEdit });
	}

	const port = window.location.port.length > 0 ? `:${window.location.port}` : "";
	const shareUrl = `${window.location.protocol}//${window.location.hostname}${port}/board/${props.boardUrlId}`;

	return (
		<>
			<div style={{position:"fixed", top:"70px", right:"5px"}}>
				<button style={styling} onClick={handleShow}>
					<i className={`${fa.fa} ${fa.faUserPlus}`} />
					&nbsp;
					share
				</button>
			</div>
			<Modal show={state.modal} onHide={handleClose}>
				<Modal.Header closeButton>
          <Modal.Title>Sharing</Modal.Title>
        </Modal.Header>
				<Modal.Body>
					<div>
						<input type="radio" name="sharing" id="private" checked={state.private} onChange={change} />
						<label htmlFor="private">&nbsp;Private (only you can access this board)</label>
						<br />
						<input type="radio" name="sharing" id="link_edit" checked={state.anyoneWithLinkCanEdit} onChange={change}/>
						<label htmlFor="link_edit">&nbsp;Anyone with the link can edit</label>
						<br />
						{ state.anyoneWithLinkCanEdit &&
							<From.Control type="text" readOnly value={shareUrl} />
						}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveAndClose}>
            Save and Close
          </Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}