import Page from "./Page";
import { BlankPageState, PageState } from "../state/background";
import Rect from "../geometry/Rect";
import { Canvas } from "../Canvas";

export default class BlankPage extends Page {

	constructor(state: BlankPageState) {
		super(state.uid, Rect.fromState(state.rect));
	}

	public get state(): BlankPageState {
		return {
			uid: this.uid,
			type: 'blank',
			rect: this.rect.state,
			color: { r: 255, g: 255, b: 255 },
		}
	}

	public drawOnCanvas(canvas: Canvas, x: number, y: number, s: number): void {
		  // find the view in non-scaled page coordinates
			var viewRect = new Rect(x, y, canvas.width() / s, canvas.height() / s);
			var ctx = canvas.getContext('2d');
			ctx.save();
			ctx.fillStyle = 'white';
			ctx.strokeStyle = 'black';
			// find the page rect in non-scaled page coordinates
			var rect = new Rect(0, 0, this.rect.width, this.rect.height);
			if (viewRect.intersects(rect)) {
				var left = (rect.left - x) * s;
				var top = (rect.top - y) * s;
				var width = rect.width * s;
				var height = rect.height * s;
				ctx.beginPath();
				ctx.rect(left, top, width, height);
				ctx.fill();
				ctx.stroke();
			}
			ctx.restore();
	}

}