/*
 * TextTool is irresponsible
 */

import Matrix from '../geometry/Matrix';
import Point from '../geometry/Point';
import Rect from '../geometry/Rect';
import PreTextItemT from '../itemTs/PreTextItemT';
import TextFormatting, { FormattingObject } from '../text/TextFormatting';
import Tool from './Tool';
import Viewport from '../geometry/Viewport';
import MutableScene from '../scene/MutableScene';
import InteractiveScene from '../scene/InteractiveScene';
import { LiveDocument } from '../document/LiveDocument';
import { SvgCache } from '../text/_svg';
import Scene from '../scene/Scene';
import ToolManager from '../context/ToolManager';

export default class TextTool extends Tool {

  constructor(document: LiveDocument, private _svgCache: SvgCache, private _toolManager: ToolManager) {
    super(document);
  }

  private formatting: TextFormatting = new TextFormatting();

  private applyFormatting(preTextItemT: PreTextItemT) {
    // Have the preTextItemT make its mutableText begin changes so that
    // only one change event is fired.
    preTextItemT.mutableText.beginChanges();
    const formats = this.formatting.formats();
    for (const format of formats) {
      preTextItemT.insertFormatting(format);
    }
    preTextItemT.mutableText.endChanges();
  }

  public toggleBold() {
    this.formatting.bold = !this.formatting.bold;
  }

  /**
   * If textTool is the primary tool and the user clicks, then textTool.onDown()
   * is called.
   * onDown(x, y) checks if there is a text item containing (x, y).  If so, it treats it just as if
   * it had been selected with the selector tool.
   * Otherwise, it constructs an empty textbox whose box has top-left
   * corner at (x, y), the position clicked.
   * @param {Number} x the x-coordinate clicked (in Scene coordinates)
   * @param {Number} y the y-coordinate clicked (in Scene coordinates)
   */
  public onDown(x: number, y: number, viewport: Viewport, scene: MutableScene) {
    const intersectingTextItems = scene.getItemsIntersectingRect(Rect.fromXYXY(x, y, x, y))
      .filter(item => item.state.t === "text");
    if (intersectingTextItems.length) {
      // TODO handle case when clicking into text (see commented code below)
      // select the last text item
      /*
      const item = intersectingTextItems[intersectingTextItems.length - 1];
      const actId = this.document.idSource.newId();
      var delta = new GrabItemsDelta(
        actId,
        devicesManager.getMyDeviceId(),
        devicesManager.getMyDeviceId(),
        [{ id: item.id, devId: item.devId }],
        Matrix.identityMatrix(),
        GrabItemsDelta.intents.PreTextItemT,
        new Point(x, y));
      delta.applyToScene();
      boardStateManager.addDelta(delta);
      devicesManager.enqueueDelta(delta);
      devicesManager.send();
      */
    } else {
      const devId = this.document.idSource.deviceId;
      const preTextItemT = new PreTextItemT(devId, x, y, null, null, null, scene as Scene, this._svgCache, this._toolManager, this.document);
      this.applyFormatting(preTextItemT); // TODO maybe this belongs in the constructor of PreTextItemT
      scene.addForefrontItem(preTextItemT);
      (scene as InteractiveScene).setActiveClickResponder(preTextItemT);
      // broadcast a del that we've created an item
      /*
      messenger.broadcastDel({
        type: 'textBegin',
        new: delManager.newDelN(),
        x: x,
        y: y,
      });
      */
    }
  }

  public onDrag() {}

  public onUp() {}

  public getCursor() {
    return 'text';
  }

  public getState() {
    return {
      type: "text",
      details: {
        // TODO
      },
    };
  }

}