import React from "react"

const css = require("../toolbar.module.css");
import fa from "../../lib/fontawesome"

import {
	onCutBtnClicked,
	onCopyBtnClicked,
	onPasteBtnClicked,
	onDeleteBtnClicked,
} from "../../../ui/buttonCallbacks"

export default ({addCtx, selectionAvailable, clipboardAvailable}) => (
	<div
		className={css.horizontalButtons}
		style={{position: "fixed", left:"45px", top:"37px"}}
	>
		<button
			className={css.btn}
			onClick={addCtx(onCutBtnClicked)}
			disabled={!selectionAvailable}
		>
			<i className={`${fa.fa} ${fa.faScissors}`} />
		</button>
		<button
			className={css.btn}
			onClick={addCtx(onCopyBtnClicked)}
			disabled={!selectionAvailable}
		>
			<i className={`${fa.fa} ${fa.faFilesO}`} />
		</button>
		<button
			className={css.btn}
			onClick={addCtx(onPasteBtnClicked)}
			disabled={!clipboardAvailable}
		>
			<i className={`${fa.fa} ${fa.faClipboard}`} />
		</button>
		<button
			className={css.btn}
			onClick={addCtx(onDeleteBtnClicked)}
			disabled={!selectionAvailable}
		>
			<i className={`${fa.fa} ${fa.faTrash}`} />
		</button>
	</div>
);