
import ToolManager from './ToolManager'
import Tool from '../tools/Tool';

export default abstract class UserInterface {

	protected tm: ToolManager | null = null;

	public setToolManager(tm: ToolManager) {
		this.tm = tm;
	}

	public abstract onToolChange(tool: Tool): void;

	public abstract selectionAvailable(): void;
	public abstract selectionNotAvailable(): void;
	public abstract clipboardDataAvailable(): void;
	
	public abstract uploadPdf(): void;

}