
import { WebRTCDataConnection, make_connection } from "./webrtc/WebRTCDataConnection"
import Channel from "./Channel";
import WebsocketClient from "./websockets/WebsocketClient";

type Message = Object;

export default class Device {

	private _channels: { [channelId: string]: Channel } = {};
	private _channelMessageQueue: { [channelId: string]: Message[]} = {};
	private _directConnectionInitiated: boolean = false;
	private _dataConnection: WebRTCDataConnection|null = null; // will be null for this device
	private _websocketClient: WebsocketClient;

	constructor(
		public readonly deviceId: string,
		public socketId: string|null,
		public readonly isThisDevice: boolean,
		websocketClient: WebsocketClient
	) {
		this._websocketClient = websocketClient;
		if (!isThisDevice) {
			this._dataConnection = make_connection(deviceId, (message: Message) => {
				this.sendMessageViaServer("webrtc", message);
			});
			this._dataConnection.signals.onObject = (obj: any) => {
				if ("channelId" in obj && "message" in obj) {
					this._dispatchMessage(obj.channelId, obj.message);
				}
			};
		}
	}

	// to be called only by Channel when adding device
	public addChannel(channel: Channel) {
		this._channels[channel.channelId] = channel;
		// play through queued messages
		if (channel.channelId in this._channelMessageQueue) {
			for (let message of this._channelMessageQueue[channel.channelId]) {
				channel.receive(message);
			}
		}
		delete this._channelMessageQueue[channel.channelId];
	}

	public initiateDirectConnection() {
		if (!this._directConnectionInitiated) {
			this._directConnectionInitiated = true;
			if (this._dataConnection) {
				this._dataConnection.makeCall();
			}
		}
	}

	public sendMessageDirectly(channelId: string, message: Message): boolean {
		if (this._dataConnection) {
			return this._dataConnection.sendObject({ channelId, message });
		} else {
			return false;
		}
	}

	// no harm in making this public
	private sendMessageViaServer(channelId: string, message: Message) {
		if (this.socketId !== null) {
			this._websocketClient.send(message, channelId, [this.socketId]);
		}
	}

	public notifyRelayFromServer(channelId: string, message: any) {
		if (channelId.startsWith("webrtc")) {
			if (this._dataConnection) {
				this._dataConnection.onMessageViaServer(message);
			}
		} else {
			this._dispatchMessage(channelId, message);
		}
	}

	private _dispatchMessage(channelId: string, message: any) {
		if (channelId in this._channels) {
			this._channels[channelId].receive(message);
		} else {
			// we don't have a record of the device being in the channel yet
			// so we queue up the messages to be relayed once the device joins the channel
			if (channelId in this._channelMessageQueue) {
				this._channelMessageQueue[channelId].push(message);
			} else {
				this._channelMessageQueue[channelId] = [message];
			}
		}
	}

}