import React from "react"

const css = require("./toolbar.module.css");
import fa from "../lib/fontawesome"
const panImg = require("./icons/pan.png");

import {
	onPanBtnClicked,
	onZoomInBtnClicked,
	onZoomOutBtnClicked,
	onFullscreenToggleBtnClicked,
} from "../../ui/buttonCallbacks"

export default ({tool, addCtx}) => (
	<div
		className={css.verticalButtons}
		style={{position: "fixed", left:"5px", top:"322px"}}
	>
		<button
			className={`${css.btn} ${tool.type==="pan" && css.btnOn}`}
			onClick={addCtx(onPanBtnClicked)}
		>
			<img style={{width:"28px", height:"28px", marginLeft:"-5px"}} src={panImg} />
		</button>
		<button
			className={css.btn}
			onClick={addCtx(onZoomInBtnClicked)}
		>
			<i className={`${fa.fa} ${fa.faSearchPlus}`} />
		</button>
		<button
			className={css.btn}
			onClick={addCtx(onZoomOutBtnClicked)}
		>
			<i className={`${fa.fa} ${fa.faSearchMinus}`} />
		</button>
		<button
			className={`${css.btn}`} // TODO (minor) consider adding css.btnOn when in fullscreen mode (require state change for BoardContext)
			onClick={addCtx(onFullscreenToggleBtnClicked)}
		>
			<i className={`${fa.fa} ${fa.faArrowsAlt}`} />
		</button>
	</div>
);