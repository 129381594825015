
import MutableScene from './MutableScene'
import Viewport from '../geometry/Viewport'
import ItemT from '../itemTs/ItemT'
import SelectionItemT from '../itemTs/SelectionItemT';
import Matrix from '../geometry/Matrix';
import { ItemState } from '../state/items';
import Rect from '../geometry/Rect';
import Point from '../geometry/Point';
import SceneView from '../SceneView';
import AddItemDelta from '../deltas/AddItemDelta';
import GrabItemsDelta from "../deltas/GrabItemsDelta";
import Item_fromState from '../items/factory';
import Item from '../items/Item';
import { KeyEvent, MouseEvent } from '../Canvas';

export interface HoverResponder {
	acceptsHover(x: number, y: number, viewport: Viewport): boolean;
	getCursor(x: number, y: number, viewport: Viewport): string;
}

export interface ClickResponder {
	acceptsClick(x: number, y: number, viewport: Viewport): boolean;
	onDown(x: number, y: number, viewport: Viewport, event: MouseEvent): void;
	onDrag(x: number, y: number, viewport: Viewport): void;
	onUp(): void;
	onClickedAway(): void;
}

export interface KeyResponder {
  onKeyDown(event: KeyEvent): boolean;
}

export interface ClipboardData {
  matrix: Matrix,
  itemStates: ItemState[],
  rect: Rect
}

export default class InteractiveScene extends MutableScene {

	private _hoverResponders: HoverResponder[] = [];

	private _clickResponders: ClickResponder[] = [];
  private _activeClickResponder: ClickResponder | null = null;

  private _keyResponders: KeyResponder[] = [];

  // TODO this blongs in a SharedContext
  private _clipboardData: ClipboardData | null = null;
  
  public removeForefrontItem(itemT: ItemT) {
    super.removeForefrontItem(itemT);
    // remove itemT as event responder
    if (itemT.respondsToHoverEvents) {
      this.removeHoverResponder(itemT as any as HoverResponder);
    }
    if (itemT.respondsToClickEvents) {
      this.removeClickResponder(itemT as any as ClickResponder);
    }
    if (itemT.respondsToKeyEvents) {
      this.removeKeyResponder(itemT as any as KeyResponder);
    }
  }

  public onClickedAway() {
    if (this._activeClickResponder) {
      this._dropActiveClickResponder();
      this._activeClickResponder = null;
    }
  }

	// Adds the responder to the front of the list.
	public addHoverResponder(responder: HoverResponder) {
		this._hoverResponders.unshift(responder);
	}

	public removeHoverResponder(responder: HoverResponder) {
		var i = this._hoverResponders.indexOf(responder);
		if (i > -1) {
			this._hoverResponders.splice(i, 1);
		}
	}

	// Adds the responder to the front of the list.
	public addClickResponder(responder: ClickResponder) {
		this._clickResponders.unshift(responder);
	}

	public removeClickResponder(responder: ClickResponder) {
		var i = this._clickResponders.indexOf(responder);
		if (i > -1) {
			this._clickResponders.splice(i, 1);
		}
		if (responder === this._activeClickResponder) {
      this._dropActiveClickResponder(false);
			this._activeClickResponder = null;
		}
	}

	// Adds the responder to the front of the list.
	public addKeyResponder(responder: KeyResponder) {
		this._keyResponders.unshift(responder);
	}

	public removeKeyResponder(responder: KeyResponder) {
		var i = this._keyResponders.indexOf(responder);
		if (i >= -1) {
			this._keyResponders.splice(i, 1);
		}
	}

	// Used when the activeClickResponder should point to a newly created item
  //   (so that it can receive onClickedAway() if the user clicks away)
  public setActiveClickResponder(newClickResponder: ClickResponder) {
    // There likely shouldn't be an activeClickResponder, because this method probably indicates a tool is being used.
    if (this._activeClickResponder && this._activeClickResponder !== newClickResponder) {
      this._dropActiveClickResponder();
    }
    this._activeClickResponder = newClickResponder;
    if (this._activeClickResponder instanceof SelectionItemT) {
      for (let sv of this.sceneViews) {
        sv.ui.selectionAvailable();
      }
    }
  }

  private _dropActiveClickResponder(notify: boolean = true) {
    if (this._activeClickResponder) {
      if (this._activeClickResponder instanceof SelectionItemT) {
        for (let sv of this.sceneViews) {
          sv.ui.selectionNotAvailable();
        }
      }
      if (notify) {
        this._activeClickResponder.onClickedAway();
      }
    }
  }

	///////////////////////////
	// events from SceneView //
	///////////////////////////

  // internal, calls acceptsHover() for each hoverResponder (in order)
  // As soon as a hoverResponder accepts the hover, it is returned.
  // If no hoverResponder accepts the hover, null is returned.
  private _getHoverResponder(x: number, y: number, viewport: Viewport) {
    for (var i = 0 ; i < this._hoverResponders.length; i++) {
      if (this._hoverResponders[i].acceptsHover(x, y, viewport)) {
        return this._hoverResponders[i];
      }
    }
    return null;
  }

  // returns the cursor to display above the Scene
  public onHover(x: number, y: number, viewport: Viewport) {
    const hoverResponder = this._getHoverResponder(x, y, viewport);
    if (hoverResponder) {
      return hoverResponder.getCursor(x, y, viewport);
    } else {
      return 'default';
    }
  }

	// internal, calls acceptsClick() for each clickResponder (in order)
  // 	as soon as a clickResponder accepts the click, it is returned.
  // 	if no clickResponder accepts the click, null is returned.
  private _getClickResponder(x: number, y: number, viewport: Viewport) {
    for (var i = 0; i < this._clickResponders.length; i++) {
      if (this._clickResponders[i].acceptsClick(x, y, viewport)) {
        return this._clickResponders[i];
      }
    }
    return null;
  }

  // returns true if the scene wants a mouse grab (meaning a clickResponder accepted the click), false otherwise
  public onDown(x: number, y: number, viewport: Viewport, event: MouseEvent) {
    const oldClickResponder = this._activeClickResponder;
    const newClickResponder = this._getClickResponder(x, y, viewport);
    if (oldClickResponder && oldClickResponder !== newClickResponder) {
      this._dropActiveClickResponder();
    }
    this._activeClickResponder = newClickResponder;
    if (this._activeClickResponder) {
      this._activeClickResponder.onDown(x, y, viewport, event);
      return true;
    } else {
      return false;
    }
  }

  public onDrag(x: number, y: number, viewport: Viewport) {
    if (this._activeClickResponder) {
      this._activeClickResponder.onDrag(x, y, viewport);
    }
  }

  public onUp() {
    if (this._activeClickResponder) {
      this._activeClickResponder.onUp();
    }
  }

  public onKeyDown(event: KeyEvent): boolean {
    for (const keyResponder of this._keyResponders) {
      if (keyResponder.onKeyDown(event)) {
        return true;
      }
    }
    return false;
  }

  public doCopy() {
    const selectionItemT = this.getForefrontItem(this.document.idSource.deviceId, SelectionItemT);
    if (selectionItemT) {
      this._clipboardData = selectionItemT.copyItemStates();
      for (let sv of this.sceneViews) {
        sv.ui.clipboardDataAvailable();
      }
      return true;
    }
    return false;
  }

  public doPaste(sceneView: SceneView) {
    if (this._clipboardData) {
      // be careful not to mutate itemsData
      const itemsData = this._clipboardData;

      // compute location for paste
      let matrix: Matrix;
      const selectionItemT = this.getForefrontItem(this.document.idSource.deviceId, SelectionItemT);
      if (selectionItemT) {
        // paste near existing selection
        // TODO: should check that paste location is within the screen
        const OFFSET = 10;
        const upperLeftPoint = selectionItemT.getUpperLeftPoint();
        const targetUpperLeftPoint = new Point(upperLeftPoint.x + OFFSET, upperLeftPoint.y + OFFSET);
        selectionItemT.onClickedAway();
        const curPoint = new Point(itemsData.rect.left, itemsData.rect.top);
        matrix = Matrix.translateMatrix(targetUpperLeftPoint.x - curPoint.x, targetUpperLeftPoint.y - curPoint.y);
      } else {
        // paste in center
        const viewRect = sceneView.getViewRect();
        const itemsWidth = itemsData.rect.width;
        const itemsHeight = itemsData.rect.height;
        var x = (viewRect.left + (viewRect.width - itemsWidth) / 2 ) - itemsData.rect.left;
        var y = (viewRect.top + (viewRect.height - itemsHeight) / 2) - itemsData.rect.top;
        matrix = Matrix.translateMatrix(x, y);
      }

      // Make a copy of all the selected items
      const actId = this.document.idSource.newId();
      const devId = this.document.idSource.deviceId;
      this.beginChanges();
      const itemIds: string[] = [];
      const items: Item[] = [];
      for (let originalItemState of itemsData.itemStates) {
        const itemState: ItemState = {
          ...originalItemState,
          id: this.document.idSource.newId(),
          m: matrix.times(Matrix.fromState(originalItemState.m)).state,
        };
        itemIds.push(itemState.id);
        const delta = new AddItemDelta(actId, itemState);
        delta.item = Item_fromState(itemState, this.document);
        items.push(delta.item);
        this.document.addDelta(delta);
      }
      const delta = new GrabItemsDelta(actId, devId, itemIds, Matrix.identityMatrix());
      delta.items = items;
      this.document.addDelta(delta);

      this.endChanges();
      return true;
    }
    return false;
  }

  public doDelete() {
    const selectionItemT = this.getForefrontItem(this.document.idSource.deviceId, SelectionItemT);
    if (selectionItemT) {
      selectionItemT.erase();
      return true;
    }
    return false;
  }

}