
import AbstractServerInterface from "../core/context/ServerInterface"
import { putFile, getFile } from "../../../services/lib/send";
import boards from "../../../services/lib/boards"

/*
	Notes on pdf.js installation
	+ the most out-of-the-box solution is to require pdfjs-dist/webpack
	+ this solution also requires installing the module worker-loader, which sets up the web worker script
		(see https://github.com/mozilla/pdf.js/tree/master/examples/webpack)
	+ when running on Gatsby, the worker-loader tries to use fs and module
		to get those to work, I needed to add configuration to gatsby-node.js
*/
import * as PDFJS from "pdfjs-dist/webpack"

export default class ServerInterface extends AbstractServerInterface {

	public isNewBoard(boardUrlId: string): boolean {
		const board = boards.getBoard(boardUrlId);
		return board.isNew;
	}

	public async saveBoardState(state: string, boardUrlId: string) {
		const board = boards.getBoard(boardUrlId);
		const putUrl = await board.putUrl();
		putFile(state, putUrl);
		board.isNew = false;
	}

	public async getBoardState(boardUrlId: string): Promise<string> {
		const board = boards.getBoard(boardUrlId);
		const getUrl = await board.getUrl();
		return await getFile(getUrl);
	}

	public async uploadPdfFile(file: File, boardUrlId: string): Promise<{pdfUrlId: string}> {
		const board = boards.getBoard(boardUrlId);
		const { putUrl, urlId } = await board.pdfPutUrl();
		await putFile(file, putUrl);
		return { pdfUrlId: urlId };
	}

	public async getPdfFile(pdfUrlId: string, boardUrlId: string) {
		const board = boards.getBoard(boardUrlId);
		const getUrl = await board.pdfGetUrl(pdfUrlId);
		return PDFJS.getDocument(getUrl).promise;
	}

}