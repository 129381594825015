
import DrawingTool from './DrawingTool'
import AddItemDelta from '../deltas/AddItemDelta'
import { LiveDocument } from '../document/LiveDocument'
import LinearPath from '../geometry/path/LinearPath'
import PathItem from '../items/PathItem'
import PrePathItemT from '../itemTs/PrePathItemT'
import Matrix from '../geometry/Matrix'
import LocalAgent from '../agent/local/LocalAgent'

export default class PenTool extends DrawingTool {

  constructor(document: LiveDocument, localAgent: LocalAgent, stateType: string) {
    super(document, localAgent, stateType);
  }

  public onPathDrawFinished(prePathItemT: PrePathItemT) {
    this.document.beginChanges();

    // make the resource
    let pointBufferResource = prePathItemT.makePointBufferResource(this.document.idSource.newId());
    this.document.addResource(pointBufferResource);

    // make the item
    let path = new LinearPath(pointBufferResource);
    let item = new PathItem(this.document.idSource.newId(), Matrix.identityMatrix(), path, this.color, this.size);

    // make the delta
    let delta = new AddItemDelta(this.document.idSource.newId(), item.state);
    delta.item = item; // avoid serialization and deserialization
    this.document.addDelta(delta);

    this.document.endChanges();
  }

}