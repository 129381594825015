
import Tool from './Tool'
import Viewport from '../geometry/Viewport'
import MutableScene from '../scene/MutableScene'
import Point from '../geometry/Point'
import BoxPreSelectionItemT from '../itemTs/BoxPreSelectionItemT'
import GrabItemsDelta from '../deltas/GrabItemsDelta'
import Matrix from '../geometry/Matrix'

export default class BoxSelectorTool extends Tool {

	private boxPreSelectionItemT: BoxPreSelectionItemT | null = null;

	public onDown(x: number, y: number, viewport: Viewport, scene: MutableScene) {
		let p = viewport.viewToSceneMatrix().timesPoint(new Point(x, y));
		this.boxPreSelectionItemT = new BoxPreSelectionItemT(this.document.idSource.deviceId, p.x, p.y);
		scene.addForefrontItem(this.boxPreSelectionItemT);
	}

	public onDrag(x: number, y: number, viewport: Viewport, scene: MutableScene) {
		let p = viewport.viewToSceneMatrix().timesPoint(new Point(x, y));
		if (this.boxPreSelectionItemT) {
			this.boxPreSelectionItemT.moveCorner(p.x, p.y);
		}
	}

	public onUp(scene: MutableScene) {
		if (this.boxPreSelectionItemT) {
			let rect = this.boxPreSelectionItemT.getRect();
			scene.beginChanges();
			scene.removeForefrontItem(this.boxPreSelectionItemT);
			this.boxPreSelectionItemT = null;
			// Find the items and select them.
			const itemList = scene.getItemsIntersectingRect(rect);
			if (itemList.length > 0) {
				/* TODO: when supporting text, handle click inside TextItem
				let textPoint = null;
				let items;
				let intent;
				if (rect.width === 0 && rect.height === 0) {
					// if the event was a click, check whether the point clicked is
					// contained in a TextItem
					// We don't use for...of because order matters (if more than one
					// TextItem is clicked, only the frontmost one should be selected).
					for (let i = itemList.length - 1; i >= 0; i--) {
						const item = itemList[i];
						if (item.state.type === Item.types.TextItem) {
							// then user intends to edit an existing text item
							intent = GrabItemsDelta.intents.PreTextItemT;
							items = [{ id: item.id, devId: item.devId }];
							textPoint = new Point(x0, y0);
							break;
						}
					}
				}
				if (!textPoint) {
					items = itemList.map(item => { return { id: item.id, devId: item.devId }; });
					// determine intent
					intent = GrabItemsDelta.intents.SelectionItemT; // default intent
				}
				if (DEBUG_MODE) {
					console.log('BoxSelectorTool: intent = ', intent);
				}
				*/
				let itemIds: string[] = [];
				for (let item of itemList) {
					itemIds.push(item.id);
				}
				var delta = new GrabItemsDelta(
					this.document.idSource.newId(),
					this.document.idSource.deviceId,
					itemIds,
					Matrix.identityMatrix()
				);
				delta.items = itemList;
				this.document.addDelta(delta);
			}
			scene.endChanges();
		}
	}

	public getState() {
		return {
			type: "selector",
			details: {
				subType: "box",
			}
		}
	}

}