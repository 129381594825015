
import { Canvas, ICanvasEventHandler, IMouseEventHandler, IWheelEventHandler, IKeyEventHandler } from '../../core/Canvas'
import Color from '../../core/geometry/Color'
import Point from '../../core/geometry/Point'
import Viewport from '../../core/geometry/Viewport'
import Matrix from '../../core/geometry/Matrix'
import Rect from '../../core/geometry/Rect'

export default class BrowserCanvas extends Canvas {

  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;

  constructor(canvas: HTMLCanvasElement) {
    super();
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d')!;
    this.resizeCanvasElement();
  }

  // this is very bad and does not belong! (it was added to speed up migration)
  public getContext(type: string) { // TODO remove!!
    return this.canvas.getContext(type);
  }  

  private resizeCanvasElement() {
    let devicePixelRatio = window.devicePixelRatio || 1;
    this.canvas.width = this.canvas.clientWidth * devicePixelRatio;
    this.canvas.height = this.canvas.clientHeight * devicePixelRatio;
    this.ctx.scale(devicePixelRatio, devicePixelRatio);
  }

  public reset(color: Color) {
    this.ctx.fillStyle = color.css;
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }

  public save() {
    this.ctx.save();
  }

  public restore() {
    this.ctx.restore();
  }

  public width() {
    return this.canvas.clientWidth;
  }

  public height() {
    return this.canvas.clientHeight;
  }

  public transform(viewport: Viewport) {
    this.ctx.scale(viewport.zoom, viewport.zoom);
    this.ctx.translate(-viewport.left, -viewport.top);
  }

  public transformMatrix(m: Matrix) {
    this.ctx.transform(m.a, m.b, m.c, m.d, m.e, m.f);
  }

  public translate(dx: number, dy: number) {
    this.ctx.translate(dx, dy);
  }

  public setPathColor(color: Color) {
    this.ctx.strokeStyle = color.css;
    this.ctx.globalAlpha = color.opacity;
  }

  public setPathWidth(width: number) {
    this.ctx.lineWidth = width;
  }

  public drawPath(xs: Array<number>, ys: Array<number>, lineCap: CanvasLineCap = 'round') {
    const ctx = this.ctx;
    ctx.beginPath();
    ctx.lineCap = lineCap;
    ctx.moveTo(xs[0], ys[0]);
    for (let i = 1; i < xs.length; i++) {
      ctx.lineTo(xs[i], ys[i]);
    }
    ctx.stroke();
  }

  public drawLinearPath(points: Array<Point>, lineCap: CanvasLineCap = 'round') {
    const ctx = this.ctx;
    ctx.beginPath();
    ctx.lineCap = lineCap;
    ctx.moveTo(points[0].x, points[0].y);
    for (let i = 1; i< points.length; i++) {
      ctx.lineTo(points[i].x, points[i].y);
    }
    ctx.stroke();
  }

  public drawHalo(center: Point, radius: number) {
    const ctx = this.ctx;
    ctx.beginPath();
    ctx.arc(center.x, center.y, radius, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();
  }

  public setFillColor(color: Color) {
    this.ctx.fillStyle = color.css;
    this.ctx.globalAlpha = color.opacity;
  }

  public fillRect(r: Rect) {
    this.ctx.fillRect(r.left, r.top, r.width, r.height);
  }

  public registerCanvasEvents(handler: ICanvasEventHandler) {
    // TODO don't rely on window resize (set up a short timer to watch for resizes)
    window.addEventListener('load', () => {
      this.resizeCanvasElement();
      handler.onCanvasLoaded();
    });
    window.addEventListener('resize', () => {
      this.resizeCanvasElement();
      handler.onCanvasResized();
    });
  }

  public registerMouseEvents(handler: IMouseEventHandler) {
    // note: mousedown event is for the canvas element
    //       mousemove, mouseup are for window element
    //         but do nothing unless the mousedown event happened
    var dragging = false;
    this.canvas.addEventListener('mousedown', (event) => {
      if (event.button === 0) { // only handle left-click for now
        const bounds = this.canvas.getBoundingClientRect();
        const x = event.clientX - bounds.left;
        const y = event.clientY - bounds.top;
        handler.onMouseDown(x, y, event);
        dragging = true;
      }
    });
    window.addEventListener('mousemove', (event) => {
      const bounds = this.canvas.getBoundingClientRect();
      const x = event.clientX - bounds.left;
      const y = event.clientY - bounds.top;
      if (dragging) {
        handler.onMouseDrag(x, y);
      } else {
        handler.onMouseHover(x, y);
      }
    });
    window.addEventListener('mouseup', (event) => {
      if (dragging) {
        if (event.button === 0) {
          handler.onMouseUp();
          dragging = false;
        }
      }
    });
  }

  public registerWheelEvents(handler: IWheelEventHandler) {
    this.canvas.addEventListener('wheel', (event) => {
      // how much to multiply delta[XY] by if deltaMode == 1
      const ZOOM_DELTA_PIXELS_PER_LINE = 12;
      const PAN_DELTA_PIXELS_PER_LINE = 18;
      // translation of deltaY pixels to percentage point zoom change
      const ZOOM_SCALING_FACTOR = 0.01;
      if (event.ctrlKey) {
        let dy = event.deltaY;
        if (event.deltaMode === 1) { // delta measured in lines (Firefox)
          dy *= ZOOM_DELTA_PIXELS_PER_LINE;
        }
        let bounds = this.canvas.getBoundingClientRect();
        let x = event.clientX - bounds.left;
        let y = event.clientY - bounds.top;
        handler.onZoom(x, y, 1 - dy * ZOOM_SCALING_FACTOR);
      } else {
        let dx = event.deltaX;
        let dy = event.deltaY;
        if (event.deltaMode === 1) { // delta measured in lines (Firefox)
          dx *= PAN_DELTA_PIXELS_PER_LINE;
          dy *= PAN_DELTA_PIXELS_PER_LINE;
        }
        handler.onPan(dx, dy);
      }
      event.preventDefault();
    });
  }

  public registerKeyEvents(handler: IKeyEventHandler) {
    // WARNING: event listener was added to window -- can something better be done?
    window.addEventListener('keydown', (event) => {
      const accepted = handler.onKeyDown(event);
      if (accepted) {
        event.preventDefault();
      }
    });
  }

}