
// this is an object that can be exposed to the user interface to trigger actions within the CanvasContext
// the goal is to avoid importing a bunch of classes from within sw-drawing into the external UI code

import InteractiveScene from "../scene/InteractiveScene";
import { LiveDocument } from "../document/LiveDocument";
import { DocumentState, BackgroundState } from "../state/background";
import IdSource from "../IdSource";
import ChangeBackgroundDelta from "../deltas/ChangeBackgroundDelta";
import UserInterface from "./UserInterface";

export default class Actions {

	private _scene: InteractiveScene;
	private _document: LiveDocument;
	private _idSource: IdSource;
	private _ui: UserInterface;

	constructor(scene: InteractiveScene, document: LiveDocument, idSource: IdSource, ui: UserInterface) {
		this._scene = scene;
		this._document = document;
		this._idSource = idSource;
		this._ui = ui;
	}

	public addPage() {
		const background = this._scene.getBackground();
    var pos = background.getPositionForNewDocument();
    var mini: DocumentState = {
      uid: this._idSource.newId(),
      rect: [pos[0], pos[1], 0, 0],
      pages: [
        {
          uid: this._idSource.newId(),
          type: 'blank',
          rect: [0, 0, 8.5 * 72, 11 * 72],
          color: { r: 255, g: 255, b: 255 },
        },
      ],
      fileIds: [],
      layoutMode: 'book',
		};
		
		const from: BackgroundState = {...background.state};
		const to = {
			...from,
			documents: [
				...from.documents,
				mini,
			]
		};

    var delta = new ChangeBackgroundDelta(this._idSource.newId(), from, to);
			
		this._document.addDelta(delta);
	}

	public uploadPdf() {
		this._ui.uploadPdf();
	}

}