import Page from "./Page";
import { PdfPageState } from "../state/background";
import Rect from "../geometry/Rect";
import { Canvas } from "../Canvas";

export default class PdfPage extends Page {

	private _fileId: string;
	private _index: number;

	constructor(state: PdfPageState) {
		super(state.uid, Rect.fromState(state.rect));
		this._fileId = state.fileId;
		this._index = state.index;
	}
	
	public get state(): PdfPageState {
		return {
			uid: this.uid,
			type: 'pdf',
			fileId: this._fileId,
			index: this._index,
			rect: this.rect.state,
		}
	}

	public drawOnCanvas(canvas: Canvas, x: number, y: number, s: number): void {
		// find the view in non-scaled page coordinates
		var viewRect = new Rect(x, y, canvas.width() / s, canvas.height() / s);
		var ctx = canvas.getContext('2d');
		ctx.save();
		ctx.fillStyle = 'grey';
		ctx.strokeStyle = 'black';
		// find the page rect in non-scaled page coordinates
		var rect = new Rect(0, 0, this.rect.width, this.rect.height);
		if (viewRect.intersects(rect)) {
			var left = (rect.left - x) * s;
			var top = (rect.top - y) * s;
			var width = rect.width * s;
			var height = rect.height * s;
			ctx.beginPath();
			ctx.rect(left, top, width, height);
			ctx.fill();
			ctx.stroke();
		}
		ctx.restore();
}

}