
import Agent from '../Agent'
import { ApplicationMessage } from '../AgentManager'
import Channel from '../channels/Channel'
import { HaloReceiverChannel } from '../channels/halo'
import Scene from '../../scene/Scene'
import HaloItemT from '../../itemTs/HaloItemT'
import { DrawingReceiverChannel } from '../channels/drawing'
import Color from '../../geometry/Color'
import PrePathItemT from '../../itemTs/PrePathItemT'
import Viewport from '../../geometry/Viewport'
import { DocumentChangesReceiverChannel } from '../channels/deltas'
import Resource from '../../resources/Resource'
import Delta from '../../deltas/Delta'
import Document from '../../document/Document'

export default class RemoteAgent extends Agent {

	private channels: Map<string, Channel> = new Map();

	constructor(private _scene: Scene, private _document: Document) {
		super();
		const haloItemT = new HaloItemT('garbage'); // TODO remove need for id in constructor
		this.haloItemT = haloItemT;
		_scene.addForefrontItem(haloItemT);
		this._setupHaloChannel();
		this._setupDrawingChannel();
		this._setupChangesChannel();
	}

	private _setupHaloChannel() {
		const channel = new HaloReceiverChannel(
			(x: number, y: number) => {
				this.haloItemT!.updateLocation(x, y);
			}
		)
		this.channels.set('halo', channel);
	}

	private _setupDrawingChannel() {
		const channel = new DrawingReceiverChannel(
			(color: Color, size: number, x: number, y: number) => {
				const viewport = new Viewport(0, 0, 1);
				const prePathItemT = new PrePathItemT('garbage', color, size, viewport);
				prePathItemT.addXY(x, y, viewport);
				this._scene.beginChanges();
				this._scene.addForefrontItem(prePathItemT);
				this.haloItemT!.updateLocation(x, y);
				this._scene.endChanges();
				this.prePathItemT = prePathItemT;
			},
			(x: number, y: number) => {
				if (this.prePathItemT) {
					const viewport = new Viewport(0, 0, 1);
					this._scene.beginChanges();
					this.haloItemT!.updateLocation(x, y);
					this.prePathItemT.addXY(x, y, viewport);
					this._scene.endChanges();
				}
			},
			() => {
				if (this.prePathItemT) {
					this._scene.removeForefrontItem(this.prePathItemT);
					this.prePathItemT = null;
				}
			}
		);
		this.channels.set('draw', channel);
	}

	private _setupChangesChannel() {
		const channel = new DocumentChangesReceiverChannel(
			(resources: Resource[], deltas: Delta[]) => {
				this._document.beginChanges();
				this._scene.beginChanges();
				for (let resource of resources) {
					resource.local = false;
					this._document.addResource(resource);
				}
				for (let delta of deltas) {
					delta.local = false;
					delta.populate(this._scene, this._document);
					this._document.addDelta(delta);
				}
				this._scene.endChanges();
				this._document.endChanges();
			}
		);
		this.channels.set('changes', channel);
	}

	// all application messages (dels and deltas) from this agent go through this method
	public receive(message: ApplicationMessage) {
		// TODO enqueue messages before Agent is "present"
		let channel = this.channels.get(message.channel);
		if (channel) {
			channel.receive(message.message);
		}
	}

}