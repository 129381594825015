
import Shape from '../geometry/shapes/Shape';
import Viewport from '../geometry/Viewport';
import { Canvas } from "../Canvas";
import Scene from "../scene/Scene";
import ItemT from './ItemT';

// should inherit from ItemT, but ItemT is not a proper class
export default class PreShapeItemT extends ItemT {

	// properties required for all ItemTs
	public scene: Scene | null = null;
	public respondsToHoverEvents = false;
	public respondsToClickEvents = false;
	public respondsToKeyEvents = false;

	constructor(devId: string, public shape: Shape) {
		super(devId);
	}

	public updateXY(x: number, y: number) {
		this.shape.updateXY(x, y);
		if (this.scene) {
			this.scene.redisplay();
		}
	}

	public drawOnCanvas(canvas: Canvas, viewport: Viewport) {
		this.shape.drawOnCanvas(canvas, viewport.sceneToViewMatrix(), viewport.zoom);
	}

	public setScene(scene: Scene | null) {
		this.scene = scene;
	}

}