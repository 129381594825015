
// The Canvas class represents the drawing medium
// a particular subclass of Canvas will wrap the native html <canvas> element

import Viewport from './geometry/Viewport'
import Color from './geometry/Color'
import Point from './geometry/Point'
import Matrix from './geometry/Matrix'
import Rect from './geometry/Rect'

export interface ICanvasEventHandler {
  onCanvasLoaded(): void;
  onCanvasResized(): void;
}

export type MouseEvent = {
  shiftKey: boolean,
}

export interface IMouseEventHandler {
  onMouseDown(x: number, y: number, event: MouseEvent): void;
  onMouseDrag(x: number, y: number): void;
  onMouseHover(x: number, y: number): void;
  onMouseUp(): void;
}

export interface IWheelEventHandler {
  onPan(dx: number, dy: number): void;
  onZoom(x: number, y: number, factor: number): void;
}

export type KeyEvent = {
  key: string,
  metaKey: boolean,
  shiftKey: boolean,
  ctrlKey: boolean,
  altKey: boolean,
}

export interface IKeyEventHandler {
  onKeyDown(event: KeyEvent): boolean; // should return whether key was handled
}

export abstract class Canvas {

  // this is very bad and does not belong! (it was added to speed up migration)
  public abstract getContext(type: string): any; // TODO remove!!

  public abstract reset(color: Color): void; // fill canvas with color
  public abstract save(): void;
  public abstract restore(): void;

  public abstract width(): number;
  public abstract height(): number;

  /*
    canvas.transform(viewport) replaces the following from the old code
      ctx.scale(zoom, zoom);
      ctx.translate(-left, -top);
  */
  public abstract transform(viewport: Viewport): void;
  public abstract transformMatrix(matrix: Matrix): void;
  public abstract translate(dx: number, dy: number): void;
  public abstract setPathColor(color: Color): void;
  public abstract setPathWidth(width: number): void;
  public abstract drawPath(xs: Array<number>, ys: Array<number>, lineCap?: CanvasLineCap): void;
  public abstract drawLinearPath(points: Array<Point>, lineCap?: CanvasLineCap): void;
  public abstract drawHalo(center: Point, radius: number): void;
  public abstract setFillColor(color: Color): void;
  public abstract fillRect(rect: Rect): void;

  public abstract registerCanvasEvents(handler: ICanvasEventHandler): void;
  public abstract registerMouseEvents(handler: IMouseEventHandler): void;
  public abstract registerWheelEvents(handler: IWheelEventHandler): void;
  public abstract registerKeyEvents(handler: IKeyEventHandler): void;

}