
import MutableScene from '../scene/MutableScene'
import Viewport from '../geometry/Viewport'
import { LiveDocument } from '../document/LiveDocument'
import IdSource from '../IdSource'

export default abstract class Tool {

  protected document: LiveDocument;

  constructor(document: LiveDocument) {
    this.document = document;
  }

  abstract onDown(x: number, y: number, viewport: Viewport, scene: MutableScene): void;
  abstract onDrag(x: number, y: number, viewport: Viewport, scene: MutableScene): void;
  abstract onUp(scene: MutableScene): void;

  // can be used by UI to determine how to color/display buttons
  abstract getState(): { type: string, details?: object };

}