
import { Canvas } from '../Canvas'
import Point from '../geometry/Point'
import Rect from '../geometry/Rect'
import Matrix from '../geometry/Matrix'
import { ItemState } from '../state/items'
import SimpleScene from '../scene/SimpleScene'

export default abstract class Item {

  public id: string;
  public scene: SimpleScene | null;

  // a subclass may override this and set it to false
  //   if false, then drawOnCanvas() also requires a matrix
  public scaleInvariant: boolean = true;

  // Represents the transformation of the item.
  //   This transformation is applied to the item, so if it is scale by 2, then the item appears twice as large.
  private _matrix: Matrix;
  private _inverseMatrix: Matrix | null;
  private _boundingRect: Rect | null;
  
  constructor(id: string, matrix: Matrix) {
    this.id = id;
    this.scene = null;
    this._matrix = matrix;
    this._inverseMatrix = null;
    this._boundingRect = null;
  }

  public abstract get state(): ItemState;

  get matrix() {
    return this._matrix;
  }

  set matrix(matrix: Matrix) {
    this._matrix = matrix;
    this._inverseMatrix = null;
    this._boundingRect = null;
  }

  protected abstract _computeBoundingRect(): Rect;

  public getBoundingRect() {
    if (!this._boundingRect) {
      this._boundingRect = this._computeBoundingRect();
    }
    return this._boundingRect!;
  }

  get inverseMatrix() {
    if (!this._inverseMatrix) {
      this._inverseMatrix = this._matrix.inverse();
    }
    return this._inverseMatrix!;
  }

  public abstract drawOnCanvas(canvas: Canvas, matrix: Matrix): void;

  public abstract intersectsRect(rect: Rect): boolean;
  public abstract intersectsSegment(end1: Point, end2: Point): boolean;

}