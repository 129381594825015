
import InteractiveScene from '../scene/InteractiveScene'
import { DeltaState } from '../state/deltas'
import { LiveDocument } from '../document/LiveDocument'
import SimpleScene from '../scene/SimpleScene'
import SimpleDocument from '../document/SimpleDocument'

type DeltaType =
  | 'bg'
  | 'add'
  | 'grab'
  | 'trans'
  | 'release'
  | 'del'
  ;

export default abstract class Delta {

  // specifies whether this device created the delta
  //   defaults to true, recipient is responsible for setting to false
  //   should not be serialized
  public local: boolean = true;

  constructor(protected type: DeltaType,
              protected actId: string)
  {}

  public abstract get state(): DeltaState;

  public abstract inverse(actId: string): Delta;

  // attempt to populate objects which could be null (this should be called after deserializing)
  public abstract populate(scene: SimpleScene, document: SimpleDocument): void;

  public abstract applyToScene(scene: InteractiveScene, document: LiveDocument): void;

}