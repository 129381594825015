
import Delta from './Delta'
import GrabItemsDelta from './GrabItemsDelta'
import Item from '../items/Item'
import MutableScene from '../scene/MutableScene'
import Matrix from '../geometry/Matrix'
import { ReleaseItemsDeltaState } from '../state/deltas'
import SelectionItemT from '../itemTs/SelectionItemT'
import InteractiveScene from '../scene/InteractiveScene'

export default class ReleaseItemsDelta extends Delta {

	public selectionItemT: SelectionItemT | null = null;

	constructor(actId: string,
							private devId: string,
							private itemIds: string[],
							private finalMatrix: Matrix,
							)
	{
		super('release', actId);
	}

	public get state(): ReleaseItemsDeltaState {
    return {
			t: 'release',
			a: this.actId,
			h: this.devId,
			i: this.itemIds,
			m: this.finalMatrix.state,
    }
	}
	
	public static fromState(state: ReleaseItemsDeltaState) {
		return new ReleaseItemsDelta(state.a, state.h, state.i, Matrix.fromState(state.m));
	}

	public populate(scene: InteractiveScene) {
		if (this.selectionItemT === null) {
			this.selectionItemT = scene.getForefrontItem(this.devId, SelectionItemT);
		}
	}

	public inverse(actId: string) {
		let delta = new GrabItemsDelta(actId, this.devId, this.itemIds, this.finalMatrix);
		if (this.selectionItemT) {
			delta.items = this.selectionItemT.items;
		}
		return delta;
	}

	public applyToScene(scene: MutableScene) {
		if (this.selectionItemT) {
			scene.beginChanges();
			this.selectionItemT.returnItemsToScene();
			scene.removeForefrontItem(this.selectionItemT);
			scene.endChanges();
		}
	}

}