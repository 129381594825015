
import Delta from './Delta'
import Item from '../items/Item'
import Matrix from '../geometry/Matrix'
import InteractiveScene from '../scene/InteractiveScene'
import SelectionItemT from '../itemTs/SelectionItemT'
import { TransformItemsDeltaState } from '../state/deltas'
import SimpleScene from '../scene/SimpleScene';

export default class TransformItemsDelta extends Delta {

	private selectionItemT: SelectionItemT | null = null;

	constructor(actId: string,
							private holderDevId: string,
							private fromMatrix: Matrix,
							private toMatrix: Matrix,
	)
	{
		super('trans', actId);
	}

	public get state(): TransformItemsDeltaState {
    return {
			t: 'trans',
			a: this.actId,
			h: this.holderDevId,
			fm: this.fromMatrix.state,
			tm: this.toMatrix.state,
    }
	}
	
	public static fromState(state: TransformItemsDeltaState) {
		return new TransformItemsDelta(state.a, state.h, Matrix.fromState(state.fm), Matrix.fromState(state.tm));
	}

	public populate(scene: InteractiveScene) {
		if (this.selectionItemT === null) {
			this.selectionItemT = scene.getForefrontItem(this.holderDevId, SelectionItemT);
		}
	}

	public inverse(actId: string) {
		let delta = new TransformItemsDelta(actId, this.holderDevId, this.toMatrix.copy(), this.fromMatrix.copy());
		delta.selectionItemT = this.selectionItemT;
		return delta;
	}

	public applyToScene(scene: InteractiveScene) {
		if (this.selectionItemT) {
			this.selectionItemT.setMatrix(this.toMatrix.copy());
		}
	}

}