
import AbstractAnalytics from "../core/context/Analytics"

// if this is set, then the console will log Google Analytics messages instead of sending
const DEBUG_MODE = false;

const categories = {
  actions: {
    AddPageBtnClicked: 'AddPageBtnClicked',
    DownloadBtnClicked: 'DownloadBtnClicked',
    FullscreenToggleBtnClicked: 'FullscreenToggleBtnClicked',
    ImageBtnClicked: 'ImageBtnClicked',
    PanBtnClicked: 'PanBtnClicked',
    PrintBtnClicked: 'PrintBtnClicked',
    RedoBtnClicked: 'RedoBtnClicked',
    UndoBtnClicked: 'UndoBtnClicked',
    ZoomInBtnClicked: 'ZoomInBtnClicked',
    ZoomOutBtnClicked: 'ZoomOutBtnClicked',
  },
  answerCall: {
    IgnoreCallBtnClicked: 'IgnoreCallBtnClicked',
    AnswerWithVideoBtnClicked: 'AnswerWithVideoBtnClicked',
    AnswerWithVoiceBtnClicked: 'AnswerWithVoiceBtnClicked',
  },
  call: {
    EndVideoCallBtnClicked: 'EndVideoCallBtnClicked',
    EndVoiceCallBtnClicked: 'EndVoiceCallBtnClicked',
    MuteBtnClicked: 'MuteBtnClicked',
    StartChatBtnClicked: 'StartChatBtnClicked',
    StartVideoBtnClicked: 'StartVideoBtnClicked',
    StartVideoCallBtnClicked: 'StartVideoCallBtnClicked',
    StartVoiceCallBtnClicked: 'StartVoiceCallBtnClicked',
    StopVideoBtnClicked: 'StopVideoBtnClicked',
    UnmuteBtnClicked: 'UnmuteBtnClicked',
    VolumeDownBtnClicked: 'VolumeDownBtnClicked',
    VolumeUpBtnClicked: 'VolumeUpBtnClicked',
    VolumeOffBtnClicked: 'VolumeOffBtnClicked',
  },
  camera: {
    CameraBtnClicked: 'CameraBtnClicked',
    CameraSettingsBtnClicked: 'CameraSettingsBtnClicked',
    PairTabletBtnClicked: 'PairTabletBtnClicked',
    image_capture_directions_cancel: 'image_capture_directions_cancel',
    image_capture_directions_ready: 'image_capture_directions_ready',
    image_capture_cancel: 'image_capture_cancel',
  },
  eraserDetails: {
  },
  image_preview: {
    image_preview_retake: 'image_preview_retake',
    image_preview_cancel: 'image_preview_cancel',
    image_preview_select: 'image_preview_select',
    image_preview_selectall: 'image_capture_selectall',
  },
  issues: {
    SocketDisconnect: 'SocketDisconnect',
  },
  highlighterDetails: {
    HighlighterCyanBtnClicked: 'HighlighterCyanBtnClicked',
    HighlighterGreenBtnClicked: 'HighlighterGreenBtnClicked',
    HighlighterMagentaBtnClicked: 'HighlighterMagentaBtnClicked',
    HighlighterSize1BtnClicked: 'HighlighterSize1BtnClicked',
    HighlighterSize2BtnClicked: 'HighlighterSize2BtnClicked',
    HighlighterSize3BtnClicked: 'HighlighterSize3BtnClicked',
    HighlighterSize4BtnClicked: 'HighlighterSize4BtnClicked',
    HighlighterYellowBtnClicked: 'HighlighterYellowBtnClicked',
  },
  menubar: {
    MenubarInviteCollaboratorsBtn: 'MenubarInviteCollaboratorsBtn',
    MenubarNewBoardBtn: 'MenubarNewBoardBtn',
    MenubarDuplicateBoardBtn: 'MenubarDuplicateBoardBtn',
    MenubarOpenBoardBtn: 'MenubarOpenBoardBtn',
    MenubarBoardDetailsBtn: 'MenubarBoardDetailsBtn',
    MenubarPrintPagesBtn: 'MenubarPrintPagesBtn',
    MenubarDownloadPagesBtn: 'MenubarDownloadPagesBtn',
    MenubarPairWithTabletBtn: 'MenubarPairWithTabletBtn',
    MenubarUndoBtn: 'MenubarUndoBtn',
    MenubarRedoBtn: 'MenubarRedoBtn',
    MenubarCutBtn: 'MenubarCutBtn',
    MenubarCopyBtn: 'MenubarCopyBtn',
    MenubarPasteBtn: 'MenubarPasteBtn',
    MenubarSelectAllBtn: 'MenubarSelectAllBtn',
    MenubarZoomInBtn: 'MenubarZoomInBtn',
    MenubarZoomOutBtn: 'MenubarZoomOutBtn',
    MenubarZoomDefaultBtn: 'MenubarZoomDefaultBtn',
    MenubarFullScreenBtn: 'MenubarFullScreenBtn',
    MenubarGotoPageBtn: 'MenubarGotoPageBtn',
    MenubarInsertImageBtn: 'MenubarInsertImageBtn',
    MenubarAddPageBtn: 'MenubarAddPageBtn',
    MenubarInsertPDFBtn: 'MenubarInsertPDFBtn',
    MenubarExportSelectionPNGBtn: 'MenubarExportSelectionPNGBtn',
    MenubarExportSelectionSVGBtn: 'MenubarExportSelectionSVGBtn',
    MenubarWebcamScanSettingsBtn: 'MenubarWebcamScanSettingsBtn',
    MenubarManualBtn: 'MenubarManualBtn',
  },
  misc: {
    PermissionsBtnClicked: 'PermissionsBtnClicked',
    ScheduleMeetingBtnClicked: 'ScheduleMeetingBtnClicked',
    ShareBtnClicked: 'ShareBtnClicked',
  },
  penDetails: {
    PenBlackBtnClicked: 'PenBtnBlackClicked',
    PenBlueBtnClicked: 'PenBtnBlueClicked',
    PenGreenBtnClicked: 'PenBtnGreenClicked',
    PenRedBtnClicked: 'PenBtnRedClicked',
    PenSize1BtnClicked: 'PenBtnSize1Clicked',
    PenSize2BtnClicked: 'PenBtnSize2Clicked',
    PenSize3BtnClicked: 'PenBtnSize3Clicked',
    PenSize4BtnClicked: 'PenBtnSize4Clicked',
    MyScriptBtnClicked: 'MyScriptBtnClicked',
  },
  selectorDetails: {
    CopyBtnClicked: 'CopyBtnClicked',
    CutBtnClicked: 'CutBtnClicked',
    DeleteBtnClicked: 'DeleteBtnClicked',
    PasteBtnClicked: 'PasteBtnClicked',
  },
  shapeDetails: {
    ShapeSegmentToolBtnClicked: 'ShapeSegmentToolBtnClicked',
    ShapeArrowToolBtnClicked: 'ShapeArrowToolBtnClicked',
    ShapeRectangleToolBtnClicked: 'ShapeRectangleToolBtnClicked',
    ShapeEllipseToolBtnClicked: 'ShapeEllipseToolBtnClicked',
  },
  shortcuts: {
    InviteCollaboratorsShortcut: 'InviteCollaboratorsShortcut',
    NewBoardShortcut: 'NewBoardShortcut',
    DuplicateBoardShortcut: 'DuplicateBoardShortcut',
    OpenBoardShortcut: 'OpenBoardShortcut',
    BoardDetailsShortcut: 'BoardDetailsShortcut',
    PrintPagesShortcut: 'PrintPagesShortcut',
    DownloadPagesShortcut: 'DownloadPagesShortcut',
    PairWithTabletShortcut: 'PairWithTabletShortcut',
    UndoShortcut: 'UndoShortcut',
    RedoShortcut: 'RedoShortcut',
    CutShortcut: 'CutShortcut',
    CopyShortcut: 'CopyShortcut',
    PasteShortcut: 'PasteShortcut',
    SelectAllShortcut: 'SelectAllShortcut',
    ZoomInShortcut: 'ZoomInShortcut',
    ZoomOutShortcut: 'ZoomOutShortcut',
    ZoomDefaultShortcut: 'ZoomDefaultShortcut',
    FullScreenShortcut: 'FullScreenShortcut',
    InsertImageShortcut: 'InsertImageShortcut',
    AddPageShortcut: 'AddPageShortcut',
    InsertPDFShortcut: 'InsertPDFShortcut',
    ExportSelectionPNGShortcut: 'ExportSelectionPNGShortcut',
    ExportSelectionSVGShortcut: 'ExportSelectionSVGShortcut',
    WebcamScanSettingsShortcut: 'WebcamScanSettingsShortcut',
    SelectToolShortcut: 'SelectToolShortcut',
    CameraToolShortcut: 'CameraToolShortcut',
    PenToolShortcut: 'PenToolShortcut',
    HighlighterToolShortcut: 'HighlighterToolShortcut',
    EraserToolShortcut: 'EraserToolShortcut',
    TextToolShortcut: 'TextToolShortcut',
    MyScriptToolShortcut: 'MyScriptToolShortcut',
  },
  textDetails: {
    TextBackgroundColorBtnClicked: 'TextBackgroundColorBtnClicked',
    TextBoldFormattingBtnClicked: 'TextBoldFormattingBtnClicked',
    TextFontBtnClicked: 'TextFontBtnClicked',
    TextFontSizeBtnClicked: 'TextFontSizeBtnClicked',
    TextItalicFormattingBtnClicked: 'TextItalicFormattingBtnClicked',
    TextStrikethroughBtnClicked: 'TextStrikethroughBtnClicked',
    TextUnderlineFormattingBtnClicked: 'TextUnderlineFormattingBtnClicked',
    TextAlignCenterBtnClicked: 'TextAlignCenterBtnClicked',
    TextAlignJustifyBtnClicked: 'TextAlignJustifyBtnClicked',
    TextColorBtnClicked: 'TextColorBtnClicked',
    TextHyperlinkBtnClicked: 'TextHyperlinkBtnClicked',
    TextLaTeXBtnClicked: 'TextLaTeXBtnClicked',
    TextLeftJustifyBtnClicked: 'TextLeftJustifyBtnClicked',
    TextNormalFormattingBtnClicked: 'TextNormalFormattingBtnClicked',
    TextRightJustifyBtnClicked: 'TextRightJustifyBtnClicked',
  },
  toolbar: {
    EraserBtnClicked: 'EraserBtnClicked',
    HighlighterBtnClicked: 'HighlighterBtnClicked',
    PenBtnClicked: 'PenBtnClicked',
    SelectorBtnClicked: 'SelectorBtnClicked',
    ShapeToolsBtnClicked: 'ShapeToolsBtnClicked',
    TextboxBtnClicked: 'TextboxBtnClicked',
  },
  usage: {
    Edit: 'Edit',
  },
};

export default class Analytics extends AbstractAnalytics {

  private _debug: boolean = DEBUG_MODE;
	private _ga;

	constructor(debug=DEBUG_MODE, ga=()=>{}) {
    super();
    this._debug = debug;
		this._ga = ga;
	}

  private _getCategoryForAction(action) {
    var category = 'unknown';
    for (var cat in categories) {
      if (action in categories[cat]) {
        category = cat;
        break;
      }
    }
    return `board_${ category }`;
  }

  // reports object or logs to console depending on DEBUG_MODE
  private _send(obj) {
    if (this._debug) {
      console.log('ga send:');
      console.log(obj);
    } else {
      this._ga('send', obj);
    }
  }

  /**
   * Reports an event to Google Analytics
   * @param {String} action (required) the google analytics action
   * @param {String} label (optional) for categorizing events
   * @param {String} value (optional) value associated to event
   */
  public event(action, label, value) {
    var obj: any = {
      hitType: 'event',
      eventCategory: this._getCategoryForAction(action),
      eventAction: action,
    };
    if (typeof label !== 'undefined') {
      obj.eventLabel = label;
    }
    if (typeof value !== 'undefined') {
      obj.eventValue = value;
    }
    this._send(obj);
  }

  /**
   * Reports (via Google Analytics) that an unexpected case was encountered in a switch...case statement.
   * @param {String} location (required) A string indicating which switch...case had the issue.
   * @param {String} value (optional) A string representation of the unexpected case.
   */
  public unexpected(location, value) {
    var obj = {
      hitType: 'event',
      eventCategory: 'unexpected',
      eventAction: location,
      eventLabel: value,
    };
    this._send(obj);
  }

}