////////////////////////////////////////////////////////////////////////////////
//  MutableTextController methods:                                            //
//      + onKeyDown(event)                                                    //
//      + onDown()                                                            //
////////////////////////////////////////////////////////////////////////////////

import MutableText from "./MutableText";
import MutableScene from "../scene/MutableScene";
import { KeyEvent } from "../Canvas";

export default class MutableTextController {

  private mutableText: MutableText;

  constructor(mutableText: MutableText, private scene: MutableScene) {
    this.mutableText = mutableText;
  }

  /**
   * Responds to typed characters and to Backspace, ArrowLeft and ArrowRight
   * @param event the keyboard event
   */
  onKeyDown(event: KeyEvent) {
    const key = event.key;
    if (key.length === 1) {
      if (!event.metaKey && !event.altKey && !event.ctrlKey) {
        // then input is a character we want to type
        // if we have a selection, we want to replace it with the typed character:
        // so delete the selection first.
        if (this.mutableText.cursorPos.isSelection) {
          this.mutableText.delete(false);
        }
        this.mutableText.insert({ type: 'string', string: key });
      } else if (event.metaKey || event.altKey) {
        // commands are checked here
        const lowercaseKey = key.toLowerCase();
        // clipboard manipulation
        // TODO: implement cut/copy/paste with the system clipboard
        if (lowercaseKey === 'x') {
          // cut the text and put it on the clipboard
          // TODO: formatting

        } else if (lowercaseKey === 'c') {
          // copy the text and put it on the clipboard
          // TODO
        } else if (lowercaseKey === 'v') {
          // paste text from clipboard
          // TODO
        }
        // select all
        else if (lowercaseKey === 'a') {
          this.mutableText.selectAll();
        }
      } else {
        // unknown command to us; don't absorb the key.
        return false;
      }
    } else if (key === 'Backspace' || key === 'Delete') {
      this.mutableText.delete(key === 'Backspace'); // calls updateLines
    } else if (key === 'Enter') {
      this.mutableText.insert({ type: 'string', string: '\n' });
    } else {
      // navigational keys, affected by shift
      const makeSelection = event.shiftKey;
      if (key === 'ArrowLeft') {
        if (event.ctrlKey || event.metaKey) {
          this.mutableText.moveCursorHorizontallyByWords(-1, makeSelection);
        } else {
          this.mutableText.moveCursorHorizontally(-1, makeSelection);
        }
      } else if (key === 'ArrowRight') {
        if (event.ctrlKey || event.metaKey) {
          this.mutableText.moveCursorHorizontallyByWords(1, makeSelection);
        } else {
          this.mutableText.moveCursorHorizontally(1, makeSelection);
        }
      } else if (key === 'ArrowUp') {
        this.mutableText.moveCursorVertically(-1, makeSelection);
      } else if (key === 'ArrowDown') {
        this.mutableText.moveCursorVertically(1, makeSelection);
      } else if (key === 'Home') {
        this.mutableText.moveCursorToStart(makeSelection);
      } else if (key === 'End') {
        this.mutableText.moveCursorToEnd(makeSelection);
      } else {
      // we didn't absorb the key
        return false;
      }
    }
    this.mutableText.setCursor();
    this.scene.redisplay();
    return true;
  }

  cut() {
    if (!this.mutableText.cursorPos.isSelection) {
      return null;
    }
    var selectedText = this.mutableText.getSelectionAsText();
    this.mutableText.delete(false);
    this.mutableText.setCursor();
    this.scene.redisplay();
    return selectedText;
  }

  copy() {
    if (!this.mutableText.cursorPos.isSelection) {
      return null;
    }
    return this.mutableText.getSelectionAsText();
  }

  paste(text: string) {
    if (this.mutableText.cursorPos.isSelection) {
      this.mutableText.delete(false);
    }
    this.mutableText.insert({ type: 'string', string: text });
    this.mutableText.setCursor();
    this.scene.redisplay();
  }

  /**
   * Cause the mutableText to move the cursor to the given coordinates.
   * @param {*} x the x-coordinate in textLines coordinates
   * @param {*} y the y-coordinate in textLines coordinates
   * @param {*} makeSelection true if making a selection, false otherwise
   */
  moveToXY(x: number, y: number, makeSelection: boolean) {
    this.mutableText.placeCursor(x, y, makeSelection);
  }
}