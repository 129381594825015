
import SimpleDocument from '../document/SimpleDocument'
import Item from '../items/Item'
import ItemT from '../itemTs/ItemT'
import SceneView from '../SceneView'
import Point from '../geometry/Point'
import Rect from '../geometry/Rect'
import Matrix from '../geometry/Matrix'
import Background from '../background/Background'
import { BoardState } from '../state/filestructs'

export default class SimpleScene {

  protected sceneViews: Array<SceneView> = [];

  protected _background: Background;
  protected items: Array<Item> = [];
  protected forefrontItems: Array<ItemT> = [];

  constructor(private _document: SimpleDocument) {
    // TODO when loading board state is supported, remove pattern from default background below
    this._background = new Background({
      color: { r: 255, g: 255, b: 255 },
      patterns: [
        {
          type: 'Grid',
          color: { r: 173, g: 216, b: 230 }, // lightblue
          dash: [],
          dx: 72,
          dy: 72,
        },
      ],
      documents: [],
    });
  }

  public getBoardState(): BoardState {
    return {
      background: this._background.state,
      items: this.items.map(item => item.state),
      resources: this._document.getResources().map(resource => resource.state),
    }
  }

  public addSceneView(sv: SceneView) {
    this.sceneViews.push(sv);
    this.displayOnSceneView(sv);
  }

  public displayOnSceneView(sv: SceneView) {
    const canvas = sv.canvas;
    const viewport = sv.viewport;

    this._background.drawOnCanvas(canvas, viewport);

    canvas.save();
    canvas.transform(viewport);
    const identityMatrix = Matrix.identityMatrix();
    for (const item of this.items) {
      item.drawOnCanvas(canvas, identityMatrix);
    }
    canvas.restore();
    for (const item of this.forefrontItems) {
      item.drawOnCanvas(canvas, viewport);
    }
  }

  public getBackground() {
    return this._background;
  }

  public getItem(id: string) {
    let item = this.items.find(x => { return x.id === id; });
    if (item) {
      return item;
    } else {
      return null;
    }
  }

    private getItemsWhereRectIntersectsRect(rect: Rect) {
      return this.items.filter(item => rect.intersects(item.getBoundingRect()));
    }
  
    public getItemsIntersectingRect(rect: Rect) {
      return this.getItemsWhereRectIntersectsRect(rect).filter(item => item.intersectsRect(rect));
    }
  
    public getItemsIntersectingSegment(end1: Point, end2: Point) {
      const rect = Rect.fromXYXY(end1.x, end1.y, end2.x, end2.y);
      return this.getItemsWhereRectIntersectsRect(rect).filter(item => item.intersectsSegment(end1, end2));
    }

}