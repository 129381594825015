////////////////////////////////////////////////////////////////////////////////
// BeginFormattingTextInput                                                   //
////////////////////////////////////////////////////////////////////////////////

import Color from '../../geometry/Color';
import TextInput, { TextInputType } from './TextInput';
import { BeginFormattingTextInputState } from '../../state/text';
import TextFormatting, { TextFormattingType } from '../TextFormatting';

export type FormattingStack = { property: string, value: any }[];

export default class BeginFormattingTextInput extends TextInput {

  constructor(private property: TextFormattingType, private value: any) {
    super(TextInputType.begin_formatting);
  }

  static fromState(state: BeginFormattingTextInputState) {
    if (state.p !== 'color') {
      return new BeginFormattingTextInput(state.p, state.v);
    } else {
      return new BeginFormattingTextInput(state.p, Color.fromState(state.v));
    }
  }

  get state(): BeginFormattingTextInputState {
    if (this.property !== 'color') {
      return {
        t: 'begin',
        p: this.property,
        v: this.value,
      }
    } else {
      return {
        t: 'begin',
        p: this.property,
        v: (this.value as Color).state,
      }
    }
  }

  get length() {
    return 0;
  }

  public charAt() {
    return '';
  }

  toString() {
    return '';
  }

  /**
   * Called by PreTextItemT.calculateLines() and
   * PreTextItemT.drawOnCanvas().
   * Updates formattingStack and formatting w.r.t. the formatting input
   * Push the PREVIOUS (property, value) pair onto the stack
   * @param {TextFormatting} formatting
   * @param formattingStack an array of property-value pairs
   */
  dealWithFormatting(formatting: TextFormatting, formattingStack: FormattingStack) {
    const previousFormat = {
      property: this.property,
      value: formatting[this.property],
    };
    formattingStack.push(previousFormat);
    // @ts-ignore
    formatting[this.property] = this.value;
  }
}