import React from "react"

const css = require("../toolbar.module.css");
import fa from "../../lib/fontawesome"

import {
	onPenBlackBtnClicked,
	onPenBlueBtnClicked,
	onPenGreenBtnClicked,
	onPenRedBtnClicked,
	onPenSize1BtnClicked,
	onPenSize2BtnClicked,
	onPenSize3BtnClicked,
	onPenSize4BtnClicked,
} from "../../../ui/buttonCallbacks"

export default ({tool, addCtx}) => (
	<div
		className={css.horizontalButtons}
		style={{position: "fixed", left:"45px", top:"37px"}}
	>
		<button
			className={`${css.btn} ${tool.details.color.css==="#000000" && css.btnOn}`}
			onClick={addCtx(onPenBlackBtnClicked)}
		>
			<i className={`${fa.fa} ${fa.faPencil}`} />
		</button>
		<button
			className={`${css.btn} ${tool.details.color.css==="#0000ff" && css.btnOn}`}
			onClick={addCtx(onPenBlueBtnClicked)}
		>
			<i className={`${fa.fa} ${fa.faPencil}`} style={{color:"blue"}} />
		</button>
		<button
			className={`${css.btn} ${tool.details.color.css==="#008000" && css.btnOn}`}
			onClick={addCtx(onPenGreenBtnClicked)}
		>
			<i className={`${fa.fa} ${fa.faPencil}`} style={{color:"green"}} />
		</button>
		<button
			className={`${css.btn} ${tool.details.color.css==="#ff0000" && css.btnOn}`}
			onClick={addCtx(onPenRedBtnClicked)}
		>
			<i className={`${fa.fa} ${fa.faPencil}`} style={{color:"red"}} />
		</button>
		<button
			className={`${css.btn} ${tool.details.size===1 && css.btnOn}`}
			onClick={addCtx(onPenSize1BtnClicked)}
		>
			<i className={`${fa.fa} ${fa.faCircle}`} style={{fontSize:".75em", verticalAlign: "middle"}}/>
		</button>
		<button
			className={`${css.btn} ${tool.details.size===2 && css.btnOn}`}
			onClick={addCtx(onPenSize2BtnClicked)}
		>
			<i className={`${fa.fa} ${fa.faCircle}`} style={{fontSize:"1em", verticalAlign: "middle"}}/>
		</button>
		<button
			className={`${css.btn} ${tool.details.size===4 && css.btnOn}`}
			onClick={addCtx(onPenSize3BtnClicked)}
			style={{paddingTop:"2px"}}
		>
			<i className={`${fa.fa} ${fa.faCircle}`} style={{fontSize:"1.5em", verticalAlign: "middle"}}/>
		</button>
		<button
			className={`${css.btn} ${tool.details.size===9 && css.btnOn}`}
			onClick={addCtx(onPenSize4BtnClicked)}
			style={{paddingLeft:"4px"}}
		>
			<i className={`${fa.fa} ${fa.faCircle}`} style={{fontSize:"2em", verticalAlign: "middle"}}/>
		</button>
	</div>
);