
import Item from './Item'
import { TextItemState } from '../state/items';
import Matrix from '../geometry/Matrix';
import Rect from '../geometry/Rect';
import { Canvas } from '../Canvas';
import Point from '../geometry/Point';

import { TEXT_PADDING } from '../itemTs/PreTextItemT'
import TextLines from '../text/TextLines';
import TextBuffer from '../text/TextBuffer';
import SimpleDocument from '../document/SimpleDocument';
import { SvgCache } from '../text/_svg';
import Scene from '../scene/Scene';

const DEBUG_MODE = false;

export default class TextItem extends Item {

	private _localBaselineLeft: Point;
	private _localBaselineRight: Point;
	private _lines: TextLines;

	constructor(id: string, matrix: Matrix, private _buffer: TextBuffer, localBaselineLeft: Point, localBaselineRight: Point, private _svgCache: SvgCache) {
		super(id, matrix);

		this._localBaselineLeft = localBaselineLeft;
		this._localBaselineRight = localBaselineRight;

		// TODO: fix this when we implement rotations
		var textWidth = localBaselineRight.x - localBaselineLeft.x;
		this._lines = new TextLines(_buffer, textWidth, _svgCache, null);
	}

	public static fromState(state: TextItemState, document: SimpleDocument) {
		return new TextItem(
			state.id,
			Matrix.fromState(state.m),
			TextBuffer.fromState(state.b),
			Point.fromArray(state.bll),
			Point.fromArray(state.blr),
			document.svgCache
		);
	}

	public get state(): TextItemState {
		throw new Error("Method not implemented.");
	}

	protected _computeBoundingRect(): Rect {
		// FIXME: when rotation is implemented
		var topLeft = this._getCorner('left', this.matrix, 'topBox');
		var topRight = this._getCorner('right', this.matrix, 'topBox');
		var left = topLeft.x;
		var top = topLeft.y;
		// respect a larger width if baselineRight demands it
		var width = Math.max(this._lines.width, topRight.x - topLeft.x);
		var height = this._lines.height + 2 * TEXT_PADDING;
		var rect = new Rect(left, top, width, height);
		return rect;
	}

	public drawOnCanvas(canvas: Canvas, matrix: Matrix): void {
		const EPSILON = 1e-6;
		const ctx = canvas.getContext('2d');
		let topBoxLeft; // top-left corner of the implicit textbox
		let totalMatrix;
	
		if (!matrix) {
			topBoxLeft = this._getCorner('left', this.matrix, 'topBox');
			totalMatrix = this.matrix;
		} else {
			totalMatrix = matrix.times(this.matrix);
			topBoxLeft = this._getCorner('left', totalMatrix, 'topBox');
			const width = this._getTextWidth(totalMatrix);
			// only relayout if the width changes
			if (Math.abs(this._lines.maxWidth - width) > EPSILON) {
				this._lines = new TextLines(this._buffer, width, this._svgCache, this.scene as (Scene|null));
			}
		}
		// point where the top and left margins intersect
		const textTopLeft = topBoxLeft.translate(TEXT_PADDING, TEXT_PADDING);
		ctx.save();
		ctx.translate(textTopLeft.x, textTopLeft.y);
		this._lines.drawOnContext(ctx);
		ctx.restore();
	
		if (DEBUG_MODE) {
			// should draw the line 0 baseline
			// debug code
			const baselineLeft = this._getCorner('left', totalMatrix, 'baseline');
			const baselineRight = this._getCorner('right', totalMatrix, 'baseline');
			ctx.beginPath();
			ctx.moveTo(baselineLeft.x, baselineLeft.y);
			ctx.lineTo(baselineRight.x, baselineRight.y);
			ctx.stroke();
	
			const rect = this.getBoundingRect();
			ctx.rect(rect.left, rect.top, rect.width, rect.height);
			ctx.stroke();
		}
	}

	public intersectsRect(rect: Rect): boolean {
		const topLeft = this._getCorner('left', this.matrix, 'topBox');
		for (const selectionRect of this._lines.getAllSelectionRects()) {
			// transform the rect from lines coordinates to scene coordinates.
			selectionRect.left += topLeft.x + TEXT_PADDING;
			selectionRect.top += topLeft.y + TEXT_PADDING;
			const expandedRect = selectionRect.expandedBy(TEXT_PADDING, TEXT_PADDING, TEXT_PADDING, TEXT_PADDING);
			if (expandedRect.intersects(rect)) {
				return true;
			}
		}
		return false;
	}

	public intersectsSegment(end1: Point, end2: Point): boolean {
		throw new Error("Method not implemented.");
	}

	/////////////////////
	// private methods //
	/////////////////////

	/**
	 * If refLine is TopBox, then getCorner returns the top-left (resp. top-right)
	 * corner of the textbox, after having applied the total transformation matrix.
	 * If refline is baseline, then getCorner returns the point where the left
	 * (resp. right) margin intersects the line 0 baseline, after having applied
	 * the total transformation matrix.
	 * @param {TextItem.chiralities} chirality left or right
	 * @param {Matrix} matrix total transformation matrix applied to the textbox
	 * @param {TextItem.referenceLines} refLine
	 * @return {Point}
	 */
	private _getCorner(chirality: 'left'|'right', matrix: Matrix, refLine: 'topBox'|'baseline'): Point {
		// FIX ME when rotation is implemented
		var dx = 0;
		var dy = -this._getDistToptoBaseline();
		switch (chirality) {
			case 'left':
				dx = -TEXT_PADDING;
				break;
			case 'right':
				dx = TEXT_PADDING;
				break;
		}
		var topBoxBeforeMatrix = this._getInitialBaseline(chirality).translate(dx, dy);
		var topBox = matrix.timesPoint(topBoxBeforeMatrix);
		switch (refLine) {
			case 'topBox':
				return topBox;
			case 'baseline':
				return topBox.translate(-dx, -dy);
		}
	}

	/**
	 * Returns the point where the initial line 0 baseline intersects the left or
	 * right margin.
	 * @param {TextItem.chiralities} chirality left or right
	 * @return {Point}
	 */
	private _getInitialBaseline(chirality: 'left'|'right'): Point {
		switch (chirality) {
			case 'left':
				return this._localBaselineLeft;
			case 'right':
				return this._localBaselineRight;
		}
	}

	/**
	 * @return {number} the distance from the top of the textbox to the line 0
	 * baseline
	 */
	private _getDistToptoBaseline() {
		if (!this._lines) {
			console.log('ERROR: TextItem.getDistTopToBaseline(), lines === null');
			return TEXT_PADDING; // next best thing
		} else {
			return this._lines.lines[0].ascent + TEXT_PADDING;
		}
	}

	/**
	 * @param {Matrix} totalMatrix the total transformation applied to the textbox
	 * @return the distance between the left and right margins
	 */
	private _getTextWidth(totalMatrix: Matrix) {
		// FIX ME when rotation is implemented
		var topBoxLeft = this._getCorner('left', totalMatrix, 'topBox');
		var topBoxRight = this._getCorner('right', totalMatrix, 'topBox');
		return topBoxRight.x - topBoxLeft.x - 2 * TEXT_PADDING;
	};

}