import React, { useState } from "react"

import Toolbar from "./toolbar"
import NavTools from "./nav_tools"

import SelectorDetails from "./details/selector"
import PenDetails from "./details/pen"
import HighlighterDetails from "./details/highlighter"
import TextDetails from "./details/text"
import ShapesDetails from "./details/shapes"

export default ({tool, addCtx, selectionAvailable, clipboardAvailable}) => {
	
	return (
		<>
			<Toolbar tool={tool} addCtx={addCtx} />
			<NavTools tool={tool} addCtx={addCtx} />
			{ tool.type==="selector" &&
				<SelectorDetails selectionAvailable={selectionAvailable} clipboardAvailable={clipboardAvailable} addCtx={addCtx} />
			}
			{ tool.type==="pen" &&
				<PenDetails tool={tool} addCtx={addCtx} />
			}
			{ tool.type==="highlighter" &&
				<HighlighterDetails tool={tool} addCtx={addCtx} />
			}
			{ tool.type==="textbox" &&
				<TextDetails />
			}
			{ tool.type==="shape" &&
				<ShapesDetails tool={tool} addCtx={addCtx} />
			}
		</>
	);
};