
import Color from '../geometry/Color'
import Point from '../geometry/Point'
import PrePathItemT from '../itemTs/PrePathItemT'
import Scene from '../scene/Scene'
import Tool from './Tool'
import Viewport from '../geometry/Viewport'
import { LiveDocument } from '../document/LiveDocument'
import LocalAgent from '../agent/local/LocalAgent'

export default abstract class DrawingTool extends Tool {

  protected color: Color;
  protected size: number;

  private initialPoint: Point | null;
  private initialViewport: Viewport | null;
  private prePathItemT: PrePathItemT | null;

  constructor(document: LiveDocument, protected _localAgent: LocalAgent, private _stateType: string) {
    super(document);
    this.color = Color.fromCss('black');
    this.size = 1;
    this.initialPoint = null;
    this.initialViewport = null;
    this.prePathItemT = null;
  }

  abstract onPathDrawFinished(prePathItemT: PrePathItemT): void;

  public onDown(x: number, y: number, viewport: Viewport, scene: Scene) {
    this.initialPoint = new Point(x, y);
    this.initialViewport = viewport;
    const scenePoint = viewport.viewToSceneMatrix().timesPoint(this.initialPoint);
    this._localAgent.notifyPenDown(this.color, this.size, scenePoint);
  }

  public onDrag(x: number, y: number, viewport: Viewport, scene: Scene) {
    if (this.prePathItemT) {
      this.prePathItemT.addXY(x, y, viewport);
    } else {
      // This is the first drag. Make the prePathItemT now and add it to the Scene.
      this.prePathItemT = new PrePathItemT(this.document.idSource.deviceId, this.color, this.size, this.initialViewport!);
      this.prePathItemT.addXY(this.initialPoint!.x, this.initialPoint!.y, this.initialViewport!);
      this.prePathItemT.addXY(x, y, viewport);
      scene.addForefrontItem(this.prePathItemT);
    }

    const scenePoint = viewport.viewToSceneMatrix().timesPoint(new Point(x, y));
    this._localAgent.notifyPenDrag(scenePoint);
  }

  public onUp(scene: Scene) {
    if (!this.prePathItemT)
      return;

    scene.beginChanges();
    this.onPathDrawFinished(this.prePathItemT);
    scene.removeForefrontItem(this.prePathItemT);
    this.prePathItemT = null;
    scene.endChanges();

    this._localAgent.notifyPenUp();
  }

  public setColor(color: Color) {
    this.color = color;
  }

  public getColor() {
    return this.color;
  }

  public setSize(size: number) {
    this.size = size;
  }

  public getSize() {
    return this.size;
  }

  public getState() {
    return {
      type: this._stateType,
      details: {
        size: this.size,
        color: this.color,
      }
    }
  }

}