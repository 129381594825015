
/*
	TODO
	[] (IMPORTANT) add and test reconnection logic
	[] provide a mechanism for being booted and for self-booting after inactivity
	[] (BUG) keep a queue of channel subscriptions in case a second subscribe() call is made after the first, but before getting the deviceToken from the server
	[] keep maps for socketId <-> deviceId within this file (no need to report socketIds outside of this file)
*/


import { ClientEvent, ServerEvent, DeviceData, DeviceChannelInfo } from "./interface";


export default class WebsocketClient {

	// event handlers
	public onConnected = () => {};
	public onDeviceId = (deviceId: string) => {};
	public onSubscribe = (channelId: string, devices: DeviceData[]) => {};
	public onDeviceSubscribed = (socketId: string, deviceId: string, channelId: string, info: DeviceChannelInfo) => {};
	public onDeviceUnsubscribed = (deviceId: string, channelIds: string[]) => {};
	public onDeviceDisconnected = (socketId: string) => {};
	public onMessage = (message: any, deviceId: string, channelId: string) => {};

	private _ws: WebSocket|null = null; // if url is "", this class does nothing
	private _deviceTokenRequested: boolean = false;
	private _deviceToken: string|null = null;

	constructor(url: string) {
		if (url !== "") {
			const ws = new WebSocket(url);
			ws.onopen = (event) => this._onOpen(event);
			ws.onmessage = (event) => {
				try {
					this._onMessage(event);
				} catch (error) {
					console.error(error);
					console.log(event);
				}
			}
			ws.onerror = (event) => this._onError(event);
			ws.onclose = (event) => this._onClose(event);
			this._ws = ws;
		}
	}

	///////////////////
	// api functions //
	///////////////////

	public isConnected(): boolean {
		return (
			this._ws !== null
			&& this._ws.readyState === WebSocket.OPEN
			&& this._deviceToken !== null
		);
	}

	public subscribe(channelIds: string[], channelTokens: string[]) {
		if (this._deviceToken || !this._deviceTokenRequested) {
			const deviceToken = this._deviceToken || "assign";
			this._send({
				event: "subscribe",
				data: {
					deviceToken,
					channelIds,
					channelTokens,
				}
			});
			this._deviceTokenRequested = true;
		}
	}

	public unsubscribe(channelIds: string[]) {
		if (this._deviceToken) {
			this._send({
				event: "unsubscribe",
				data: {
					deviceToken: this._deviceToken,
					channelIds,
				}
			});
		}
	}

	public send(message: any, channelId: string, socketIds: string[]) {
		if (this._deviceToken) {
			this._send({
				event: "send",
				data: {
					deviceToken: this._deviceToken,
					channelId,
					socketIds,
					message,
				}
			});
		}
	}

	//////////////////////
	// server interface //
	//////////////////////

	private _send(event: ClientEvent) {
		// TODO should I have an outbox if the connection isn't open?
		if (this._ws) {
			this._ws.send(JSON.stringify(event));
		}
	}

	private _onMessage(e: MessageEvent): boolean {
		if (e.data === "") {
			return false; // aws api gateway websockets seem to send an empty event if response integration is enabled but nothing is returned from the lambda
		}
		// TODO check that event is actually a ServerEvent
		const event = JSON.parse(e.data) as ServerEvent;
		if (event.event === "subscribe" && "welcome" in event.data) {
			this._deviceToken = event.data.welcome!.deviceToken;
			this.onDeviceId(event.data.welcome!.deviceId);
		}
		switch (event.event) {
			/*
			case "welcome":
				{
					// only notify onConnected the first time
					if (!this._deviceToken) {
						this._deviceToken = event.data.deviceToken;
						this.onConnected(event.data.deviceId);
					}
				}
				return true;
			*/
			case "subscribe":
				{
					for (const channelId of event.data.channelIds) {
						const devices = event.data.devices.filter(device => channelId in device.info);
						this.onSubscribe(channelId, devices);
					}
				}
				return true;
			case "device_subscribed":
				{
					for (const channelId of event.data.channelIds) {
						const socketId = event.data.device.socketId;
						const deviceId = event.data.device.deviceId;
						const info = event.data.device.info[channelId];
						this.onDeviceSubscribed(socketId, deviceId, channelId, info);
					}
				}
				return true;
			case "device_unsubscribed":
				{
					this.onDeviceUnsubscribed(event.data.deviceId, event.data.channelIds);
				}
				return true;
			case "device_disconnected":
				{
					this.onDeviceDisconnected(event.data.socketId);
				}
				return true;
			case "message":
				{
					const channelId = event.data.channelId;
					if (channelId === "ping") {
						const socketId = event.data.message.socketId;
						this.send({ pong: "pong" }, "pong", [socketId]);
					} else {
						this.onMessage(event.data.message, event.data.deviceId, event.data.channelId);
					}
				}
				return true;
		}
	}

	private _onOpen(event: Event) {
		this.onConnected();
	}

	private _onClose(event: CloseEvent) {
		console.log("ws closed");
		console.log("TODO try reconnecting");
	}

	private _onError(event: Event) {
		console.error(event);
	}

}