
import { ResourceState } from '../../state/resources'
import { DeltaState } from '../../state/deltas'
import Sender from '../local/Sender'
import Resource from '../../resources/Resource'
import Delta from '../../deltas/Delta'
import Channel from './Channel'
import Delta_fromState from '../../deltas/factory'
import Resource_fromState from '../../resources/factory'

type DocumentChanges = {
	resources: ResourceState[],
	deltas: DeltaState[],
};

export class DocumentChangesSender {

	constructor(private _sender: Sender) {
	}

	public changes(resources: Resource[], deltas: Delta[]) {
		const message: DocumentChanges = {
			resources: resources.map(r => r.state),
			deltas: deltas.map(d => d.state),
		};
		this._sender.broadcast('changes', message);
	}

}

export class DocumentChangesReceiverChannel extends Channel {

	constructor(
		private _onchanges: (resources: Resource[], deltas: Delta[]) => void,
	) {
		super();
	}

	public receive(message: any) {
		// TODO handle sequencing
		const changes = message as DocumentChanges;
		const resources = changes.resources.map(rs => Resource_fromState(rs));
		const deltas = changes.deltas.map(ds => Delta_fromState(ds));
		this._onchanges(resources, deltas);
	}

}