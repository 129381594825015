/**
 * The TextMetrics class contains libraries for measuring text
 * width, ascent, and descent.
 */

import TextFormatting from "./TextFormatting";

export default class TextMetrics {
  /**
   * Measures the ascent, descent, and width of the given text string.
   * @param {String} text the text
   * @param {TextFormatting} formatting the formatting
   * @return an object with ascent, descent, and width fields
   */
  static getMetrics(text: string, formatting: TextFormatting) {
    /*  From carota/src/text.js
    Returns width, height, ascent, descent in pixels for the specified text and font.
    The ascent and descent are measured from the baseline. Note that we add/remove
    all the DOM elements used for a measurement each time - this is not a significant
    part of the cost, and if we left the hidden measuring node in the DOM then it
    would affect the dimensions of the whole page. */
    var span, block, div;
    span = document.createElement('span');
    block = document.createElement('div');
    div = document.createElement('div');

    block.style.display = 'inline-block';
    block.style.width = '1px';
    block.style.height = '0';

    div.style.visibility = 'hidden';
    div.style.position = 'absolute';
    div.style.top = '0';
    div.style.left = '0';
    div.style.width = '100px'; // nominal
    div.style.height = '100px'; // also nominal
    div.style.whiteSpace = 'nowrap';

    div.appendChild(span);
    div.appendChild(block);
    document.body.appendChild(div);
    let ascent, descent, width;
    try {
      span.style.font = formatting.fontString;

      span.innerHTML = '';
      span.appendChild(document.createTextNode(text.replace(/\s/g, String.fromCharCode(160))));

      block.style.verticalAlign = 'baseline';
      ascent = (block.offsetTop - span.offsetTop);
      block.style.verticalAlign = 'bottom';
      var height = (block.offsetTop - span.offsetTop);
      descent = height - ascent;
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.font = formatting.fontString;
        width = ctx.measureText(text).width;
      } else {
        width = 1;
      }
    } finally {
      if (div) {
        if (div.parentNode) {
          div.parentNode.removeChild(div);
        }
        div = null;
      }
    }
    return {
      ascent: ascent,
      descent: descent,
      width: width,
    };
  }
}