
import { DeltaState } from '../state/deltas'
import AddItemDelta from './AddItemDelta'
import DeleteItemsDelta from './DeleteItemDelta'
import GrabItemsDelta from './GrabItemsDelta'
import ReleaseItemsDelta from './ReleaseItemsDelta'
import TransformItemsDelta from './TransformItemsDelta'
import ChangeBackgroundDelta from './ChangeBackgroundDelta'
import Delta from "./Delta"

export default function Delta_fromState(state: DeltaState): Delta {
	switch(state.t) {
		case 'bg':
			return ChangeBackgroundDelta.fromState(state);
		case 'add':
			return AddItemDelta.fromState(state);
		case 'del':
			return DeleteItemsDelta.fromState(state);		
		case 'grab':
			return GrabItemsDelta.fromState(state);
		case 'release':
			return ReleaseItemsDelta.fromState(state);
		case 'trans':
			return TransformItemsDelta.fromState(state);
	}
}