
/*
 SelectionItemT is a forefront item representing a selection.

 When creating a SelectionItemT, the items passed through the constructor should already
   have been removed from the Scene. When deleting a SelectionItemT, the items should
   be added back to the Scene. (SelectionItemT takes care of rendering the items.)

*/

import ItemT from './ItemT'
import Item from '../items/Item'
import Matrix from '../geometry/Matrix'
import Color from '../geometry/Color'
import { Canvas, KeyEvent } from '../Canvas'
import Viewport from '../geometry/Viewport'
import MatrixControl from './MatrixControl'
import { HoverResponder, ClickResponder, KeyResponder, ClipboardData } from '../scene/InteractiveScene'
import Point from '../geometry/Point'
import { LiveDocument } from '../document/LiveDocument'
import TransformItemsDelta from '../deltas/TransformItemsDelta'
import ReleaseItemsDelta from '../deltas/ReleaseItemsDelta';
import DeleteItemDelta from '../deltas/DeleteItemDelta'
import { ItemState } from '../state/items'
import Rect from '../geometry/Rect'

interface MatrixControlConfig {
	color: Color
	showButtons: boolean,
};

export default class SelectionItemT extends ItemT implements HoverResponder, ClickResponder, KeyResponder {

	private downMatrix: Matrix | null = null;
	private matrix: Matrix = Matrix.identityMatrix();
	private matrixControl: MatrixControl;
	private initialBoundingRect: Rect;

	constructor(
		devId: string,
		public items: Item[],
		private initialMatrix: Matrix,
		config: MatrixControlConfig,
		private document: LiveDocument,
	)
	{
		super(devId);
		// compute the rectangle containing all the items and set up the MatrixControl
		let initialBoundingRect = items[0].getBoundingRect();
		for (let item of items) {
			initialBoundingRect = initialBoundingRect.union(item.getBoundingRect());
		}
		this.matrixControl = new MatrixControl(initialBoundingRect, config.color, config.showButtons);
		this.initialBoundingRect = initialBoundingRect;
	}

	public getUpperLeftPoint() {
		var point = new Point(this.initialBoundingRect.left, this.initialBoundingRect.top);
		return this.matrix.timesPoint(point);
	}

	public setMatrix(matrix: Matrix) {
		this.matrix = matrix;
    this.matrixControl.totalMatrix = matrix;
    if (this.scene) {
      this.scene.redisplay();
    }
	}

	// required by deltas
	public returnItemsToScene() {
		// Apply the transformation to the items and return them to the scene
		if (this.scene) {
			this.scene.beginChanges();
			for (let item of this.items) {
				item.matrix = this.matrix.times(item.matrix);
				this.scene.addItem(item);
			}
			this.scene.endChanges();
		}
	}

	public drawOnCanvas(canvas: Canvas, viewport: Viewport) {
		// apply the global transformation
		canvas.save();
		canvas.transform(viewport);
		// switch to the local transformation and draw the items
		canvas.save();
		canvas.transformMatrix(this.matrix);
		// draw the items
		for (let item of this.items) {
			if (item.scaleInvariant) {
				item.drawOnCanvas(canvas, this.matrix/*(ignored because scaleInvariant)*/);
			} else {
				canvas.restore();
				item.drawOnCanvas(canvas, this.matrix);
				canvas.save();
				canvas.transformMatrix(this.matrix);
			}
		}
		canvas.restore(); // we save twice, so must pop both states off
		canvas.restore();
		// draw the MatrixControl
		this.matrixControl.drawOnCanvas(canvas, viewport);
	};

	/////////////////////////////
	// respond to scene events //
	/////////////////////////////

	public acceptsHover(x: number, y: number, viewport: Viewport) {
		const p = viewport.viewToSceneMatrix().timesPoint(new Point(x, y));
		return this.matrixControl.acceptsHover(p.x, p.y, viewport.zoom);
	}

	public getCursor(x: number, y: number, viewport: Viewport) {
		const p = viewport.viewToSceneMatrix().timesPoint(new Point(x, y));
		return this.matrixControl.getCursor(p.x, p.y, viewport.zoom);
	}

	public acceptsClick(x: number, y: number, viewport: Viewport) {
		const p = viewport.viewToSceneMatrix().timesPoint(new Point(x, y));
		return this.matrixControl.acceptsClick(p.x, p.y, viewport.zoom);
	}

	public copyItemStates(): ClipboardData { // replaces SelectionItemT.doCopy()
		const itemStates: ItemState[] = [];
		for (let item of this.items) {
			const itemState = item.state;
			// combine board transformation with item state transformation
			itemState.m = this.matrix.times(item.matrix).state;
			itemStates.push(itemState);
		}
		return {
			matrix: this.matrix.copy(), // board transformation
			itemStates: itemStates,
			rect: this.initialBoundingRect.boundsAfterMatrix(this.matrix),
		};
	}

	public onDown(x: number, y: number, viewport: Viewport) {
		const p = viewport.viewToSceneMatrix().timesPoint(new Point(x, y));
		this.downMatrix = this.matrixControl.totalMatrix.copy();
  	this.matrixControl.onDown(p.x, p.y, viewport.zoom);
		// TODO support meta+click to copy
	}

	public onDrag(x: number, y: number, viewport: Viewport) {
		const p = viewport.viewToSceneMatrix().timesPoint(new Point(x, y));
		this.matrixControl.onDrag(p.x, p.y, viewport.zoom);
		this.matrix = this.matrixControl.totalMatrix;
		if (this.scene) {
			this.scene.redisplay();
		}
	}

	public onUp() {
		this.matrixControl.onUp();
		let delta = new TransformItemsDelta(
			this.document.idSource.newId(),
			this.devId,
			this.downMatrix!,
			this.matrix,
			);
		this.document.addDelta(delta);
	}

	public onClickedAway() {
		this._release();
	}

	onKeyDown(event: KeyEvent): boolean {
		if (event.key==="Enter") {
			this._release();
			return true;
		}
		if (event.key==="Backspace"||event.key==="Delete") {
			this.erase();
			return true;
		}
		return false;
	}

	onClipboardEvent(key: string, meta: boolean): boolean {
		console.log("TODO clipboard");
		return false;
	}

	private _release(erase: boolean = false) {
		let itemIds: string[] = [];
		let actId = this.document.idSource.newId();
		for (let item of this.items) {
			itemIds.push(item.id);
		}
		let delta = new ReleaseItemsDelta(
			actId,
			this.devId,
			itemIds,
			this.matrix,
		);
		delta.selectionItemT = this;
		this.document.addDelta(delta);
		if (erase) {
			for (let item of this.items) {
				let delta = new DeleteItemDelta(actId, item.state);
				delta.item = item;
				this.document.addDelta(delta);
			}
		}
	}

	public erase() {
		this._release(true);
	}

}