
import Device from "./Device"
import WebsocketClient from "./websockets/WebsocketClient";

export interface IChannelListener {
	receive(message: any): void;
	subscribed(devices: Device[]): void;
}

export default class Channel {

	public readonly channelId: string;
	public readonly channelToken: string;

	private _listeners: IChannelListener[] = [];
	private _devices: { [deviceId: string]: Device } = {};
	private _websocketClient: WebsocketClient;

	constructor(channelId: string, channelToken: string, websocketClient: WebsocketClient) {
		this.channelId = channelId;
		this.channelToken = channelToken;
		this._websocketClient = websocketClient;
	}

	public getDeviceOrNull(deviceId: string): Device|null {
		if (deviceId in this._devices) {
			return this._devices[deviceId];
		}
		return null;
	}

	public addListener(listener: IChannelListener) {
		this._listeners.push(listener);
	}

	public broadcast(sender: IChannelListener, message: any, important: boolean = false) {
		const recipientSocketIds: string[] = [];
		// try to send to other devices directly
		for (const device of Object.values(this._devices)) {
			const success = device.sendMessageDirectly(this.channelId, message);
			// if unable to send directly, append to server recipients
			if (!success) {
				const socketId = device.socketId;
				if (socketId) {
					recipientSocketIds.push(socketId);
				}
			}
		}
		// as a fallback, send to other devices through the server (if important)
		if (important && recipientSocketIds.length > 0) {
			this._websocketClient.send(message, this.channelId, recipientSocketIds);
		}
		// send to other listeners on this device
		for (const listener of this._listeners) {
			if (listener !== sender) {
				listener.receive(message);
			}
		}
	}

	public receive(message: any) {
		for (const listener of this._listeners) {
			listener.receive(message);
		}
	}

	// called immediately before notifySubscribed() if device subscribed first
	// called by notifyDeviceJoined() if device subscribed later
	public addDevice(device: Device) {
		this._devices[device.deviceId] = device;
		device.addChannel(this);
	}

	public notifySubscribed() {
		// try calling all the existing devices
		const devices = Object.values(this._devices);
		for (let device of devices) {
			device.initiateDirectConnection();
		}
		for (const listener of this._listeners) {
			listener.subscribed(devices);
		}
	}

	public notifyDeviceJoined(device: Device) {
		this.addDevice(device);
	}

	public notifyDeviceUnsubscribed(deviceId: string) {
		// TODO
	}

}