
import SimpleScene from './SimpleScene'
import Delta from '../deltas/Delta'
import { LiveDocument, ILiveDocumentSubscriber } from '../document/LiveDocument'
import Item from '../items/Item'
import ItemT from '../itemTs/ItemT'
import Scene from './Scene'
import { ClickResponder, HoverResponder, KeyResponder } from './InteractiveScene';
import Background from '../background/Background'
import Item_fromState from '../items/factory'
import { BoardState } from '../state/filestructs'

export default class MutableScene extends SimpleScene implements ILiveDocumentSubscriber {

  private semaphore: number = 0;
  private redisplayAfterChanges: boolean = false;
  private reindexAfterChanges: boolean = false;

  constructor(protected document: LiveDocument) {
    super(document);
    document.subscribe(this);
  }

  public loadBoardState(boardState: BoardState) {
    this.document.loadResources(boardState.resources);
    this._background = new Background(boardState.background);
    for (const itemState of boardState.items) {
      const item = Item_fromState(itemState, this.document);
      this.items.push(item);
    }
    this.reindex();
    this.redisplay();
  }

  public setBackground(background: Background): void {
    this._background = background;
    this.redisplay();
  }

  public addItem(item: Item) {
    this.items.push(item);
    item.scene = this;
    this.reindex();
    this.redisplay();
  }

  public addForefrontItem(itemT: ItemT) {
    this.forefrontItems.push(itemT);
    itemT.setScene(this as unknown as Scene);
    // add itemT as event responder
    if (itemT.respondsToHoverEvents) {
      (this as unknown as Scene).addHoverResponder(itemT as unknown as HoverResponder);
    }
    if (itemT.respondsToClickEvents) {
      (this as unknown as Scene).addClickResponder(itemT as unknown as ClickResponder);
    }
    if (itemT.respondsToKeyEvents) {
      (this as unknown as Scene).addKeyResponder(itemT as unknown as KeyResponder);
    }
    this.redisplay();
  }

  // TODO can I remove this? if so, maybe remove ItemT constructor arg
  public getForefrontItem<T extends ItemT>(devId: string, classType: {new(...args: any[]): T}): T | null {
    for (let itemT of this.forefrontItems) {
      if (devId === itemT.devId && itemT instanceof classType) {
        return itemT;
      }
    }
    return null;
  }

  public removeForefrontItem(itemT: ItemT) {
    var i = this.forefrontItems.indexOf(itemT);
    if (i > -1) {
      // TODO why is this case not always happening (if I select a curve, then click away)
      this.forefrontItems.splice(i, 1);
      itemT.setScene(null);
      this.redisplay();
    }
  }

  removeSceneItem(item: Item) {
    var i = this.items.indexOf(item);
    if (i > -1) {
      this.items.splice(i, 1);
    }
    item.scene = null;
    this.reindex();
    this.redisplay();
  }

  public onDelta(delta: Delta) {
    delta.applyToScene(this as unknown as Scene, this.document);
  }

  /////////////////////////////
  // notify scene of changes //
  /////////////////////////////

  beginChanges() {
    this.semaphore += 1;
  }

  endChanges() {
    this.semaphore -= 1;
    if (this.semaphore === 0) {
      if (this.redisplayAfterChanges) {
        this.redisplay();
      }
      if (this.reindexAfterChanges) {
        this.reindex();
      }
    }
  }

  redisplay() {
    if (this.semaphore > 0) {
      this.redisplayAfterChanges = true;
    } else {
      this.redisplayAfterChanges = false;
      for (const sv of this.sceneViews) {
        this.displayOnSceneView(sv);
      }
    }
  }

  reindex() {
    if (this.semaphore > 0) {
      this.reindexAfterChanges = true;
    } else {
      this.reindexAfterChanges = false;
      // reindexing code goes here
    }
  }

}