import MutableScene from '../scene/MutableScene'
import Viewport from '../geometry/Viewport'
import { LiveDocument } from '../document/LiveDocument'
import SceneView from '../SceneView';
import Tool from './Tool';

export default class PanTool extends Tool {

	private x0 = 0;
	private y0 = 0;

  constructor(document: LiveDocument, private _sceneView: SceneView) {
		super(document);
  }

  public onDown(x: number, y: number, viewport: Viewport, scene: MutableScene): void {
		this.x0 = x;
		this.y0 = y;
	}

  public onDrag(x: number, y: number, viewport: Viewport, scene: MutableScene): void {
		this._sceneView.onPan(this.x0-x, this.y0-y);
		this.x0 = x;
		this.y0 = y;
	}

  public onUp(scene: MutableScene): void {
	}

  // can be used by UI to determine how to color/display buttons
  public getState() {
		return { type: 'pan' };
	}

}