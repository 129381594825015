
/**
 * A CursorPosition represents the current cursor within a TextBuffer.
 * It has two fields: pivotPos and reachPos.
 * Each field is a buffer position of the format { input: Integer, inputChar: Integer },
 * representing the inputChar-th character of the input-th input.
 * The fields are the same if there is no selection (just a cursor).
 */

import BufferPosition from "./BufferPosition";

export default class CursorPosition {

  constructor(
    public readonly pivotPos: BufferPosition,
    public readonly reachPos: BufferPosition,
  ) {}

  get beginPos() {
    return (this.pivotPos.compareTo(this.reachPos) <= 0 ? this.pivotPos : this.reachPos);
  }

  get endPos() {
    return (this.pivotPos.compareTo(this.reachPos) >= 0 ? this.pivotPos : this.reachPos);
  }

  get isSelection() {
    return (this.pivotPos.compareTo(this.reachPos) !== 0);
  }

  /**
   * Creates a new CursorPosition with reach bufferPos and pivot either unchanged or moved to bufferPos.
   * @param {BufferPosition} bufferPos the new reach
   * @param {Boolean} movePivot true if pivot is to be moved to bufferPos, false if pivot is to stay the same
   * @return a new cursor position
   */
  updatePos(bufferPos: BufferPosition, movePivot: boolean) {
    if (!movePivot) {
      return new CursorPosition(this.pivotPos, bufferPos);
    } else {
      return new CursorPosition(bufferPos, bufferPos);
    }
  }

  /**
   * Determine if this cursorPos equals another (has same pivot and reach).
   * @param {CursorPosition} other the other position
   * @return true if they are equal, false otherwise
   */
  equals(other: CursorPosition) {
    return (this.pivotPos.equals(other.pivotPos) && this.reachPos.equals(other.reachPos));
  }
}