
/*
  WebRTCDataConnection manages a connection (or connection attempt) with another device over WebRTC for the purpose of sending and receiving data over a datachannel.

  Use WebRTCStreamConnection for audio/video streaming.

*/

// NOTE: The use of the word 'peer' in this file is distinct from the use of the word 'peer' in most other files in this project. In this file, it matches the WebRTC notion of 'peer'.

/*
  Typical caller flow
  + makeCall() is called
  +   createDataChannels()
  +   pc.createOffer() // -> onLocalDescription
  + onLocalDescription:
  +   pc.setLocalDescription() // -> pc.onicecandidate
  +   local description sent to peer
  + pc.onicecandidate: // happens for each new ice candidate
  +   ice candidate sent to peer
  + message containing remote description
  +   pc.setRemoteDescription()
  + message containing ice candidate
  +   pc.addIceCandidate()

  Typical answerer flow
  + message containing remote description
  +   pc.setRemoteDescription()
  +   answerCall() // -> answerCall()
  + answerCall():
  +   pc.createAnswer() // -> onLocalDescription
  + message containing ice candidate
  +   pc.addIceCandidate()
  + onLocalDescription:
  +   pc.setLocalDescription() // -> pc.onicecandidate
  +   local description sent to peer
  + pc.onicecandidate:
  +   ice candidate sent to peer
*/

/*
  Notes on message size:
  There is a nice blog on the datachannel implementation here: http://viblast.com/blog/2015/2/5/webrtc-data-channel-message-size/
  Here are a few take-aways:
  + Individual data packets should not exceed 16KB
  + Under the hood, the data packets are kept in outgoing/incoming buffers. If these buffers run out, the datachannel is closed.
  + datachannel.bufferedAmount tells the size of outgoing buffer. It should not exceed 16MB in Chrome.

*/


import {
  webRTCIsSupported,
  WebRTC_configuration
} from "./configuration"

import { WebRTCLogger, WebRTCLoggerConnection } from "./WebRTCLogger"
const globalWebRTCLogger = new WebRTCLogger();
if (typeof(window) !== "undefined") {
  // @ts-ignore
  window["rtc"] = globalWebRTCLogger;
}

export function make_connection(deviceId: string, sendToPeerViaServer: (data: any) => void): WebRTCDataConnection {
  const logger = globalWebRTCLogger.newConnection(deviceId);
  if (webRTCIsSupported) {
    return new WorkingWebRTCDataConnection(logger, sendToPeerViaServer);
  } else {
    return new DummyWebRTCDataConnection(logger, sendToPeerViaServer);
  }
}

type SignalsType = {
  onObject: ((obj: Object) => void) | null,
}

export abstract class WebRTCDataConnection {

  public signals: SignalsType = {
    onObject: null,
  }

  protected _logger: WebRTCLoggerConnection;
  protected _sendToPeerViaServer: (data: any) => void;

  constructor(logger: WebRTCLoggerConnection, sendToPeerViaServer: (data: any) => void) {
    this._logger = logger;
    this._sendToPeerViaServer = sendToPeerViaServer;
    logger.setStatus('init', 'started');
  }

  public abstract makeCall(): void;
  public abstract sendObject(obj: Object): boolean;
  public abstract onMessageViaServer(message: any): void;

}


class DummyWebRTCDataConnection extends WebRTCDataConnection {

  constructor(logger: WebRTCLoggerConnection, sendToPeerViaServer: (data: any) => void) {
    super(logger, sendToPeerViaServer);
    logger.log('RTCPeerConnection is undefined.');
    logger.setStatus('init', 'done');
    logger.setStatus('status', 'failed');
    logger.setStatus('reason', 'I_dont_support_webrtc');
  }

  public makeCall() { this._sendToPeerViaServer({ type: 'no_webrtc' }); }
  public sendObject(obj: Object) { return false; }
  public onMessageViaServer() { this._sendToPeerViaServer({ type: 'no_webrtc'}); }

}

// parameters for breaking data into chunks
const bytesPerChar = 2;
const pieceOverhead = 1;
const maxPieceSize = 16000 / bytesPerChar - pieceOverhead;
const dataChannelBufferThreshold = 8 * 1024 * 1024; // 8 MB

class WorkingWebRTCDataConnection extends WebRTCDataConnection {

  private _pc: RTCPeerConnection;
  private _iAmCaller: boolean = false;
  private _dataChannel: RTCDataChannel|null = null;

  // for receiving data
  private _buffer: string = "";

  // for sending data
  private _piecesQueue: string[] = [];
  private _flushInterval: number|null = null;

  constructor(logger: WebRTCLoggerConnection, sendToPeerViaServer: (data: any) => void) {
    super(logger, sendToPeerViaServer);
    const pc = new RTCPeerConnection(WebRTC_configuration);
    this._pc = pc;
    // to stay up-to-date on connection state (this doesn't seem to be working)
    pc.onconnectionstatechange = (event) => {
      logger.log('pc.onconnectionstatechange: ');
      logger.log(pc.connectionState);
      logger.log(event);
      logger.setStatus('connectionState', pc.connectionState);
    }
    // this happens after
    //   calling peer: pc.setLocalDescription() (in onLocalDescription)
    //   answering peer: pc.setLocalDescription() (in onLocalDescription)
    pc.onicecandidate = (event) => {
      // About ICE candidates: Each ICE message suggests a communication protocol (TCP or UDP), IP address, port number, and connection type (for example, whether the specified IP is the peer itself or a relay server), as well as any other information needed to link the two computers together, even if there's NAT or other complications between the two.
      if (event.candidate) {
        logger.log('pc.onicecandidate: ');
        logger.log(event.candidate);
        logger.addCandidate(event.candidate);
        // send event.candidate to the peer
        sendToPeerViaServer({ type: 'ice_candidate', ice_candidate: event.candidate });
      } else {
        // All ICE candidates have been sent
        logger.log('pc.onicecandidate: (no more ICE candidates remain)');
      }
    };
    // only happens for remote channels
    pc.ondatachannel = (event) => {
      logger.log('pc.ondatachannel');
      logger.log(event);
      this._dataChannel = event.channel;
      this._setDataChannelHandlers(this._dataChannel);
    }
    logger.setStatus('init', 'done');
  }

  public async makeCall(): Promise<void> {
    this._logger.log('makeCall()');
    this._logger.setStatus('caller', true);
    this._logger.setStatus('status', 'calling...');

    // TODO is there still an issue with Firefox calling Chrome? (support this)
    this._createDataChannels();

    this._iAmCaller = true;
    try {
      const description = await this._pc.createOffer();
      this._onLocalDescription(description);
    } catch (error) {
      this._logger.log('pc.createOffer() error');
      this._logger.log(error);
      this._logger.addError(error, 'pc.createOffer()');
      this._logger.setStatus('status', 'error_creating_offer');
    }
  }

  public async onMessageViaServer(message: any): Promise<void> {
    const logger = this._logger;
    if (message.type === 'description') {
      logger.log('onMessageViaServer: got description from peer: ');
      logger.log(message.description);
      logger.setRemoteDescription(message.description);
      try {
        await this._pc.setRemoteDescription(new RTCSessionDescription(message.description));
      } catch (error) {
        logger.log('pc.setRemoteDescription() error');
        logger.log(error);
        logger.addError(error, 'pc.setRemoteDescription');
        logger.setStatus('status', 'error_setting_remote_description');
      }
      // if this is not the calling peer, answer the call
      if (!this._iAmCaller) {
        this._answerCall();
      }
    } else if (message.type === 'ice_candidate') {
      logger.log('onMessageViaServer: got ice_candidate from peer: ');
      logger.log(message.ice_candidate);
      logger.addCandidate(message.ice_candidate); // comment to exclude candidates from peer
      try {
        await this._pc.addIceCandidate(new RTCIceCandidate(message.ice_candidate));
      } catch (error) {
        logger.log('pc.addIceCandidate() error');
        logger.log(error);
        logger.addError(error, 'pc.addIceCandidate()');
        logger.setStatus('status', 'error_adding_remote_ice_candidate');
      }
    } else if (message.type === 'no_webrtc') {
      logger.log('onMessageViaServer: peer does not support WebRTC.');
      logger.setStatus('status', 'failed');
      logger.setStatus('reason', 'peer_doesnt_support_webrtc');
    } else {
      logger.log('onMessageViaServer: message with unknown type');
      logger.log(message);
    }
  }

  public sendObject(obj: Object): boolean {
    if (!this._dataChannel || !(this._dataChannel.readyState === 'open')) {
      return false;
    } else {
      this._sendData(JSON.stringify(obj));
      return true;
    }
  }

  /////////////
  // private //
  /////////////

  private async _answerCall() {
    this._logger.log('answerCall()');
    this._logger.setStatus('answerer', true);
    this._logger.setStatus('status', 'answering...');

    try {
      const description = await this._pc.createAnswer();
      this._onLocalDescription(description);
    } catch (error) {
      this._logger.log('pc.createAnswer() error');
      this._logger.log(error);
      this._logger.addError(error, 'pc.createAnswer()');
      this._logger.setStatus('status', 'error_creating_answer');
    }
  }

  // this happens after
  //   calling peer: pc.createOffer() in makeCall()
  //   answering peer: pc.createAnswer() in answerCall()
  private _onLocalDescription(description: RTCSessionDescriptionInit) {
    this._logger.log('onLocalDescription: ');
    this._logger.log(description);
    this._logger.setLocalDescription(description);
    try {
      // After pc.setLocalDescription, pc.onicecandidate will happen
      this._pc.setLocalDescription(description);
    } catch (error) {
      this._logger.log('pc.setLocalDescription() error');
      this._logger.log(error);
      this._logger.addError(error, 'pc.setLocalDescription()');
      this._logger.setStatus('status', 'error_setting_local_description');
    }
    // send description to peer
    this._sendToPeerViaServer({ type: 'description', description: description });
  }

  ////////////////////
  // receiving data //
  ////////////////////

  private _onReceivedPiece(data: any) {
    this._buffer += data.substr(1, data.length - 1);
    if (data[0] === '1') {
      // all pieces have been received
      if (this.signals.onObject) {
        this.signals.onObject(JSON.parse(this._buffer));
      }
      this._buffer = '';
    }
  }

  //////////////////
  // sending data //
  //////////////////

  private _sendData(data: string) {
    // break up the data into pieces and enqueue them
    const numPieces = Math.ceil(data.length / maxPieceSize);
    for (var i = 0; i < numPieces; i++) {
      var start = i * maxPieceSize;
      var length = Math.min(maxPieceSize, data.length - start);
      var prefix = '0'; // indicates there are more pieces to come
      if (i + 1 === numPieces) {
        prefix = '1'; // indicates message is finished
      }
      this._piecesQueue.push(prefix + data.substr(start, length));
    }
    this._tryToEmptyPiecesQueue();
  }

  private _tryToEmptyPiecesQueue() {
    while (
        !(this._piecesQueue.length===0)
      && this._dataChannel
      && this._dataChannel.bufferedAmount < dataChannelBufferThreshold
    ) {
      const piece = this._piecesQueue.shift();
      if (piece) {
        // this should always hold true
        this._dataChannel.send(piece);
      }
    }
    if (!(this._piecesQueue.length===0)) {
      // not all pieces were sent, because the datachannel buffer is full
      if (this._flushInterval == null) {
        this._flushInterval = window.setInterval(() => {
          this._tryToEmptyPiecesQueue();
        }, 10);
      }
    } else {
      // the queue has been emptied
      if (this._flushInterval != null) {
        clearInterval(this._flushInterval);
        this._flushInterval = null;
      }
    }
  }

  //////////////////
  // data channel //
  //////////////////

  private _onDataChannelOpen(event: any) {
    this._logger.log('channel.onopen: ');
    this._logger.log(event);
    this._logger.setStatus('status', 'open');
    this._logger.setStatus('datachannel', 'open');
  }
  private _onDataChannelMessage(event: any) {
    this._onReceivedPiece(event.data);
  }
  private _onDataChannelClose(event: any) {
    this._logger.log('channel.onclose: ');
    this._logger.log(event);
    this._logger.setStatus('status', 'closed');
    this._logger.setStatus('datachannel', 'closed');
  }
  private _onDataChannelError(event: any) {
    this._logger.log('channel.onerror: ');
    this._logger.log(event);
    this._logger.addError(event, 'onDataChannelError()');
  }

  // to be called by the peer that initiates the call
  //   (on the answering end, data channels show up in pc.ondatachannel)
  private _createDataChannels() {
    this._dataChannel = this._pc.createDataChannel('general purpose'); // default options are ok
    this._setDataChannelHandlers(this._dataChannel);
  }

  private _setDataChannelHandlers(dataChannel: RTCDataChannel) {
    dataChannel.onopen = (event: any) => { this._onDataChannelOpen(event); };
    dataChannel.onmessage = (event: any) => { this._onDataChannelMessage(event); };
    dataChannel.onclose = (event: any) => { this._onDataChannelClose(event); };
    dataChannel.onerror = (event: any) => { this._onDataChannelError(event); };
  }

}
