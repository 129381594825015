import React from "react"

import { useLocation } from "@reach/router"
import BoardContext from "../../components/board/BoardContext"

export default () => {
	const location = useLocation();
	// a regex would be better here
	if (location.pathname.startsWith("/staging/board/") && location.pathname !== "/staging/board/") {
		const boardId = location.pathname.substr("/staging/board/".length);
		return (
			<BoardContext boardId={boardId} />
		);
	} else {
		return (
			<p>Expected url starting with "/staging/board/"</p>
		)
	}
};