import React from "react"

const css = require("../toolbar.module.css");
import fa from "../../lib/fontawesome"

import {
	onHighlighterCyanBtnClicked,
	onHighlighterGreenBtnClicked,
	onHighlighterMagentaBtnClicked,
	onHighlighterYellowBtnClicked,
	onHighlighterSize1BtnClicked,
	onHighlighterSize2BtnClicked,
	onHighlighterSize3BtnClicked,
	onHighlighterSize4BtnClicked,
} from "../../../ui/buttonCallbacks"

export default ({tool, addCtx}) => (
	<div
		className={css.horizontalButtons}
		style={{position: "fixed", left:"45px", top:"37px"}}
	>
		<button
			className={`${css.btn} ${tool.details.color.css==="#ffff00" && css.btnOn}`}
			onClick={addCtx(onHighlighterYellowBtnClicked)}
		>
			<i className={`${fa.fa} ${fa.faGoogleWallet}`} style={{color:"#f4d742"}} />
		</button>
		<button
			className={`${css.btn} ${tool.details.color.css==="#00ffff" && css.btnOn}`}
			onClick={addCtx(onHighlighterCyanBtnClicked)}
		>
			<i className={`${fa.fa} ${fa.faGoogleWallet}`} style={{color:"cyan"}} />
		</button>
		<button
			className={`${css.btn} ${tool.details.color.css==="#ff00ff" && css.btnOn}`}
			onClick={addCtx(onHighlighterMagentaBtnClicked)}
		>
			<i className={`${fa.fa} ${fa.faGoogleWallet}`} style={{color:"magenta"}} />
		</button>
		<button
			className={`${css.btn} ${tool.details.color.css==="#7fff00" && css.btnOn}`}
			onClick={addCtx(onHighlighterGreenBtnClicked)}
		>
			<i className={`${fa.fa} ${fa.faGoogleWallet}`} style={{color:"#7FFF00"}} />
		</button>
		<button
			className={`${css.btn} ${tool.details.size===15 && css.btnOn}`}
			onClick={addCtx(onHighlighterSize1BtnClicked)}
		>
			<i className={`${fa.fa} ${fa.faCircle}`} style={{fontSize:".75em", verticalAlign: "middle"}}/>
		</button>
		<button
			className={`${css.btn} ${tool.details.size===20 && css.btnOn}`}
			onClick={addCtx(onHighlighterSize2BtnClicked)}
		>
			<i className={`${fa.fa} ${fa.faCircle}`} style={{fontSize:"1em", verticalAlign: "middle"}}/>
		</button>
		<button
			className={`${css.btn} ${tool.details.size===25 && css.btnOn}`}
			onClick={addCtx(onHighlighterSize3BtnClicked)}
			style={{paddingTop:"2px"}}
		>
			<i className={`${fa.fa} ${fa.faCircle}`} style={{fontSize:"1.5em", verticalAlign: "middle"}}/>
		</button>
		<button
			className={`${css.btn} ${tool.details.size===30 && css.btnOn}`}
			onClick={addCtx(onHighlighterSize4BtnClicked)}
			style={{paddingLeft:"4px"}}
		>
			<i className={`${fa.fa} ${fa.faCircle}`} style={{fontSize:"2em", verticalAlign: "middle"}}/>
		</button>
	</div>
);