
import Color from "../Color";
import Matrix from '../Matrix';
import Rect from '../Rect';
import Point from '../Point';
import { MinifiedShape } from '../../state/shapes';
import Parallelogram from '../Parallelogram';
import { Canvas } from "../../Canvas";

export default abstract class Shape {

	protected _p0: Point;
	protected _p1: Point;

	constructor(protected _size: number, protected _color: Color, x0: number, y0: number) {
		this._p0 = new Point(x0, y0);
		this._p1 = new Point(x0, y0);
	}

	public abstract drawOnCanvas(canvas: Canvas, matrix: Matrix, factor?: number): void;

	public abstract getBoundingRect(matrix: Matrix): Rect;

	public abstract intersectsParallelogram(parallelogram: Parallelogram): boolean;

	public abstract minify(): MinifiedShape;

	// public abstract getPdfgenData(matrix: Matrix);

	public updateXY(x: number, y: number) {
		this._p1 = new Point(x, y);
	}

}