/**
 * A BufferPosition represents a particular location in a TextBuffer.
 * It has two fields, input and inputChar, both of which are nonnegative.
 *
 * A BufferPosition is immutable.
 */

export default class BufferPosition {

  constructor(
    public readonly input: number,
    public readonly inputChar: number
  ) {}

  moveToStartOfNextInput() {
    return new BufferPosition(this.input + 1, 0);
  }

  /**
   * Returns a positive number if this appears after the other position, negative if it appears before, or zero if equal.
   * @param {BufferPosition} other the other position
   * @return A positive number if this appears after other, a negative number if it appears before, or zero if equal.
   */
  compareTo(other: BufferPosition) {
    if (this.input !== other.input) {
      return this.input - other.input;
    }
    else {
      return this.inputChar - other.inputChar;
    }
  }

  /**
   * Returns whether this bufferPos matches the other bufferPos.
   * Note that this.equals(other) is true iff this.compareTo(other) === 0.
   * @param {BufferPosition} other the other position
   * @return true if the bufferPos's match, false otherwise
   */
  equals(other: BufferPosition) {
    return this.input === other.input && this.inputChar === other.inputChar;
  }
}