
import PathItem from './PathItem'
import ShapeItem from "./ShapeItem"
import TextItem from "./TextItem"
import { ItemState } from '../state/items'
import SimpleDocument from '../document/SimpleDocument'
import Item from './Item';

// this helps avoid a circular dependency in which Item must import eg. PathItem
export default function Item_fromState(state: ItemState, document: SimpleDocument): Item {
	switch(state.t) {
		case 'path':
			return PathItem.fromState(state, document);
		case 'shape':
			return ShapeItem.fromState(state);
		case 'text':
		 	return TextItem.fromState(state, document);
	}
}