
import Shape from "./Shape";
import Matrix from '../Matrix';
import Point from '../Point';
import Rect from '../Rect';
import { MinifiedSegmentShape } from "../../state/shapes";
import Parallelogram from "../Parallelogram";
import Color from "../Color"
import { Canvas } from "../../Canvas";

const ARROW_SIZE = 72 / 12;
const ARROW_ANGLE = Math.PI / 8;

export default class SegmentShape extends Shape {

	private _arrows: number;

	constructor(size: number, color: Color, x0: number, y0: number,  arrows: number = 0) {
		super(size, color, x0, y0);
		this._arrows = arrows;
	}

	public drawOnCanvas(canvas: Canvas, matrix: Matrix, factor: number = 1) {
		const ctx = canvas.getContext('2d');
		const p0 = matrix.timesPoint(this._p0);
		const p1 = matrix.timesPoint(this._p1);
		ctx.save();
		ctx.beginPath();
		ctx.strokeStyle = this._color.css;
		ctx.lineWidth = this._size * factor;
		ctx.lineCap = 'round';
		ctx.moveTo(p0.x, p0.y);
		ctx.lineTo(p1.x, p1.y);
		if (this._arrows > 0) {
			this._drawArrow(ctx, p0, p1, this._size * factor);
		}
		if (this._arrows > 1) {
			this._drawArrow(ctx, p1, p0, this._size * factor);
		}
		ctx.stroke();
		ctx.restore();
	}

	public getPdfgenData(matrix: Matrix) {
		const p0 = matrix.timesPoint(this._p0);
		const p1 = matrix.timesPoint(this._p1);
		let segments: [number, number, number, number][] = [
			[p0.x, p0.y, p1.x, p1.y],
		];
		if (this._arrows > 0) {
			let vecs = SegmentShape._computeArrowheadVectors(p0, p1, this._size);
			segments.push([p1.x, p1.y, p1.x + vecs[0].x, p1.y + vecs[0].y]);
			segments.push([p1.x, p1.y, p1.x + vecs[1].x, p1.y + vecs[1].y]);
		}
		if (this._arrows > 1) {
			let vecs = SegmentShape._computeArrowheadVectors(p1, p0, this._size);
			segments.push([p0.x, p0.y, p0.x + vecs[0].x, p0.y + vecs[0].y]);
			segments.push([p0.x, p0.y, p0.x + vecs[1].x, p0.y + vecs[1].y]);
		}
		return {
			t: 'shape',
			st: 'segment',
			c: this._color.state,
			s: this._size,
			segments,
		};
	}

	private static _computeArrowheadVectors(p0: Point, p1: Point, size: number) {
		let norm = Math.sqrt((p1.x-p0.x)*(p1.x-p0.x)+(p1.y-p0.y)*(p1.y-p0.y));
		let scale = ARROW_SIZE * size / norm;
		let vec = new Point((p0.x-p1.x) * scale, (p0.y-p1.y) * scale);
		let vec1 = Matrix.rotateMatrix(ARROW_ANGLE).timesPoint(vec);
		let vec2 = Matrix.rotateMatrix(-ARROW_ANGLE).timesPoint(vec);
		return [vec1, vec2];
	}

	private _drawArrow(ctx: any, p0: Point, p1: Point, size: number) {
		let vecs = SegmentShape._computeArrowheadVectors(p0, p1, size);
		ctx.moveTo(p1.x, p1.y);
		ctx.lineTo(p1.x + vecs[0].x, p1.y + vecs[0].y);
		ctx.moveTo(p1.x, p1.y);
		ctx.lineTo(p1.x + vecs[1].x, p1.y + vecs[1].y);
	}

	public getBoundingRect(matrix: Matrix) {
		const p0 = matrix.timesPoint(this._p0);
		const p1 = matrix.timesPoint(this._p1);
		return Rect.fromXYXY(p0.x, p0.y, p1.x, p1.y);
	}

	public intersectsParallelogram(parallelogram: Parallelogram) {
		const p0 = parallelogram.inverseMatrix.timesPoint(this._p0);
		const p1 = parallelogram.inverseMatrix.timesPoint(this._p1);
		const rect = parallelogram.rect;
		// does the rect contain one of the endpoints?
		if (rect.containsPointXY(p0.x, p0.y) || rect.containsPointXY(p1.x, p1.y)) {
			return true;
		}
		// are both endpoints on the same side of the rect?
		if (
			Math.max(p0.x, p1.x) < rect.left ||
			Math.min(p0.x, p1.x) > rect.right() ||
			Math.max(p0.y, p1.y) < rect.top ||
			Math.min(p0.y, p1.y) > rect.bottom()
		) {
			return false;
		}
		// is the segment horizontal or vertical?
		if (p0.y === p1.y || p0.x === p0.y) {
			return true;
		}
		// general case: must solve linear equations
		// segment equation: y = m x + b
		let m = (p1.y - p0.y) / (p1.x - p0.x);
		let b = p0.y - m * p0.x;

		const yLeft = m * rect.left + b;
		if (rect.top < yLeft && yLeft < rect.bottom()) {
			return true;
		}
		const yRight = m * rect.right() + b;
		if (rect.top < yRight && yRight < rect.bottom()) {
			return true;
		}
		const xTop = (rect.top - b) / m;
		if (rect.left < xTop && xTop < rect.right()) {
			return true;
		}
		const xBottom = (rect.bottom() - b) / m;
		if (rect.left < xBottom && xBottom < rect.right()) {
			return true;
		}
		return false;
	}

	public minify(): MinifiedSegmentShape {
		return {
			type: 'segment',
			size: this._size,
			color: this._color,
			p0: [ this._p0.x, this._p0.y ],
			p1: [ this._p1.x, this._p1.y ],
			arrows: this._arrows,
		};
	}

	public static unminify(mini: MinifiedSegmentShape): SegmentShape {
		const shape = new SegmentShape(mini.size, Color.fromState(mini.color), mini.p0[0], mini.p0[1], mini.arrows);
		shape.updateXY(mini.p1[0], mini.p1[1]);
		return shape;
	}

}
