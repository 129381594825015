
import { ResourceState } from '../state/resources'

export default abstract class Resource {

  // specifies whether this device created the delta
  //   defaults to true, recipient is responsible for setting to false
  //   should not be serialized
  public local: boolean = true;
  
  public readonly id: string;

  constructor(id: string) {
    this.id = id;
  }

  public abstract get state(): ResourceState;

}