
import { ColorState } from '../../state/structs'
import Sender from '../local/Sender'
import Color from '../../geometry/Color'
import Channel from './Channel';

type PenDownMessage = {
	type: 'down',
	N: number
	color: ColorState,
	size: number,
	x: number,
	y: number,
};

type PenDragMessage = {
	type: 'drag',
	N: number,
	n: number,
	x: number,
	y: number,
};

type PenUpMessage = {
	type: 'up',
	N: number,
};

function isPenDownMessage(m: any): m is PenDownMessage {
	return 'type' in m
			&& m.type === 'down'
			&& 'N' in m
			&& 'color' in m
			&& 'size' in m
			&& 'x' in m
			&& 'y' in m
			;
}

function isPenDragMessage(m: any): m is PenDragMessage {
	return 'type' in m
			&& m.type === 'drag'
			&& 'N' in m
			&& 'n' in m
			&& 'x' in m
			&& 'y' in m
			;
}

function isPenUpMessage(m: any): m is PenUpMessage {
	return 'type' in m
			&& m.type === 'up'
			&& 'N' in m
			;
}

export class DrawingSender {

	private _sequence: number = 0;
	private _subSequence: number = 0;
	
	constructor(private _sender: Sender) {
	}

	down(color: Color, size: number, x: number, y: number) {
		this._subSequence = 0;
		const message: PenDownMessage = {
			type: 'down',
			N: ++this._sequence,
			color: color.state,
			size,
			x,
			y,
		};
		this._sender.broadcastDel('draw', message);
	}

	drag(x: number, y: number) {
		const message: PenDragMessage = {
			type: 'drag',
			N: this._sequence,
			n: ++this._subSequence,
			x,
			y,
		};
		this._sender.broadcastDel('draw', message);
	}

	up() {
		const message: PenUpMessage = {
			type: 'up',
			N: this._sequence,
		};
		this._sender.broadcastDel('draw', message);
	}

}

export class DrawingReceiverChannel extends Channel {

	private _sequence: number = 0;
	private _subSequence: number = 0;
	private _dragging: boolean = false;

	constructor(
		private _ondown: (color: Color, size: number, x: number, y: number) => void,
		private _ondrag: (x: number, y: number) => void,
		private _onup: () => void,
	) {
		super();
	}

	public receive(message: any) {
		if (isPenDownMessage(message)) {
			if (message.N > this._sequence) {
				if (this._dragging) {
					this._onup();
					this._dragging = false; // noop
				}
				const color = Color.fromState(message.color);
				this._ondown(color, message.size, message.x, message.y);
				this._sequence = message.N;
				this._subSequence = 0;
				this._dragging = true;
			}
		} else if(isPenDragMessage(message)) {
			if (message.N === this._sequence) {
				if (this._dragging) {
					// TODO also check subsequence
					this._ondrag(message.x, message.y);
				}
			} else if (message.N > this._sequence) {
				this._onup();
				this._dragging = false;
			}
		} else if(isPenUpMessage(message)) {
			if (message.N >= this._sequence && this._dragging) {
				this._onup();
				this._dragging = false;
			}
		}
	}

}