////////////////////////////////////////////////////////////////////////////////
//  TextBuffer public methods:                                                //
//      + insertChar(charPos, inputChar)                                      //
//      + deleteChar(charPos)                                                 //
//      + minify()                                                            //
//      + unminify(array)                                                     //
//                                                                            //
//  TextBuffer private methods:                                               //
//      + getBufferPos(charPos)                                               //
////////////////////////////////////////////////////////////////////////////////

import TextInput, { TextInputType } from './input/TextInput';
import TextInput_fromState from './input/factory';
import TextTextInput from './input/TextTextInput';
import LatexTextInput from './input/LatexTextInput';
import BeginFormattingTextInput, { FormattingStack } from './input/BeginFormattingTextInput';
import EndFormattingTextInput from './input/EndFormattingTextInput';
import NewLineTextInput from './input/NewLineTextInput';
import TextFormatting, { FormattingObject } from './TextFormatting';
import BufferPosition from './BufferPosition';
import { TextInputState, TextTextInputState } from '../state/text';
import CursorPosition from './CursorPosition';

export type RawInput = {type:'string',string:string} | {type:'formatting',formatting:FormattingObject};

/**
 * @param inputs an array of TextInputs
 */
export default class TextBuffer {

  public inputs: TextInput[];
  public length: number;

  constructor(inputs: TextInput[]) {
    this.inputs = [];
    // number of characters in the buffer
    this.length = 0;
    inputs.forEach((input) => {
      this.inputs.push(input);
      this.length += input.length;
    });
  }

  inTextInput(bufferPos: BufferPosition) {
    return this.inputs[bufferPos.input] &&
            this.inputs[bufferPos.input].type === TextInputType.text;
  }

  atEndOfInput(bufferPos: BufferPosition) {
    return this.inputs[bufferPos.input] &&
      bufferPos.inputChar === this.inputs[bufferPos.input].length;
  }

  /**
   * Converts the input string into an array of text and newline preInputs
   * @param {string} string
   */
  static parseString(string: string) {
    var parsedString = [];
    var i = 0;
    while (i < string.length) {
      var prevI = i;
      while (string.charAt(i) !== '\n' && i < string.length) {
        i++;
      }
      if (i > prevI) {
        // if the substring is non-empty, push a text object
        parsedString.push({ type: TextInputType.text, text: string.substring(prevI, i) });
      }
      if (i < string.length) {
        parsedString.push({ type: TextInputType.new_line });
      }
      i++;
    }
    return parsedString;
  }

  insertEmptyTextInput(bufferPos: BufferPosition) {
    this.inputs.splice(bufferPos.input, 0, new TextTextInput(''));
  }

  /**
   * After this method, bufferPos.input points to a TextTextInput of
   * the buffer at which we can insert text.
   */
  prepareBufferForTextInsertion(bufferPos: BufferPosition) {
    let atStartOfBuffer = this.getOverallCharPos(bufferPos) === 0;

    if (!this.inTextInput(bufferPos) && this.atEndOfInput(bufferPos)) {
      bufferPos = bufferPos.moveToStartOfNextInput();
    }
    if (!this.inTextInput(bufferPos)) {
      this.insertEmptyTextInput(bufferPos);
    }

    // By construction, this.inTextInput(bufferPos) should always be true
    if (!this.inTextInput(bufferPos)) {
      console.log('ERROR: TextBuffer.prepareBufferForTextInsertion() did not leave us in a TextInput');
      console.log('Error details: bufferPos.input is ', bufferPos.input, ', buffer is ', this.inputs);
    }
    return bufferPos;
  }

  /**
   * After this method, TextInput can be spliced into the buffer at
   * bufferPos.input.
   */
  prepareBufferForNonTextInsertion(bufferPos: BufferPosition) {
    if (this.atEndOfInput(bufferPos)) {
      bufferPos = bufferPos.moveToStartOfNextInput();
    } else if (this.inTextInput(bufferPos)) {
      // if we're in the middle of a TextInput, split it into into prefix and suffix
      var input = this.inputs[bufferPos.input];
      var prefix = (input as TextTextInput).text.substring(0, bufferPos.inputChar);
      var suffix = (input as TextTextInput).text.substring(bufferPos.inputChar);
      this.inputs.splice(bufferPos.input, 1,
        new TextTextInput(prefix), new TextTextInput(suffix));
      bufferPos = bufferPos.moveToStartOfNextInput();
    }
    return bufferPos;
  }

  // this is only called from one place, where it is clear that this.inputs[bufferPos.input] is of type TextTextInput
  private insertText(text: string, bufferPos: BufferPosition) {
    bufferPos = this.prepareBufferForTextInsertion(bufferPos);

    const input = this.inputs[bufferPos.input];
    const prefix = (input as TextTextInput).text.substring(0, bufferPos.inputChar);
    const suffix = (input as TextTextInput).text.substring(bufferPos.inputChar);
    const newText = `${ prefix }${ text }${ suffix }`;
    // replace this input with a new one
    this.inputs[bufferPos.input] = new TextTextInput(newText);

    this.length += text.length;
    return new BufferPosition(bufferPos.input, bufferPos.inputChar + text.length);
  }

  insertNewLine(bufferPos: BufferPosition) {
    bufferPos = this.prepareBufferForNonTextInsertion(bufferPos);
    this.inputs.splice(bufferPos.input, 0, new NewLineTextInput());
    this.length++;
    return new BufferPosition(bufferPos.input, this.inputs[bufferPos.input].length);
  }

  insertFormatting(formatting: FormattingObject, bufferPos: BufferPosition) {
    bufferPos = this.prepareBufferForNonTextInsertion(bufferPos);

    var property = formatting.property;
    var value = formatting.value;
    if (value === null) {
      // then we're in true/false format case
      var prevFormatting = this.getCursorFormatting(bufferPos.input - 1);
      value = !prevFormatting[formatting.property];
    }
    var endFormat = new EndFormattingTextInput();
    var beginFormat  = new BeginFormattingTextInput(property, value);

    this.inputs.splice(bufferPos.input, 0, beginFormat, endFormat);
    return new BufferPosition(bufferPos.input, 0);
  }

  /**
   * Inserts strings (including parsing new lines) and formatting into the buffer
   * @param bufferPos properties input and inputChar
   * @param rawInput an object with property type, and some data.
   * At present, supports rawInput of types 'string' and 'formatting'.
   * 'string' rawInputs have a string property, which can include new lines. New
   * lines are inserted as NewLineTextInputs
   * 'formatting' rawInputs have a formatting property, which is a property-value
   * pair, e.g. { property: 'bold', value: null }
   * @return new cursor position
   */
  insert(bufferPos: BufferPosition, rawInput: RawInput) {
    if (rawInput.type === 'string') {
      var parsedString = TextBuffer.parseString(rawInput.string);

      for (var preInput of parsedString) {
        if (preInput.type === TextInputType.text) {
          bufferPos = this.insertText(preInput.text!, bufferPos);
        } else if (preInput.type === TextInputType.new_line) {
          bufferPos = this.insertNewLine(bufferPos);
        }
      }
    } else if (rawInput.type === 'formatting') {
      bufferPos = this.insertFormatting(rawInput.formatting, bufferPos);
    } else {
      // ERROR MESSAGE for potential debugging, replace with testing
      console.log('ERROR: TextBuffer.insert(), unknown rawInput type ', (rawInput as any).type);
    }
    return bufferPos;
  }

  /**
   * Deletes the character between (getOverallCharPos(bufferPos) - 1) and
   * getOverallCharPos(bufferPos). Does nothing if
   * getOverallCharPos(bufferPos) === 0. Assumes that
   * 0 <= getOverallCharPos(bufferPos) <= number of characters in the buffer
   * @param bufferPos
   * @return new bufferPos
   */
  deleteCharBefore(bufferPos: BufferPosition): BufferPosition {
    const originalBufferPos = bufferPos;
    bufferPos = this.endNormalizeBufferPos(bufferPos);
    if (bufferPos.input === 0 && bufferPos.inputChar === 0) {
      // no operation: at beginning of text.
      return originalBufferPos; // don't end-normalize on no-op
    }
    const input = this.inputs[bufferPos.input];
    if (input.length === 1) {
      // in this case, we want to remove this input from the buffer

      /* ERROR MESSAGE (for potential debugging) - replace with testing */
      if (bufferPos.inputChar !== 1) {
        console.log('ERROR: TextBuffer.deleteCharBefore() - assumed that, for length 1 input, getBufferPos() takes us to end of input');
        console.log('Error details: bufferPos = ', bufferPos, ', input = ', input);
      }
      this.inputs.splice(bufferPos.input, 1);
      if (bufferPos.input > 0) {
        bufferPos = new BufferPosition(bufferPos.input - 1, this.inputs[bufferPos.input - 1].length);
      } else {
        bufferPos = new BufferPosition(0, 0);
      }
    } else if (input.type === TextInputType.text) {
      const prefix = (input as TextTextInput).text.substring(0, bufferPos.inputChar - 1);
      const suffix = (input as TextTextInput).text.substring(bufferPos.inputChar);
      const newText = `${ prefix }${ suffix }`;
      this.inputs[bufferPos.input] = new TextTextInput(newText);
      bufferPos = new BufferPosition(bufferPos.input, bufferPos.inputChar - 1);
    } else {
      /* ERROR MESSAGE (for potential debugging) - replace with testing */
      console.log('ERROR: TextBuffer.deleteCharBefore() - unknown input type');
      console.log('Error details: input = ', input);
    }
    this.length--;
    return bufferPos;
  }

  deleteCharAfter(bufferPos: BufferPosition): BufferPosition {
    var overallCharPos = this.getOverallCharPos(bufferPos);
    if (this.inputs.length === overallCharPos) {
      return bufferPos;
    }
    // major hack time!
    return this.deleteCharBefore(bufferPos);
  }

  deleteSelection(cursorPos: CursorPosition): BufferPosition {
    if (!cursorPos.isSelection) {
      // not a selection, no effect
      return cursorPos.beginPos;
    }
    const beginCharPos = this.getOverallCharPos(cursorPos.beginPos);
    let charPos = this.getOverallCharPos(cursorPos.endPos);
    // TODO: major FIXME: delete all inputs at once rather than character-by-character
    let bufferPos = cursorPos.endPos;
    while (charPos > beginCharPos) {
      bufferPos = this.deleteCharBefore(bufferPos);
      charPos = this.getOverallCharPos(bufferPos);
    }
    return bufferPos;
  }

  public get state() {
    return this.inputs.map(input => input.state);
  }

  /**
   * @param objectInputs an array of objects, each encoding a TextInput
   * @return a TextBuffer
   */
  static fromState(objectInputs: TextInputState[]) {
    return new TextBuffer(objectInputs.map((state: TextInputState) => TextInput_fromState(state)));
  }

  /**
   * TODO: use this method to communicate with text buttons
   * @return {TextFormatting}
   */
  getCursorFormatting(bufferPos: number) {
    // ERROR MESSAGE for potential debugging - replace with testing
    if (bufferPos >= this.inputs.length) {
      throw new RangeError('ERROR: TextBuffer.getCursorFormatting() called with inputPos out of bounds');
    }

    const formatting = new TextFormatting();
    const formattingStack: FormattingStack = [];
    for (let curBufferPos = 0; curBufferPos <= bufferPos; curBufferPos++) {
      const input = this.inputs[curBufferPos];
      if (input.type === TextInputType.begin_formatting || input.type === TextInputType.end_formatting) {
        (input as BeginFormattingTextInput|EndFormattingTextInput).dealWithFormatting(formatting, formattingStack);
      }
    }
    return formatting;
  }

  /**
   * Assumes overallCharPos is between 0 and the number of characters in the buffer.
   * Note that, if overallCharPos is between two TextInputs A and B, we
   * choose to return (input: index of A, inputChar: A's length) rather than
   * (input: index of B, inputChar: 0). Why? Suppose A has FontA and B has FontB.
   * Then, the user will expect text to be inserted with FontA. (See google docs)
   * @param {number} overallCharPos character position in the buffer
   * @return returns an index in the buffer and a character position in the input
   * this.inputs[index]
   */
  getBufferPos(overallCharPos: number) {

    /* ERROR MESSAGE (for potential debugging) - replace with testing */
    if (overallCharPos < 0 || overallCharPos > this.length) {
      throw new RangeError(`ERROR: TextBuffer.getBufferPos(${ overallCharPos }) index out of bounds`);
    }

    let input = 0;  // initial input position
    let remainingChars = overallCharPos;

    if (this.inputs.length === 0) {
      // CORNER CASE: buffer is empty
      remainingChars = 0;
    } else {
      while (input < this.inputs.length &&
            (this.inputs[input].length < remainingChars ||
            this.inputs[input].length === 0)) {
        remainingChars -= this.inputs[input].length;
        input++;
      }
    }

    return new BufferPosition(input, remainingChars);
  }

  /**
   * @param {BufferPosition} bufferPos the buffer position
   * @return {number} overall character position in the input corresponding to
   * the bufferPos
   */
  getOverallCharPos(bufferPos: BufferPosition) {
    // ERROR MESSAGE for potential debugging - replace with testing
    if (bufferPos.input > this.inputs.length || bufferPos.input < 0) {
      console.log('ERROR: TextBuffer.getOverallCharPos(), bufferPos.input = ', bufferPos.input, ' out of bounds');
    } else if (bufferPos.inputChar < 0 ||
              (bufferPos.input < this.inputs.length &&
                bufferPos.inputChar > this.inputs[bufferPos.input].length)) {
      console.log('ERROR: TextBuffer.getOverallCharPos(), bufferPos.inputChar = ', bufferPos.inputChar, ' out of bounds');
    }
    return this.inputs.slice(0, bufferPos.input).map(input => input.length).reduce((a, b) => a + b, 0) + bufferPos.inputChar;
  }

  /**
   * Returns bufferPos unchanged if its inputChar !== 0 or if its input === 0
   * Otherwise, returns a new bufferPos with input one less and inputChar the length of that input.
   * Equivalent to calling getBufferPos(getOverallCharPos(bufferPos)), but this is faster because
   * we don't sum the lengths twice.
   * @param {BufferPosition} bufferPos the bufferPos
   * @return {BufferPosition} the end-normalized buffer position.
   */
  endNormalizeBufferPos(bufferPos: BufferPosition) {
    if (bufferPos.input === 0 || bufferPos.inputChar !== 0) {
      return bufferPos;
    }
    let input = bufferPos.input;
    while (input > 0) {
      input--;
      if (this.inputs[input].length > 0) {
        // stop here, we've found the input
        return new BufferPosition(input, this.inputs[input].length);
      }
    }
    // if we get here, we were at the beginning.
    return new BufferPosition(0, 0);
  }

  /**
   * NOT USED YET
   * Removes any BeginFormattingTextInputs that are immediately followed by
   * EndFormattingTextInputs. This removes any formatting that is no longer
   * needed.
   */
  cleanUpBuffer() {
    let n = 0;
    while (n < this.inputs.length - 1) {
      if (this.inputs[n].type === TextInputType.begin_formatting &&
        this.inputs[n + 1].type === TextInputType.end_formatting) {
        this.inputs.splice(n, 2);
        if (n > 0) {
          n--;
        }
      } else {
        n++;
      }
    }
  }

  charAt(overallCharPos: number) {
    if (overallCharPos < 0 || overallCharPos >= this.length) {
      return '';
    }
    let bufferPos = this.getBufferPos(overallCharPos);
    if (bufferPos.inputChar === this.inputs[bufferPos.input].length) {
      // we're at the end of the item, so move to the next
      let i = bufferPos.input;
      do {
        i++;
      } while (this.inputs[i].length === 0);
      bufferPos = new BufferPosition(i + 1, 0);
    }
    return this.inputs[bufferPos.input].charAt(bufferPos.inputChar);
  }

  /**
   * Preprocess the text inputs for LaTeX.
   */
  preprocessLatex() {
    function addTextToElement(text: string) {
      if (isLatex) {
        latexBuffer.push(text);
      } else if (text.length !== 0) {
        newBuffer.push(new TextTextInput(text));
      }
    }

    function closeLatex() {
      var latexString = latexBuffer.join('');
      newBuffer.push(new LatexTextInput(latexString));
      // push all the queued formatting
      formattingBuffer.forEach(function(x) { newBuffer.push(x); });
      formattingBuffer = [];
      latexBuffer = [];
    }

    if (this.inputs.length === 0) {
      return;
    }
    var newBuffer = [];
    var latexBuffer: string[] = [];
    var formattingBuffer: TextInput[] = [];
    var isLatex = false;
    var curBufferPos = 0;
    var curCharPos = 0;
    while (curBufferPos < this.inputs.length) {
      var input = this.inputs[curBufferPos];
      switch (input.type) {
        case TextInputType.text:
          var i = (input as TextTextInput).text.indexOf('$', curCharPos);
          // TODO: escape dollar sign
          if (i !== -1) {
            // we have a latex delimiter
            var substr = (input as TextTextInput).text.substring(curCharPos, i);
            addTextToElement(substr);
            // toggle LaTeX mode
            if (isLatex) {
              // we're ending LaTeX mode
              closeLatex();
              isLatex = false;
            } else {
              isLatex = true;
            }
            if (input.length === i - 1) { // nothing else after the dollar sign
              curBufferPos++;
            } else {
              curCharPos = i + 1;
            }
          } else {
            addTextToElement((input as TextTextInput).text.substring(curCharPos));
            curBufferPos++;
            curCharPos = 0;
          }
          break;
        case TextInputType.latex:
          newBuffer.push(input);
          curBufferPos++;
          break;
        default:
          if (isLatex) {
            formattingBuffer.push(input);
          } else {
            newBuffer.push(input);
          }
          curBufferPos++;
      }
    }
    if (isLatex) {
      // Error: latex mode not properly closed.
      // close latex manually for now
      console.log('LaTeX not properly closed, closing automatically');
      closeLatex();
    }
    return new TextBuffer(newBuffer);
  }

  /**
   * @return {TextBuffer} a new buffer, the same as the current buffer but
   * with all LatexInputs replaced by TextInputs
   */
  revertFromLatexToText() {
    return new TextBuffer(this.inputs.map((input: TextInput) =>
      (input.type === TextInputType.latex) ? (input as LatexTextInput).revertToText() : input)
    );
  }

  /**
   * Return a plaintext representation of the selected part of the buffer.
   * @param {CursorPosition} cursorPos the cursor position
   */
  getSelectionText(cursorPos: CursorPosition) {
    if (!cursorPos.isSelection) {
      return '';
    }
    const beginPos = cursorPos.beginPos;
    const endPos = cursorPos.endPos;
    if (beginPos.input === endPos.input) {
      return this.inputs[beginPos.input].toString(beginPos.inputChar, endPos.inputChar);
    }
    // otherwise, we have multiple inputs:
    const beginInput = this.inputs[beginPos.input];
    const prefix = beginInput.toString(beginPos.inputChar, beginInput.length);
    const endInput = this.inputs[endPos.input];
    const suffix = endInput.toString(0, endPos.inputChar);
    return prefix + this.inputs.slice(beginPos.input + 1, endPos.input).
      map((input: TextInput) => input.toString(0, input.length)).join('') + suffix;
  }
}
