
import Shape from './Shape';
import Matrix from '../Matrix';
import Rect from '../Rect';
import Point from '../Point';
import { MinifiedQuadrilateralShape } from '../../state/shapes';
import Parallelogram from '../Parallelogram';
import Color from "../Color";
import { Canvas } from '../../Canvas';

export default class RectangleShape extends Shape {

	// the intermediate points connected to p0 and p1
	//   (see overridden method updateXY())
	private _q0: Point;
	private _q1: Point;

	constructor(size: number, color: Color, x0: number, y0: number) {
		super(size, color, x0, y0);
		this._q0 = new Point(x0, y0);
		this._q1 = new Point(x0, y0);
	}

	public updateXY(x: number, y: number) {
		super.updateXY(x, y);
		this._q0 = new Point(x, this._p0.y);
		this._q1 = new Point(this._p0.x, y);
	}

	public drawOnCanvas(canvas: Canvas, matrix: Matrix, factor: number = 1) {
		const ctx = canvas.getContext('2d');
		const points = [this._p0, this._q0, this._p1, this._q1, this._p0]
			.map(p => matrix.timesPoint(p));
		ctx.save();
		ctx.beginPath();
		ctx.strokeStyle = this._color.css;
		ctx.lineWidth = this._size * factor;
		ctx.lineCap = 'square';
		ctx.moveTo(points[0].x, points[0].y);
		for (let i = 1; i < points.length; i++) {
			ctx.lineTo(points[i].x, points[i].y);
		}
		ctx.stroke();
		ctx.restore();
	}

	public getPdfgenData(matrix: Matrix) {
		const points = [this._p0, this._q0, this._p1, this._q1]
			.map(p => matrix.timesPoint(p))
			.map(p => [p.x, p.y]);
		return {
			t: 'shape',
			st: 'rect',
			c: this._color.state,
			s: this._size,
			points,
		};
	}

	public getBoundingRect(matrix: Matrix) {
		const points = [this._p0, this._q0, this._p1, this._q1]
			.map(p => matrix.timesPoint(p));
		const xs = points.map(p => p.x);
		const ys = points.map(p => p.y);
		const left = Math.min(...xs);
		const right = Math.max(...xs);
		const top = Math.min(...ys);
		const bottom = Math.max(...ys);
		return new Rect(left, top, right - left, bottom - top);
	}

	public intersectsParallelogram(parallelogram: Parallelogram) {
		const points = [this._p0, this._q0, this._p1, this._q1]
			.map(p => parallelogram.inverseMatrix.timesPoint(p));
		for (let p of points) {
			if (parallelogram.rect.containsPointXY(p.x, p.y)) {
				return true;
			}
		}
		return false;
	}

	public minify(): MinifiedQuadrilateralShape {
		return {
			type: 'rect',
			size: this._size,
			color: this._color,
			p0: [ this._p0.x, this._p0.y ],
			p1: [ this._p1.x, this._p1.y ],
		};
	}

	public static unminify(mini: MinifiedQuadrilateralShape): RectangleShape {
		const shape = new RectangleShape(mini.size, Color.fromState(mini.color), mini.p0[0], mini.p0[1]);
		shape.updateXY(mini.p1[0], mini.p1[1]);
		return shape;
	}

}
