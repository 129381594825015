
/*
	A CanvasContext is created for each Canvas instance
*/

import SharedContext from './SharedContext'
import { Canvas } from '../Canvas'
import IdSource from '../IdSource'
import { LiveDocument } from '../document/LiveDocument'
import InteractiveScene from '../scene/InteractiveScene'
import SceneView from '../SceneView'
import { ICapabilities } from '../SceneView'
import ToolManager from './ToolManager'
import Messenger from './Messenger'
import { DocumentChangesRelay } from './DocumentChangesRelay'
import UserInterface from './UserInterface'
import LocalAgent from '../agent/local/LocalAgent'
import AgentManager from '../agent/AgentManager';
import Actions from './Actions'
import ChangeBackgroundDelta from '../deltas/ChangeBackgroundDelta'
import Document from '../document/Document'
import SaveManager from './SaveManager'
import LoadManager from './LoadManager'

export default class CanvasContext {

	public readonly actions: Actions;
	public readonly scene: InteractiveScene;
	public readonly sceneView: SceneView;
	public readonly sharedContext: SharedContext;
	public readonly toolManager: ToolManager;
	public readonly idSource: IdSource;
	public readonly document: Document
	public readonly boardUrlId: string;

	constructor(
		canvas: Canvas,
		capabilities: ICapabilities,
		ui: UserInterface,
		sharedContext: SharedContext,
		boardId: string,
	)
	{
		this.boardUrlId = boardId;
		this.sharedContext = sharedContext;
		const idSource = new IdSource();
		this.idSource = idSource;
		const document = new LiveDocument(idSource);
		this.document = document;
		this.scene = new InteractiveScene(document);
		this.actions = new Actions(this.scene, document, idSource, ui);
		const channel = sharedContext.realtimeService.subscribe(`/sw/board/${boardId}`, "TODO_channelToken");
		const messenger = new Messenger(channel);
		const localAgent = new LocalAgent(idSource.deviceId, messenger);
		const agentManager = new AgentManager(this.scene, document, messenger);
		this.sceneView = new SceneView(this.scene, canvas, capabilities, localAgent, ui);
		this.toolManager = new ToolManager(document, this.sceneView, ui, localAgent);
		const documentChangesRelay = new DocumentChangesRelay(document, localAgent, this.scene);
		const saveManager = new SaveManager(messenger, sharedContext.analytics, sharedContext.serverInterface, boardId, this.scene);
		const loadManager = new LoadManager(boardId, sharedContext.serverInterface, this.scene, messenger);
		document.subscribe(documentChangesRelay);
		document.subscribe(saveManager);
	}

	// called when a user uploaded a pdf file through the dialog
	public async onPdfFiles(files: File[]) {
		const { pdfUrlId } = await this.sharedContext.serverInterface.uploadPdfFile(files[0], this.boardUrlId);
		const pdfDoc = await this.sharedContext.pdfFileManager.createBackgroundDocument(pdfUrlId, this.boardUrlId, this.idSource);
		const mini = pdfDoc.state;
		const background = this.scene.getBackground();
		const from = {...background.state};
		const pos = background.getPositionForNewDocument();
		mini.rect = [pos[0], pos[1], 0, 0];
		const to = {
			...from,
			documents: [
				...from.documents,
				mini,
			]
		}
		var delta = new ChangeBackgroundDelta(this.idSource.newId(), from, to);
		this.document.addDelta(delta);
	}

}