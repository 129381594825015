
import Delta from './Delta'
import MutableScene from '../scene/MutableScene'
import DeleteItemDelta from './DeleteItemDelta'
import Item from '../items/Item'
import { AddItemDeltaState } from '../state/deltas'
import { ItemState } from '../state/items'
import Item_fromState from '../items/factory'
import SimpleDocument from '../document/SimpleDocument'
import SimpleScene from '../scene/SimpleScene'

export default class AddItemDelta extends Delta {

  public item: Item | null = null;

  // store the item to avoid unnecessarily serializing and deserializing on originating device
  constructor(actId: string,
              private itemState: ItemState,
              )
  {
    super('add', actId);
  }

  public get state(): AddItemDeltaState {
    return {
      t: 'add',
      a: this.actId,
      i: this.itemState,
    };
  }

  public static fromState(state: AddItemDeltaState) {
    return new AddItemDelta(state.a, state.i);
  }

  public populate(scene: SimpleScene, document: SimpleDocument) {
    if (this.item === null) {
      this.item = Item_fromState(this.itemState, document);
    }
  }

  public inverse(actId: string) {
    let delta = new DeleteItemDelta(actId, this.itemState);
    delta.item = this.item;
    return delta;
  }

  public applyToScene(scene: MutableScene) {
    if (this.item !== null) {
      scene.addItem(this.item);
    } else {
      throw Error('AddItemDelta: item is null');
    }
  }

}