
import MutableDocument from './MutableDocument'

import Delta from '../deltas/Delta'
import Resource from '../resources/Resource'
import IdSource from '../IdSource';

export interface ILiveDocumentSubscriber {
  beginChanges(): void;
  endChanges(): void;
  onDelta(delta: Delta): void,
  onResource? (resource: Resource): void,
}

/*
  Example subscribers include
  + scene
  + recorder
  + messaging layer (to forward local deltas to other devices)
*/

export class LiveDocument extends MutableDocument {

  private subscribers: Array<ILiveDocumentSubscriber> = [];

  constructor(public readonly idSource: IdSource) {
    super();
  }

  // call beginChanges() and endChanges() to group multiple changes together
  public beginChanges() {
    for (let subscriber of this.subscribers) {
      subscriber.beginChanges();
    }
  }

  public endChanges() {
    for (let subscriber of this.subscribers) {
      subscriber.endChanges();
    }
  }

  // handles sequencing, changing state, reporting downstrem (including updating the scene)
  public addDelta(delta: Delta): void {
    // TODO handle sequencing
    this.change(delta);
    // update the scene (and any other subscribers)
    for (let subscriber of this.subscribers) {
      subscriber.onDelta(delta);
    }
  }

  public addResource(resource: Resource) {
    this.resources.set(resource.id, resource);
    for (let subscriber of this.subscribers) {
      if (subscriber.onResource) {
        subscriber.onResource(resource);
      }
    }
  }

  // subscribers should not care which order they receive events
  public subscribe(subscriber: ILiveDocumentSubscriber) {
    this.subscribers.push(subscriber);
  }

}