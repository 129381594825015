
// note: I am preserving comments as they are written by someone who is no longer working on the project.
//       They may not reflect changes I have made.

// TODO handle sourceItem!==null (uncomment code in constructor referring to TextItem and EquationItem)
// TODO find messenger.broadcastDel() lines, uncomment and replace with appropriate code

////////////////////////////////////////////////////////////////////////////////
//  PreTextItemT methods:                                                     //
//      + drawOnCanvas(canvas, left, top, zoom)                               //
//      + onKeyDown(event)                                                    //
//      + onClickedAway()                                                     //
//      + release()                                                           //
//      + returnItemToScene()                                                 //
//      + getItemState()                                                      //
//      + convertToTextItem()                                                 //
//      + erase()                                                             //
//      + getTextRect()                                                       //
//      + acceptsClick(x, y, zoom)                                            //
//      + onDown(x, y, zoom, event)                                           //
//      + onUp()                                                              //
//      + onDrag(x, y, zoom)                                                  //
//      + acceptsHover(x, y, zoom)                                            //
//      + getCursor(x, y, zoom)                                               //
//      + updateMatrixControlHeight()                                         //
//      + insertFormatting(format)                                            //
////////////////////////////////////////////////////////////////////////////////

// TEXT_PADDING is also needed by TextItem, who is given the baseline
// (which should be fixed between converting text to latex svg) and needs to
// calculate the top of the textbox
export const TEXT_PADDING = 5;

import { HoverResponder, ClickResponder, KeyResponder } from "../scene/InteractiveScene"
import ItemT from './ItemT';
import Item from '../items/Item';
import MatrixControl from './MatrixControl';
import MutableText from '../text/MutableText';
import MutableTextController from '../text/MutableTextController';
import Point from '../geometry/Point';
import Rect from '../geometry/Rect';
import CursorPosition from '../text/CursorPosition';
import BufferPosition from '../text/BufferPosition';
import Color from '../geometry/Color';
import Viewport from '../geometry/Viewport';
import { Canvas, KeyEvent } from '../Canvas';
import { SvgCache } from "../text/_svg";
import Scene from "../scene/Scene";
import { FormattingObject } from "../text/TextFormatting";
import ToolManager from "../context/ToolManager";
import ReleaseItemsDelta from "../deltas/ReleaseItemsDelta";
import DeleteItemDelta from "../deltas/DeleteItemDelta";
import AddItemDelta from "../deltas/AddItemDelta";
import Matrix from "../geometry/Matrix";
import Document from "../document/Document";
import { TextItemState } from "../state/items";
import Item_fromState from "../items/factory";

const DEBUG_MODE = false;
const DEFAULT_INITIAL_WIDTH = Number.MAX_SAFE_INTEGER / 2;


type ReleaseInfo = {
  // If we are editing an existing text item, we want to have the release deltas use the same actId.
  // This way, a single undo reverts all the changes made (without reselecting the item).
  actId: string,
  sourceItem: Item,
}

/**
 * Constructs a new PreTextItemT whose textbox has top-left corner at
 * (x, y).
 * @param {!number} x x-coordinate of top-left corner of textbox
 * @param {!number} y y-coordinate of top-left corner of textbox
 * @param {?Item} sourceItem the TextItem or EquationItem from which this ItemT was created. Is null for a new item.
 * @param {?number} width the width of the box. Null makes the width extremely large.
 * @param {?BufferPosition} bufferPos the buffer position at which to place the cursor. If none provided
 * the cursor is placed at the beginning of the text.
 * @param {?number} actId the actId to use when releasing this PreTextItemT.
 * Only has an effect if textItem is not null.
 */
export default class PreTextItemT extends ItemT implements HoverResponder, ClickResponder, KeyResponder {

  // these get read when the itemT is added to the scene
  public respondsToHoverEvents: boolean = true;
  public respondsToClickEvents: boolean = true;
  public respondsToKeyEvents: boolean = true;

  // TODO, set this to true only if parseLatex button clicked
  // if true, latex will be parsed on call to onClickedAway()
  private parseLatex: boolean = true;
  private hasPresetWidth: boolean;

  private topLeft: Point;
  private topRight: Point;
  private boxHeight: number;

  // these are non-null when a rectangle is visible around the text (whenever sourceItem is not null)
  private initialRect: Rect | null = null;
  private matrixControl: MatrixControl | null = null;

  public mutableText: MutableText;
  private mutableTextController: MutableTextController;

  
  private _releaseInfo: ReleaseInfo | null = null;

  // is there a text selection in progress?
  private textSelectionInProgress: boolean = false;
  // is there a matrix manipulation in progress?
  private matrixManipulationInProgress: boolean = false;

  constructor(devId: string, x: number, y: number, releaseInfo: ReleaseInfo|null, width: number|null, bufferPos: BufferPosition|null, scene: Scene, private _svgCache: SvgCache, private _toolManager: ToolManager, private document: Document) {
    super(devId);

    this._releaseInfo = releaseInfo;

    // the textbox
    // the user controls the width and the position of the textbox
    // the point where the user clicks initially is the top left corner of the
    // textbox
    // TODO: find optimal settings for initial text box height and width
    // TODO: make non-wrapping a thing (it currently doesn't work)
    // FIXME: when rotation is implemented, PreTextItemT will need top left and
    // top right points as parameters
    this.hasPresetWidth = !!width;
    const boxWidth = width || DEFAULT_INITIAL_WIDTH;
    this.topLeft = new Point(x, y);
    this.topRight = new Point(x + boxWidth, y);

    var textWidth = boxWidth - 2 * TEXT_PADDING;
    if (releaseInfo) {
      let buffer = null;
      /*
      // TODO handle reading buffer from TextItem and EquationItem
      if (sourceItem instanceof TextItem) {
        buffer = (releaseInfo.sourceItem as TextItem).buffer.revertFromLatexToText();
      } else if (sourceItem instanceof EquationItem) {
        buffer = (releaseInfo.sourceItem as EquationItem).getTextBuffer();
      }
      */
      this.mutableText = new MutableText(scene, textWidth, buffer, this._svgCache);
      if (bufferPos) {
        // directly modify the cursor position
        // this works because revertFromLatexToText() preserves the number of inputs.
        this.mutableText.cursorPos = new CursorPosition(bufferPos, bufferPos);
      }
    } else {
      this.mutableText = new MutableText(scene, textWidth, null, this._svgCache);
    }
    this.mutableTextController = new MutableTextController(this.mutableText, scene);
    // set up the handler for broadcasting dels when we change text
    this.mutableText.changeListener = () => {
      if (this.scene) {
        this.scene.redisplay();
      }
      /*
      messenger.broadcastDel({
        type: 'textChange',
        N: delManager.currentDelN,
        buffer: this.mutableText.buffer.minify(),
      });
      */
    };
    this.boxHeight = this.mutableText.height + 2 * TEXT_PADDING;

    // We show the matrix control if and only if there's an existing text item.
    if (releaseInfo) {
      // matrix control will use the initial rect to calculate the box
      this._setInitialRect(new Rect(this.topLeft.x, this.topLeft.y, boxWidth, this.boxHeight));
    }
  }

  private _setInitialRect(rect: Rect) {
    this.initialRect = rect;
    this.matrixControl = new MatrixControl(
      this.initialRect,
      Color.fromCss('#0066cc'), // color blue
      true,
      // the center button is not used: click-and-drag inside the text area selects text
      [false, true, true, true, true, true, true, true, true]
    );
  }

  /**
   * Draws the text, flashing cursor and the matrix control onto the canvas.
   *
   * @param canvas the canvas
   * @param left the x-coordinate of the left border of the canvas
   * @param top the y-coordinate of the top border of the canvas
   * @param zoom the canvas's zoom
   */
  public drawOnCanvas(canvas: Canvas, viewport: Viewport) {
    // draw the text and flashing cursor
    var ctx = canvas.getContext('2d');
    ctx.save();
    ctx.scale(viewport.zoom, viewport.zoom);
    ctx.translate(-viewport.left + this.topLeft.x + TEXT_PADDING,
      -viewport.top + this.topLeft.y + TEXT_PADDING);
    this.mutableText.drawOnContext(ctx);
    ctx.restore();

    // draw the MatrixControl
    if (this.matrixControl) {
      this.matrixControl.drawOnCanvas(canvas, viewport);
    }

    if (DEBUG_MODE) {
      // draw the text area outline as a black rectangle
      var ctx = canvas.getContext('2d');
      ctx.save();
      ctx.scale(viewport.zoom, viewport.zoom);
      ctx.translate(-viewport.left, -viewport.top);
      var rect = this.getTextRect();
      ctx.beginPath();
      ctx.rect(rect.left, rect.top, rect.width, rect.height);
      ctx.stroke();
      ctx.restore();
    }
  }

  /**
   * Responds to typed characters and to Backspace, ArrowLeft and ArrowRight
   * @param event the keyboard event
   */
  public onKeyDown(event: KeyEvent) {
    var isKeyAbsorbed = this.mutableTextController.onKeyDown(event);
    if (this.matrixControl && !this.matrixManipulationInProgress) {
      // if this key event creates a new line so that the text now spills out
      // the bottom of the textbox, then lower the bottom textbox boundary
      // but only if we're not dragging the matrix.
      // If we do type-and-drag, ideally we'd schedule an update when the drag is finished,
      // but for now, we can wait until the next time a key is pressed.
      this.updateMatrixControlHeight();
    }
    if (this.scene) {
      this.scene.redisplay();
    }
    return isKeyAbsorbed;
  };

  /**
   * Responds to ctrl+x, ctrl+c, ctrl+v events
   * @param event the clipboard event
   */
  /*
  public onClipboardEvent(event) {
    switch (event.type) {
      case 'cut':
        var textData = this.mutableTextController.cut();
        if (textData !== null) {
          event.clipboardData.setData('text/plain', textData);
          // TODO support other data types
          event.clipboardData.clearData('text/html');
          return true;
        }
        break;
      case 'copy':
        var textData = this.mutableTextController.copy();
        if (textData !== null) {
          event.clipboardData.setData('text/plain', textData);
          // TODO support other data types
          event.clipboardData.clearData('text/html');
          return true;
        }
        break;
      case 'paste':
        var textData = event.clipboardData.getData('text/plain');
        if (textData) {
          this.mutableTextController.paste(textData);
          return true;
        }
        break;
    }
    return false;
  };
  */

  public onClickedAway() {
    this._release();
    this._toolManager.onPreTextItemTClickedAway();
  };

  private _release() {
    // if it's a new TextItem, broadcast a del to let other devices know we're done typing
    // (if an existing TextItem is being edited, ReleaseItemsDelta applies)
    /*
    if (!this.sourceItem) {
      messenger.broadcastDel({
        type: 'textRelease',
        N: delManager.currentDelN,
      });
    }
    */
    this.mutableText.turnOffCursor();
    if (this.scene) {
      this.scene.beginChanges();
    }
    if (this._releaseInfo) {
      // then the old TextItem or EquationItem was grabbed and needs to be released
      throw new Error("commented branch");
      /*
      var releaseDelta = new ReleaseItemsDelta(
        this._releaseInfo.actId,
        devicesManager.getMyDeviceId(),
        devicesManager.getMyDeviceId(),
        [{ id: this.sourceItem.id, devId: this.sourceItem.devId }],
        Matrix.identityMatrix(),
        GrabItemsDelta.intents.PreTextItemT
      );
      releaseDelta.applyToScene();
      boardStateManager.addDelta(releaseDelta);
      devicesManager.enqueueDelta(releaseDelta);
      if (this.mutableText.isEmpty()) {
        this._erase();
      }
      */
    } else {
      if (!this.mutableText.isEmpty()) {
        this._convertToTextItem();
      }
    }
    if (this.scene) {
      const scene = this.scene;
      scene.removeForefrontItem(this); // causes this.scene to be null (potential bug for next line)
      scene.endChanges();
    }
  };
  
  /**
   * Called by ReleaseItemsDelta.applyToScene().
   * Note: this does not save any changes.
   * A future ChangeItemDelta must be used to actually change the item.
   */
  /*
  public returnItemToScene() {
    if (!this.sourceItem) {
      console.log('Unexpected: returnItemToScene called on non-edited PreTextItemT');
      return;
    }
    scene.addSceneItem(this.sourceItem);
  };
  */

  private _getItemState() {
    // TODO: do buffer cleanup before making the itemState
    if (this.parseLatex) {
      // TODO preprocessLatex
      console.warn("skipping preprocessLatex()");
      //this.mutableText.preprocessLatex();
    }
    // TODO: fix this when rotation is implemented
    var dy = TEXT_PADDING + this.mutableText.lines.lines[0].ascent;
    var baselineLeft = this.topLeft.translate(TEXT_PADDING, dy);
    var baselineRight;
    if (this.hasPresetWidth) {
      baselineRight = this.topRight.translate(-TEXT_PADDING, dy);
    } else {
      baselineRight = baselineLeft.translate(this.mutableText.lines.width, 0);
    }
    if (this.mutableText.isLatex()) {
      // If the buffer only contains a latex input then contruct an EquationItem
      throw new Error("commented branch");
      // TODO: what should scale be? For now, I've set it to 1
      /*
      var matrix = new Matrix(1, 0, 0, 1, baselineLeft.x, baselineLeft.y);
      let latex = this.mutableText.getLatex();
      let width = -1;
      let height = -1;
      let svg = this._svgCache.getSvgOrNull(latex);
      if (svg) {
        width = svg.width;
        height = svg.height;
      }
      return new EquationItemState(
        this._releaseInfo.sourceItem.id,
        this._releaseInfo.sourceItem.devId,
        matrix,
        latex,
        width,
        height
      );
      */
    } else {
      const state: TextItemState = {
        t: 'text',
        id: this.document.idSource.newId(),
        m: Matrix.identityMatrix().state,
        b: this.mutableText.buffer.state,
        bll: baselineLeft.toArray(),
        blr: baselineRight.toArray(),
      };
      return state;
    }
  };

  private _convertToTextItem() {
    var itemState = this._getItemState();
    // create the delta
    var delta;
    if (!this._releaseInfo) {
      delta = new AddItemDelta(
        this.document.idSource.newId(),
        itemState
      );
      delta.item = Item_fromState(itemState, this.document);
    } else {
      throw new Error("commented branch");
      /*
      delta = new ChangeItemDelta(
        this._releaseInfo.actId,
        this._releaseInfo.sourceItem.state,
        itemState
      );
      */
    }
    this.document.addDelta(delta);
  };

  private _erase() {
    if (this._releaseInfo) {
      const delta = new DeleteItemDelta(
        this._releaseInfo.actId,
        this._releaseInfo.sourceItem.state
      );
      this.document.addDelta(delta);
    }
  };

  /**
   * returns rect in scene coordinates
   * TODO: fix to work with rotation
   */
  public getTextRect() {
    var top = this.topLeft.y + TEXT_PADDING;
    var left = this.topLeft.x + TEXT_PADDING;
    var width = this.topRight.x - this.topLeft.x - 2 * TEXT_PADDING;
    var height = this.mutableText.height;
    return new Rect(left, top, width, height);
  };

  /**
   * The current PreTextItemT accepts clicks at (x, y) if (x, y) is inside the
   * textbox or if there is a matrix control that will accept the click.
   * @param {Number} x the x-coordinate clicked (in Scene coordinates)
   * @param {Number} y the y-coordinate clicked (in Scene coordinates)
   * @return {Boolean} true if the click is accepted, false otherwise.
   */
  public acceptsClick(x: number, y: number, viewport: Viewport) {
    return this.getTextRect().containsPointXY(x, y) || (
      !!this.matrixControl && this.matrixControl.acceptsClick(x, y, viewport.zoom)
    );
  };

  /**
   * onDown(x, y, zoom, event) is called when the user clicks inside the
   * current textbox. The cursor is moved to the position closest to the click.
   * @param {Number} x the x-coordinate clicked (in Scene coordinates)
   * @param {Number} y the y-coordinate clicked (in Scene coordinates)
   */
  public onDown(x: number, y: number, viewport: Viewport, event: MouseEvent) {
    if (this.matrixControl && this.matrixControl.acceptsClick(x, y, viewport.zoom)) {
      // this is a matrix control
      this.matrixManipulationInProgress = true;
      this.matrixControl.onDown(x, y, viewport.zoom);
      if (this.scene) {
        this.scene.redisplay();
      }
    } else if (this.getTextRect().containsPointXY(x, y)) {
      // transform (x, y) to mutableText coordinates (xText, yText)
      this.textSelectionInProgress = true;
      const xText = x - this.topLeft.x - TEXT_PADDING;
      const yText = y - this.topLeft.y - TEXT_PADDING;
      const makeSelection = event.shiftKey;
      this.mutableTextController.moveToXY(xText, yText, makeSelection);
    }
  };

  /**
   * Called when the mouse is released.
   */
  public onUp() {
    if (this.matrixManipulationInProgress) {
      if (this.matrixControl) {
        this.matrixControl.onUp();
      }
    }
    // text selection has been finished
    this.textSelectionInProgress = false;
    // so has matrix manipulation
    this.matrixManipulationInProgress = false;
  };

  public onDrag(x: number, y: number, viewport: Viewport) {
    if (this.matrixManipulationInProgress) {
      if (this.matrixControl) {
        this.matrixControl.onDrag(x, y, viewport.zoom);
        if (this.initialRect) {
          const rect = this.initialRect.boundsAfterMatrix(this.matrixControl.totalMatrix);
          // FIXME: when rotation is implemented
          this.topLeft = new Point(rect.left, rect.top);
          this.topRight = new Point(rect.left + rect.width, rect.top);
          this.boxHeight = rect.height;
          this.mutableText.width = rect.width - 2 * TEXT_PADDING;
          /*
          messenger.broadcastDel({
            type: 'textDrag',
            N: delManager.currentDelN,
            x: this.topLeft.x,
            y: this.topLeft.y,
            width: this.mutableText.width,
          });
          */
          if (this.scene) {
            this.scene.redisplay();
          }
        }
      }
    } else if (this.textSelectionInProgress && this.getTextRect().containsPointXY(x, y)) {
      const xText = x - this.topLeft.x - TEXT_PADDING;
      const yText = y - this.topLeft.y - TEXT_PADDING;
      this.mutableTextController.moveToXY(xText, yText, true);
      if (this.scene) {
        this.scene.redisplay();
      }
    }
  };

  public acceptsHover(x: number, y: number, viewport: Viewport) {
    return this.getTextRect().containsPointXY(x, y) ||
      (!!this.matrixControl && this.matrixControl.acceptsHover(x, y, viewport.zoom));
  }

  /**
   * TODO: what happens if the textbox does not contain the text area?
   * In this case, the text cursor will show when you hover over the south
   * matrix control button.
   * To fix this, could calculate a rectangle M = "matrix-control-plus-epsilon"
   * and a rectangle m = "matrix-control-minus-epsilon" and whenever (x, y) is in
   * M but not in m, we let matrixControl decide the cursor
   */
  public getCursor(x: number, y: number, viewport: Viewport) {
    if (this.getTextRect().containsPointXY(x, y)) {
      return 'text';
    } else if (this.matrixControl) {
      return this.matrixControl.getCursor(x, y, viewport.zoom);
    } else {
      return "default";
    }
  }

  /**
   * Used to update matrix control height when the text is higher
   */
  public updateMatrixControlHeight() {
    // This function should only be called if this.matrixControl is defined.
    // If not, gracefully handle it.
    if (!this.matrixControl) {
      console.log('WARNING: updateMatrixControlHeight called for PreTextItemT, but matrixControl is undefined');
      return;
    }
    var textHeight = this.mutableText.height + 2 * TEXT_PADDING;
    if (textHeight > this.boxHeight) {
      // initialize a new initialRect
      if (this.initialRect) {
        this._setInitialRect(this.initialRect.boundsAfterMatrix(this.matrixControl.totalMatrix));
      }
    }
  }

  /**
   * @param format a property, value pair, e.g. { property: 'bold', value: null }
   * Used by TextTool
   */
  public insertFormatting(format: FormattingObject) {
    var rawInput = { type: 'formatting' as 'formatting', formatting: format };
    this.mutableText.insert(rawInput);
  };

}
