////////////////////////////////////////////////////////////////////////////////
// TEXT INPUTS                                                                //
// Methods (all TextInputs need these methods)                                //
// + length()  (default TextInput is one character long)                      //
// + minify()                                                               //
// + unminify()                                                             //
////////////////////////////////////////////////////////////////////////////////

import { TextInputState } from "../../state/text";

export enum TextInputType {
  begin_formatting = "Fbeg",
  end_formatting = "Fend",
  latex = "Latex",
  new_line = "NL",
  text = "Text",
}

export default abstract class TextInput {

  constructor(public readonly type: TextInputType)
  {}

  public abstract get state(): TextInputState;

  abstract get length(): number; // difference between cursor position before and after

  public abstract charAt(charPos: number): string;

  // return a string representation of characters from beginCharPos (inclusive) to endcharPos (exclusive)
  // (to be printed when user is editing)
  // may return ""
  abstract toString(beginCharPos: number, endCharPos: number): string;

}