////////////////////////////////////////////////////////////////////////////////
//  TextLines public methods:                                                 //
//      + TextLines(buffer, boxWidth)                                         //
//      + drawOnContext(ctx)                                                  //
//                                                                            //
//  TextBuffer private methods:                                               //
//      +                                                                     //
////////////////////////////////////////////////////////////////////////////////

import LatexTextOutput from './output/LatexTextOutput';
import HyphenBreakTextOutput from './output/HyphenBreakTextOutput';
import TextTextOutput from './output/TextTextOutput';
import TextOutput from "./output/TextOutput";
import TextFormatting from './TextFormatting';
import TextInput, { TextInputType } from './input/TextInput';
import Matrix from '../geometry/Matrix';
import Rect from '../geometry/Rect';
import BufferPosition from './BufferPosition';
import CursorPosition from './CursorPosition';
import TextBuffer from "./TextBuffer";
import TextTextInput from './input/TextTextInput';
import LatexTextInput from './input/LatexTextInput';
import BeginFormattingTextInput, { FormattingStack } from './input/BeginFormattingTextInput';
import EndFormattingTextInput from './input/EndFormattingTextInput';
import { SvgCache } from './_svg';
import Scene from '../scene/Scene';

const LINE_SPACING = 0.5;

type Line = { buffer: TextOutput[], ascent: number, descent: number };
type Lines = Line[];

/**
 * @param {TextBuffer} buffer
 * @param {number} maxWidth distance from left to right margin
 */
export default class TextLines {

  private _maxWidth: number;
  private buffer: TextBuffer;
  public lines: Lines;

  private _scene: Scene | null = null; // TODO have TextItem set scene

  constructor(buffer: TextBuffer, maxWidth: number, private _svgCache: SvgCache, scene: Scene|null) {
    if (isNaN(maxWidth)) {
      throw new TypeError('TextLines: maxWidth must not be NaN');
    }
    this._scene = scene;
    this._maxWidth = maxWidth;
    // TextLines needs a copy of the buffer because it is used by
    // LatexTextOutputs when the svg loads
    this.buffer = buffer;
    // lines is an array such that each lines[i] has properties buffer, ascent and descent
    this.lines = [];
    this.layout();
  }

  get maxWidth() {
    return this._maxWidth;
  }

  /**
   * Calculate offsets for each TextOutput and do the given function on each.
   * @param f the function (taking output, x, y, and lineAscent)
   */
  private doPositionalFunction(f: (output: TextOutput, x: number, y: number, lineAscent: number) => void) {
    let y = 0; // top margin
    // compute the ith line
    this.lines.forEach((line) => {
      let x = 0;
      const lineAscent = line.ascent;
      line.buffer.forEach((output) => {
        f(output, x, y, lineAscent);
        x += output.width;
      });
      y += lineAscent + line.descent + LINE_SPACING;
    });
  }

  drawOnContext(ctx: CanvasRenderingContext2D) {
    this.doPositionalFunction((output, x, y, lineAscent) => {
      output.drawOnCanvas(ctx, x, y, lineAscent);
    });
    // DEBUG CODE to draw the rectangles
    /*
    ctx.save();
    ctx.globalAlpha = 0.4;
    ctx.fillStyle = 'rgb(255, 0, 0)';
    this.getAllSelectionRects().forEach(rect => { ctx.fillRect(rect.left, rect.top, rect.width, rect.height); });
    ctx.restore();
    */
  }

  /*
  addSvgData(svg, svgMatrix) {
    this.doPositionalFunction((output, x, y, lineAscent) => {
      const matrix = svgMatrix.times(Matrix.translateMatrix(x, y));
      output.addSvgData(svg, matrix);
    });
  }
  */

  /*
  getPdfgenData(matrix) {
    var pdfOutputs = [];
    // Note: this relies on the fact that all calls made by doPositionalFunction happen before it returns.
    this.doPositionalFunction((output, x, y, lineAscent) => {
      var pdfOutput = output.getPdfgenData(matrix, x, y, lineAscent);
      if (pdfOutput !== null) {
        pdfOutputs.push(pdfOutput);
      }
    });
    return pdfOutputs;
  }
  */

  /**
   * @param bufferPos position in the input buffer
   * @return an object with properties:
   *   line: the line containing the cursor
   *   ascent: the ascent of the output containing the cursor
   *   descent: the descent of the output containing the cursor
   *   x: x-coordinate of the cursor (horizontal distance between cursor and left margin)
   *   y: y-coordinate of the baseline of the output containing the cursor
   *
   *   TODO: implement when point1.y !== point2.y
   */
  getCursor(bufferPos: BufferPosition, cursorLine?: number) {
    const line = (cursorLine !== undefined ? cursorLine : this.getLineContainingBufferPos(bufferPos));
    const cursorOutput = this.getOutputGivenBufferPos(line, bufferPos);
    const cursor = {
      line: line,
      ascent: cursorOutput.ascent,
      descent: cursorOutput.descent,
      x: this.getHorizontalDistInLine(line, bufferPos),
      y: this.getYForLineIndex(line),
    };
    return cursor;
  }

  /**
   * Return the y coordinate for the line with the given index
   * @param {Integer} line the index of the line
   * @return {Number} the y-coordinate (in lines coordinates)
   */
  getYForLineIndex(line: number) {
    return this.lines.slice(0, line).map(l => l.ascent + l.descent + LINE_SPACING, this)
      .reduce((previous, current) => previous + current, 0) + this.lines[line].ascent;
  }

  /**
   * Find the maximum number of characters from the beginning of text that, when rendered in the given
   * context, will fit in the given width.
   * @param {Canvas} ctx the canvas with the current formatting
   * @param {String} text the string
   * @param {Float} width the width
   * @return {Integer} the maximum number of characters that will fit in the given width
   */
  static breakText(ctx: CanvasRenderingContext2D, text: string, width: number) {
    // binary search for index, the greatest index i such that
    // text.substring(0, i).width <= width
    var index = 0;
    var lo = 0;
    var hi = text.length;
    while (hi >= lo) {
      var mid = lo + Math.floor((hi - lo) / 2);
      var w = ctx.measureText(text.substring(0, mid)).width;
      if (TextLines.hasEnoughRemainingWidth(width, w)) {
        index = mid;
        lo = mid + 1;
      } else {
        hi = mid - 1;
      }
    }
    return index;
  }

  /**
   * Layout the lines.
   */
  layout() {
    var this_ = this;
    function moveToNewLine() {
      // edge case where lineBuffer is empty
      if (lineBuffer.length === 0) {
        pushNewOutput(new TextTextOutput('', formatting, bufferPos));
      }
      this_.lines.push({
        buffer: lineBuffer,
        ascent: lineAscent,
        descent: lineDescent,
      });
      lineBuffer = [];
      lineAscent = 0;
      lineDescent = 0;
      remainingWidth = this_._maxWidth;
    }

    function pushNewOutput(output: TextOutput) {
      lineBuffer.push(output);
      remainingWidth -= output.width;
      lineAscent = Math.max(lineAscent, output.ascent);
      lineDescent = Math.max(lineDescent, output.descent);
    }

    this.lines = [];
    // default formatting
    var formatting = new TextFormatting();

    if (this.buffer.length === 0) {
      // Used by PreTextItemT; no real TextItems will be created with empty buffer
      let output =
        new TextTextOutput('', formatting, new BufferPosition(0, 0));
      this.lines.push({
        buffer: [output],
        ascent: output.ascent,
        descent: output.descent,
      });
      return;
    }

    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    ctx!.font = formatting.fontString;

    // keeps track of the previous formatting
    var formattingStack: FormattingStack = [];

    // current position in the calculation
    var bufferPos = new BufferPosition(0, 0);

    var lineBuffer: TextOutput[] = [];
    var lineAscent = 0;
    var lineDescent = 0;
    var remainingWidth = this._maxWidth;

    for (var input of this.buffer.inputs) {
      switch (input.type) {
        case TextInputType.text:
          var textCharPos = 0;
          while (textCharPos < input.length) {
            var lineEnd = false;
            var hyphenBreak = false;
            // the text in the current buffer input that remains to be
            // processed into lines
            var text = (input as TextTextInput).text.substring(textCharPos);
            // compute the largest substring of text that fits on this line
            if (TextLines.hasEnoughRemainingWidth(remainingWidth, ctx!.measureText(text).width)) {
              textCharPos = input.length;
            } else {
              var index = TextLines.breakText(ctx!, text, remainingWidth);

              // don't want to wrap in the middle of a word
              var wrapIndex = text.lastIndexOf(' ', index);
              // if there's no space in the word, treat as 0 (for further calculations)
              if (wrapIndex === -1) {
                wrapIndex = 0;
              }

              // CORNER CASES:
              // if a word is longer than width, split the word over multiple lines
              // if the line width has been set too small, put one char per line
              if (remainingWidth === this._maxWidth && wrapIndex === 0) {
                wrapIndex = Math.max(index - 1, 1);
                text = text.substring(0, wrapIndex);
                hyphenBreak = true;
              } else if (wrapIndex !== 0) {
                // TODO: check for French spacing
                wrapIndex++; // space included in this line
                text = text.substring(0, wrapIndex);
              } else {
                // then wrapIndex === 0 and we don't want any of this input on the
                // current line
                text = '';
              }
              textCharPos += wrapIndex;
              lineEnd = true; // call moveToNewLine after we finish
            }
            let output = new TextTextOutput(text, formatting, bufferPos);
            pushNewOutput(output);
            bufferPos = new BufferPosition(bufferPos.input, textCharPos);
            if (hyphenBreak) {
              pushNewOutput(new HyphenBreakTextOutput(formatting, bufferPos));
              hyphenBreak = false;
            }
            if (lineEnd) {
              moveToNewLine();
            }
          }
          bufferPos = bufferPos.moveToStartOfNextInput();
          break;
        case TextInputType.latex:
          const onRelayout = () => {
            if (this._scene) {
              this._scene.redisplay();
            } else {
              throw new Error("There is no scene. That could be ok, but this is a reminder to make sure TextItem adds the scene to TextLines when it gets scene.");
            }
          }
          let output = new LatexTextOutput((input as LatexTextInput).latex, bufferPos, this, this._svgCache, onRelayout);
          if (!TextLines.hasEnoughRemainingWidth(remainingWidth, output.width)) {
            moveToNewLine();
          }
          pushNewOutput(output);
          if (!TextLines.hasEnoughRemainingWidth(remainingWidth, 0)) { // an overly wide LaTeX may exceed its bounding box
            moveToNewLine();
          }
          bufferPos = bufferPos.moveToStartOfNextInput();
          break;
        case TextInputType.begin_formatting:
          (input as BeginFormattingTextInput).dealWithFormatting(formatting, formattingStack);
          ctx!.font = formatting.fontString;
          bufferPos = bufferPos.moveToStartOfNextInput();
          break;
        case TextInputType.end_formatting:
          (input as EndFormattingTextInput).dealWithFormatting(formatting, formattingStack);
          ctx!.font = formatting.fontString;
          bufferPos = bufferPos.moveToStartOfNextInput();
          break;
        case TextInputType.new_line:
          // TODO this is pretty hacky: clean this shit up
          moveToNewLine();
          bufferPos = new BufferPosition(bufferPos.input, 1); // point to end of new line input
          pushNewOutput(new TextTextOutput('', formatting, bufferPos));
          bufferPos = bufferPos.moveToStartOfNextInput();
          break;
      }
      // TODO box input case
    }
    // finalize the last line, if it is nonempty:
    if (lineBuffer.length > 0) {
      moveToNewLine();
    }
  }

  get height() {
    return this.lines.map((line) => LINE_SPACING + line.ascent + line.descent, this)
      .reduce((previous: number, current: number) => previous + current, 0) - LINE_SPACING;
  }

  get width() {
    return this.lines.map((line) =>
      this.getHorizontalLengthOfLine(line))
      .reduce((previous: number, current: number) => Math.max(previous, current), 0);
  }

  /**
   * Finds the buffer position closest to the point (x, y)
   * ('lines coordinates' have scene coordinates' zoom, but have the origin at
   * the point where the top and left margins intersect)
   * @param {number} x x-coordinate in lines coordinates
   * @return new cursor line and new buffer position
   */
  placeCursor(x: number, y: number) {
    const newCursorLine = this.getLineContainingYCoordinate(y);
    return {
      line: newCursorLine,
      bufferPos: this.getPositionInLine(newCursorLine, x),
    };
  }

  /**
   * ('lines coordinates' have scene coordinates' zoom, but have the origin at
   * the point where the top and left margins intersect)
   * @param {number} y y-coordinate in lines coordinates
   * @return the index of the line containing points with y-coordinate y
   */
  getLineContainingYCoordinate(y: number) {
    // yFirstLine is the largest possible y-coordinate of a point in line 0
    const yFirstLine = this.lines[0].ascent + this.lines[0].descent +
    0.5 * LINE_SPACING;
    let yCurLine = yFirstLine;
    let curLine = 0;

    while (yCurLine < y && curLine < this.lines.length - 1) {
      curLine++;
      yCurLine += this.lines[curLine].ascent + this.lines[curLine].descent + LINE_SPACING;
    }
    return curLine;
  }

  /**
   * (Used by PreTextItemT.placeCursor())
   * Finds the character in the line-th line whose horizontal distance from
   * the left margin is closest to remainingWidth. Returns the bufferPos of this
   * character.
   * @param {number} line the line under consideration
   * @param {number} remainingWidth distance from the left margin (in
   *     scene coordinates)
   * @return {BufferPosition} new buffer position
   */
  getPositionInLine(line: number, remainingWidth: number) {
    let i = 0; // current position in line
    while (this.getOutput(line, i).width <= remainingWidth &&
    i < this.getNumberOfOutputsInLine(line) - 1) {
      remainingWidth -= this.getOutput(line, i).width;
      i++;
    }

    // the position is somewhere in output
    let output = this.getOutput(line, i);
    return new BufferPosition(output.bufferPos.input, output.bufferPos.inputChar + output.closestChar(remainingWidth));
  }

  getHorizontalDistInLine(line: number, bufferPos: BufferPosition) {
    let horizDist = 0;
    let i = 0;
    const numOutputs = this.getNumberOfOutputsInLine(line);
    let output = null; // we are assuming the first output in the line always precedes bufferPos
    while (i < numOutputs) {
      let newOutput = this.getOutput(line, i);
      if (newOutput.bufferPos.compareTo(bufferPos) > 0) {
        break;
      }
      if (output) {
        horizDist += output.width;
      }
      output = newOutput;
      i++;
    }
    // at this point, output is the last output whose start precedes bufferPos
    if (!output) {
      // this is possible if every text output on the line begins after bufferPos.
      // Easiest way to see this is with a bufferPos of (0, 0) (because the first nine
      // inputs correspond to zero-length formatting; the first output is produced by
      // input #9 (zero-based))
      return 0; // it's before anything else on the line
    }
    // if the final output entirely precedes the current bufferPos input, then it is to be included in its entirety
    if (output.bufferPos.input < bufferPos.input) {
      return horizDist + output.width;
    }
    // otherwise, we include include only a part of the output
    let remainingChars = bufferPos.inputChar - output.bufferPos.inputChar;
    return horizDist + output.widthTo(remainingChars);
  }

  getHorizontalLengthOfLine(line: Line) {
    return line.buffer.map((output: TextOutput) => output.width)
      .reduce((previous: number, current: number) => previous + current, 0);
  }

  /**
   * calculate which line contains bufferPos
   */
  getLineContainingBufferPos(bufferPos: BufferPosition) {
    var n = 0;
    while (this.precedesBufferPos(n + 1, 0, bufferPos)) {
      n++;
    }
    return n;
  }

  /**
   * Returns true if the buffer position corresponding to the output-th
   * TextOutput in the line-th line precedes (not strictly) bufferPos.
   * @param {number} line the line under consideration
   * @param {number} output the index of the output in line under consideration
   * @param bufferPos position in the input buffer
   * @return true iff
   * (1) line is a legitimate line number,
   * (2) output is a legitimate index in the line-th line, and
   * (3) this.lines[line].buffer[output].bufferPos <= bufferPos
   */
  precedesBufferPos(line: number, output: number, bufferPos: BufferPosition) {
    if (line >= this.lines.length || output >= this.getNumberOfOutputsInLine(line)) {
      return false;
    }

    let outputPos = this.getOutput(line, output).bufferPos;
    return outputPos.compareTo(bufferPos) <= 0;
  }

  /**
   * Returns the output-th TextOutput in the line-th line
   * @param {number} line the line under consideration
   * @param {number} output the index of the output in line under consideration
   * @return {TextOutput}
   */
  getOutput(line: number, output: number) {
    return this.lines[line].buffer[output];
  }

  /**
   * @param {number} line the line under consideration
   * @param bufferPos the input buffer position
   * @return {TextOutput} the output in the line-th line containing
   * bufferPos
   */
  getOutputGivenBufferPos(line: number, bufferPos: BufferPosition) {
    let outputIndex = 0;
    while (this.precedesBufferPos(line, outputIndex + 1, bufferPos)) {
      outputIndex++;
    }
    return this.lines[line].buffer[outputIndex];
  }

  /**
   * @param {number} line the line under consideration
   * @return {number} the number of inputs in the line-th line
   */
  getNumberOfOutputsInLine(line: number) {
    return this.lines[line].buffer.length;
  }

  /**
   * Gets the rectangles bounding the text selected by cursorPosition.
   * @param {CursorPosition} cursorPosition the cursor
   * @return {Array} an array of rectangles, in line coordinates
   */
  getSelectionRects(cursorPosition: CursorPosition) {
    if (!cursorPosition.isSelection) {
      return [];
    }
    const begin = cursorPosition.beginPos;
    const end = cursorPosition.endPos;
    const beginLine = this.getLineContainingBufferPos(begin);
    const endLine = this.getLineContainingBufferPos(end);
    const beginCursor = this.getCursor(begin, beginLine);
    const endCursor = this.getCursor(end, endLine);
    if (beginLine === endLine) {
      // we have a single rectangle
      const left = beginCursor.x;
      const top = beginCursor.y - beginCursor.ascent;
      const width = endCursor.x - beginCursor.x;
      const height = beginCursor.ascent + beginCursor.descent;
      return [new Rect(left, top, width, height)];
    }
    // we have multiple rectangles, one for each line
    // first rectangle
    const rect1 = new Rect(beginCursor.x,
      beginCursor.y - beginCursor.ascent,
      this.getHorizontalLengthOfLine(this.lines[beginLine]) - beginCursor.x,
      beginCursor.ascent + beginCursor.descent);
    const result = [rect1];
    // subsequent rectangles strictly between beginLine and endLine
    for (let i = beginLine + 1; i < endLine; i++) {
      const y = this.getYForLineIndex(i);
      const top = y - this.lines[i].ascent;
      const height = this.lines[i].ascent + this.lines[i].descent;
      result.push(new Rect(0, top, this.getHorizontalLengthOfLine(this.lines[i]), height));
    }
    // final rectangle
    const rectLast = new Rect(0,
      endCursor.y - endCursor.ascent,
      endCursor.x,
      endCursor.ascent + endCursor.descent);
    result.push(rectLast);
    return result;
  }

  getAllSelectionRects() {
    const beginPos = new BufferPosition(0, 0);
    const endPos = this.buffer.getBufferPos(this.buffer.length);
    return this.getSelectionRects(new CursorPosition(beginPos, endPos));
  }

  /**
   * Determine whether there is enough remaining width for the given width (with a tolerance for floating-point errors).
   * @param {*} remainingWidth remaining width
   * @param {*} width width to be placed
   * @return true if width <= remainingWidth + epsilon
   */
  static hasEnoughRemainingWidth(remainingWidth: number, width: number) {
    const EPSILON = 0.001;
    return remainingWidth - width > -EPSILON;
  }
}
