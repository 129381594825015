
import Delta from './Delta'
import ReleaseItemsDelta from './ReleaseItemsDelta'
import Item from '../items/Item'
import InteractiveScene from '../scene/InteractiveScene'
import Matrix from '../geometry/Matrix'
import Color from '../geometry/Color'
import SelectionItemT from '../itemTs/SelectionItemT'
import { LiveDocument } from '../document/LiveDocument'
import { GrabItemsDeltaState } from '../state/deltas'
import SimpleScene from '../scene/SimpleScene'

export default class GrabItemsDelta extends Delta {

	public items: Item[] | null = null;
	private selectionItemT: SelectionItemT | null = null;

	constructor(actId: string,
							private devId: string,
							private itemIds: string[],
							private initialMatrix: Matrix,
							)
	{
		super('grab', actId);
	}

	public get state(): GrabItemsDeltaState {
    return {
			t: 'grab',
			a: this.actId,
			h: this.devId,
			i: this.itemIds,
			m: this.initialMatrix.state,
    };
	}
	
	public static fromState(state: GrabItemsDeltaState) {
		return new GrabItemsDelta(state.a, state.h, state.i, Matrix.fromState(state.m));
	}

	public populate(scene: SimpleScene) {
		if (this.items === null) {
			let items = [];
			for (let id of this.itemIds) {
				let item = scene.getItem(id);
				if (item !== null) {
					items.push(item);
				}
			}
			this.items = items;
		}
	}

	public inverse(actId: string) {
		let delta = new ReleaseItemsDelta(actId, this.devId, this.itemIds, this.initialMatrix);
		delta.selectionItemT = this.selectionItemT;
		return delta;
	}

	public applyToScene(scene: InteractiveScene, document: LiveDocument) {
		if (this.items !== null) {
			scene.beginChanges();
			const initialMatrixInverse = this.initialMatrix.inverse();
			for (let item of this.items) {
				scene.removeSceneItem(item);
				item.matrix = initialMatrixInverse.times(item.matrix);
			}
			const isMyGrab = this.local;
			let itemT = new SelectionItemT(
				this.devId,
				this.items,
				this.initialMatrix,
				{
					color: Color.fromCss('#0066cc'),
					showButtons: isMyGrab,
				},
				document
			);
			// TODO the following line assumes this.selectionItemT is null (is that always true?)
			this.selectionItemT = itemT;
			if (itemT) {
				if (isMyGrab) {
					itemT.respondsToHoverEvents = true;
					itemT.respondsToClickEvents = true;
					itemT.respondsToKeyEvents = true;
					scene.setActiveClickResponder(itemT);
				}
				scene.addForefrontItem(itemT);
			}
			scene.endChanges();
		} else {
			throw Error('GrabItemsDelta: items is null');
		}
	}

}