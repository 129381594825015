import BlankPage from './BlankPage';
import { PageState } from '../state/background';
import Page from './Page';
import PdfPage from './PdfPage';

export default function Page_fromState(pageState: PageState): Page {
  switch (pageState.type) {
    case 'blank':
      return new BlankPage(pageState);
    case 'pdf':
      return new PdfPage(pageState);
  }
};