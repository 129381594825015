import Delta from "./Delta";
import { BackgroundState } from "../state/background";
import { ChangeBackgroundDeltaState } from "../state/deltas";
import InteractiveScene from "../scene/InteractiveScene";
import Background from "../background/Background";

export default class ChangeBackgroundDelta extends Delta {

	constructor(actId: string,
							private from: BackgroundState,
							private to: BackgroundState,
	) {
		super('bg', actId);
	}

	public get state(): ChangeBackgroundDeltaState {
		return {
			t: 'bg',
			a: this.actId,
			from: this.from,
			to: this.to,
		};
	}

	public static fromState(state: ChangeBackgroundDeltaState) {
		return new ChangeBackgroundDelta(state.a, state.from, state.to);
	}

	public applyToScene(scene: InteractiveScene) {
		scene.setBackground(new Background(this.to));
	}

	public inverse(actId: string) {
		return new ChangeBackgroundDelta(actId, {...this.to}, {...this.from});
	}

	public populate() {}

}