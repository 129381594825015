import React from "react"

const css = require("./toolbar.module.css");
import fa from "../lib/fontawesome"

const shapeToolImg = require("./icons/shapetool.svg");
const texPenImg = require("./icons/tex-pen.png");

import {
	onSelectorBtnClicked,
	onPenBtnClicked,
	onHighlighterBtnClicked,
	onEraserBtnClicked,
	onTextboxBtnClicked,
	onShapeToolsBtnClicked,
	onMyScriptBtnClicked,
} from "../../ui/buttonCallbacks";

const handlers = {
	selector: onSelectorBtnClicked,
	camera: () => {console.log("TODO camera")},
	pen: onPenBtnClicked,
	highlighter: onHighlighterBtnClicked,
	eraser: onEraserBtnClicked,
	text: onTextboxBtnClicked,
	shape: onShapeToolsBtnClicked,
	myscript: onMyScriptBtnClicked,
}


const ToolButton = ({children, tool, addCtx, toolName}) => (
	<button
		className={`${css.btn} ${tool.type===toolName && css.btnOn}`}
		onClick={addCtx(handlers[toolName])}
	>
		{children}
	</button>
)

export default ({tool, addCtx}) => (
	<div className={css.verticalButtons} style={{position: "fixed", left:"5px", top:"37px"}}>
		<ToolButton tool={tool} addCtx={addCtx} toolName="selector">
			<i className={`${fa.fa} ${fa.faMousePointer}`} />
		</ToolButton>
		<ToolButton tool={tool} addCtx={addCtx} toolName="camera">
			<i className={`${fa.fa} ${fa.faCamera}`} />
		</ToolButton>
		<ToolButton tool={tool} addCtx={addCtx} toolName="pen">
		<i className={`${fa.fa} ${fa.faPencil}`} />
		</ToolButton>
		<ToolButton tool={tool} addCtx={addCtx} toolName="highlighter">
			<i className={`${fa.fa} ${fa.faGoogleWallet}`} style={{color:"#f4d742"}} />
		</ToolButton>
		<ToolButton tool={tool} addCtx={addCtx} toolName="eraser">
			<i className={`${fa.fa} ${fa.faEraser}`} />
		</ToolButton>
		<ToolButton tool={tool} addCtx={addCtx} toolName="text">
			<p style={{verticalAlign:"center", margin:"0px", fontSize:"1.3em"}}>T</p>
		</ToolButton>
		<ToolButton tool={tool} addCtx={addCtx} toolName="shape">
			<img style={{width:"20px", height:"20px"}} src={shapeToolImg} />
		</ToolButton>
		<ToolButton tool={tool} addCtx={addCtx} toolName="myscript">
			<img style={{width:"20px", height:"20px"}} src={texPenImg} />
		</ToolButton>
	</div>
);