
export class SvgSource {

}

export type SvgType = {
	width: number,
	height: number,
}

export abstract class SvgCache {
	abstract getSvgOrNull(source: SvgSource): SvgType | null;
	abstract getSvg(source: SvgSource, then: (svg: SvgType)=>void ): void;
}

export class FakeSvgCache extends SvgCache {

	getSvgOrNull(source: SvgSource): SvgType | null {
		throw new Error("Method not implemented.");
	}

	getSvg(source: SvgSource, then: (svg: SvgType) => void): void {
		throw new Error("Method not implemented.");
	}

}