
import Delta from '../deltas/Delta'

import SimpleDocument from './SimpleDocument'

export default class MutableDocument extends SimpleDocument  {

  // apply delta to document state
  protected change(delta: Delta): boolean {
    // TODO
    return false;
  }

}