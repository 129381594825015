////////////////////////////////////////////////////////////////////////////////
// EndFormattingTextInput                                                     //
////////////////////////////////////////////////////////////////////////////////

import TextInput, { TextInputType } from './TextInput';
import { EndFormattingTextInputState } from '../../state/text';
import TextFormatting, { TextFormattingType } from '../TextFormatting';
import { FormattingStack } from './BeginFormattingTextInput';

export default class EndFormattingTextInput extends TextInput {

  constructor() {
    super(TextInputType.end_formatting);
	}
	
	static fromState(state: EndFormattingTextInputState) {
		return new EndFormattingTextInput();
	}

	get state(): EndFormattingTextInputState {
		return {
			t: 'end',
		};
	}

  get length() {
    return 0;
  }

  public charAt() {
    return '';
  }

  toString() {
    return '';
  }

  /**
   * Called by PreTextItemT.calculateLines() and
   * PreTextItemT.drawOnCanvas().
   * Updates formattingStack and formatting w.r.t. the formatting input
   * @param {TextFormatting} formatting
   * @param formattingStack an array of property-value pairs
   */
  dealWithFormatting(formatting: TextFormatting, formattingStack: FormattingStack) {
		const newFormatting = formattingStack.pop();
		if (newFormatting) {
			(formatting as any)[newFormatting.property] = newFormatting.value;
		}
  }
}