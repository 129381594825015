
import Item from './Item'
import { Canvas } from '../Canvas'
import Path from '../geometry/path/Path'
import Color from '../geometry/Color'
import Rect from '../geometry/Rect'
import Matrix from '../geometry/Matrix'
import Point from '../geometry/Point'
import Parallelogram from '../geometry/Parallelogram'
import { PathItemState } from '../state/items'
import Path_fromState from '../geometry/path/factory'
import SimpleDocument from '../document/SimpleDocument'

export default class PathItem extends Item {

  private path: Path;
  private color: Color;
  private size: number;

  constructor(id: string, matrix: Matrix, path: Path, color: Color, size: number) {
    super(id, matrix);
    this.path = path;
    this.color = color;
    this.size = size;
  }

  static fromState(state: PathItemState, document: SimpleDocument) {
    let id = state.id;
    let matrix = Matrix.fromState(state.m);
    let path = Path_fromState(state.p, document);
    let color = Color.fromState(state.c);
    let size = state.s;
    return new PathItem(id, matrix, path, color, size);
  }

  get state(): PathItemState {
    return {
      t: 'path',
      id: this.id,
      m: this.matrix.state,
      p: this.path.state,
      c: this.color.state,
      s: this.size,
    };
  }

  public drawOnCanvas(canvas: Canvas) {
    canvas.save();
    canvas.transformMatrix(this.matrix);
    canvas.setPathWidth(this.size);
    canvas.setPathColor(this.color);
    this.path.trace(canvas);
    canvas.restore();
  }

  protected _computeBoundingRect() {
    let rect = this.path.computeBoundingRect(this.matrix);
    let halfThickness = this.size * Math.sqrt(this.matrix.det()) / 2;
    return rect.expandedBy(halfThickness, halfThickness, halfThickness, halfThickness);
  }

  public intersectsRect(rect: Rect) {
    let parallelogram = new Parallelogram(rect, this.matrix);
    return this.path.intersectsParallelogram(parallelogram);
  }

  public intersectsSegment(end1: Point, end2: Point) {
    // since the segment is generally small, we use this hack for now
    return this.intersectsRect(Rect.fromXYXY(end1.x, end1.y, end2.x, end2.y));
  }

}