
import EllipseShape from './EllipseShape';
import SegmentShape from './SegmentShape';
import RectangleShape from './RectangleShape';
import { MinifiedShape } from '../../state/shapes';
import Shape from './Shape';

export default function Shape_fromState(mini: MinifiedShape): Shape {
	switch(mini.type) {
		case 'ellipse':
			return EllipseShape.unminify(mini);
		case 'segment':
			return SegmentShape.unminify(mini);
		case 'rect':
			return RectangleShape.unminify(mini);
	}
}