import React from "react"

import { Link } from "../../../../services/routes" //  "gatsby"
import { getSession } from "../../../../services/auth"
import routes from "../../../../services/routes"

import options from "./options"
const css = require("./menubar.module.css");
import fa from "../lib/fontawesome"

// TODO handle shortcuts

const UserLink = () => {
	const user = getSession().user;
	let text = user ? user.name : "Log In";
	return (
		<div className={css.userLinkContainer}>
			<Link to={user ? routes.account.boards : routes.login} className={css.userLink}>
				<i className={`${fa.fa} ${fa.faUser}`} />
				&nbsp;&nbsp;{text}
			</Link>
		</div>
	);
}

const MenuItem = ({text, onclick, shortcut}) => (
	<li>
		<a onClick={onclick}>
			{text}<span className={css.shortcut} id={shortcut} />
		</a>
	</li>
);

const Dropdown = ({name, items, addCtx}) => (
	<li className={css.dropdown}>
		<a className={css.dropdownHeading}>
			{name}
		</a>
		<div className={css.dropdownContent}>
			<ul>
				{items.map((item, index) =>
					<MenuItem key={item.text} text={item.text} onclick={addCtx(item.onclick)} shortcut={item.shortcut} />
				)}
			</ul>
		</div>
	</li>
);

export default ({heightPx, addCtx}) => (
	<div className={css.menubar} style={{height:`${heightPx}px`, minHeight:`${heightPx}px`}}>
		<ul>
			{
				options.map(option => (
					<Dropdown key={option.name} name={option.name} items={option.items} addCtx={addCtx} />
				))
			}
		</ul>
		<UserLink />
	</div>
);
