import React from "react"

import BoardContext from "../../BoardContext"
import BrowserCanvas from "./BrowserCanvas"
import UserInterface from "../../ui/UserInterface"
import CanvasContext from "../../core/context/CanvasContext"
import SharedContext from "../../core/context/SharedContext"

type Props = {
	boardContext: BoardContext,
	sharedContext: SharedContext,
}

export default class SceneViewComponent extends React.Component<Props> {

	public canvasContext: CanvasContext | null = null;

	private _boardContext: BoardContext;
	private _sharedContext: SharedContext;

	constructor(props) {
		super(props);
		this._boardContext = props.boardContext;
		this._sharedContext = props.sharedContext;
	}

	componentDidMount() {
		const htmlCanvas = this.refs.canvas as HTMLCanvasElement;
		const canvas = new BrowserCanvas(htmlCanvas);
		const capabilities = { mouse: true, wheel: true, keyboard: true };
		const ui = new UserInterface(this._boardContext);
		const boardId = this._boardContext.props.boardId;
		this.canvasContext = new CanvasContext(canvas, capabilities, ui, this._sharedContext, boardId);
	}

	public render() {
		return (
			<canvas ref="canvas" style={{width:"100%", height:"100%"}} />
		);
	}
}