
import Delta from './Delta'
import AddItemDelta from './AddItemDelta'
import MutableScene from '../scene/MutableScene'
import Item from '../items/Item'
import { DeleteItemDeltaState, AddItemDeltaState } from '../state/deltas'
import { ItemState } from '../state/items'
import SimpleScene from '../scene/SimpleScene'
import SimpleDocument from '../document/SimpleDocument'

export default class DeleteItemDelta extends Delta {

	public item: Item | null = null;

	constructor(actId: string,
							private itemState: ItemState,
							)
	{
		super('del', actId);
	}

	public get state(): DeleteItemDeltaState {
    return {
			t: 'del',
			a: this.actId,
			i: this.itemState,
    };
  }

  public static fromState(state: DeleteItemDeltaState) {
    return new DeleteItemDelta(state.a, state.i);
	}

  public populate(scene: SimpleScene, document: SimpleDocument) {
		// there is a bug here, where if the scene doesn't have the item
		//   but the item exists for some reason (in, say, an AddItemDelta)
		//   then this will fail
		// TODO BUG make a separate item registry
    if (this.item === null) {
			this.item = scene.getItem(this.itemState.id);
		}
  }

	public inverse(actId: string) {
		let delta = new AddItemDelta(actId, this.itemState);
		delta.item = this.item;
		return delta;
	}

	public applyToScene(scene: MutableScene) {
		if (this.item !== null) {
			scene.removeSceneItem(this.item);
		} else {
			throw Error('DeleteItemDelta: item is null');
		}
	}

}