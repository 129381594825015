
import Channel, { IChannelListener } from './realtime/Channel'
import Device from './realtime/Device';

type MessageHandler = (message: any) => void;
type SubscribedHandler = (devices: Device[]) => void;

export default class Messenger implements IChannelListener {

	private messageHandlers: Map<string, MessageHandler> = new Map();
	private subscribedHandlers: SubscribedHandler[] = [];
	private majorSequence: number = 0;

	constructor(private _channel: Channel) {
		_channel.addListener(this);
	}

	public broadcast(type: string, message: any) {
		this._channel.broadcast(this, { type, message }, /*important=*/true);
	}

	public broadcastDel(type: string, message: any) {
		this._channel.broadcast(this, { type, message });
	}

	public onSubscribe(handler: SubscribedHandler) {
		this.subscribedHandlers.push(handler);
	}

	public onMessage(type: string, handler: MessageHandler) {
		this.messageHandlers.set(type, handler);
	}

  private deliverMessage(type: string, message: any) {
		const handler = this.messageHandlers.get(type);
    if (handler) {
      handler(message);
    } else {
      console.log(`Incoming message had unrecognized type: ${ type }`);
    }
	}

	//////////////////////
	// IChannelListener //
	//////////////////////

	public receive(message: any) {
		if (message.type && message.message) {
			this.deliverMessage(message.type, message.message);
		}
	}

	public subscribed(devices: Device[]) {
		for (const handler of this.subscribedHandlers) {
			handler(devices);
		}
	}

}