
import { ApplicationMessage } from '../AgentManager'
import Messenger from '../../context/Messenger'

export default class Sender {

	constructor(private _id: string, private _messenger: Messenger) {
	}

	public broadcast(channel: string, message: any) {
		const applicationMessage: ApplicationMessage = {
			sender: this._id,
			channel,
			message,
		};
		this._messenger.broadcast('agent', applicationMessage);
	}

	public broadcastDel(channel: string, message: any) {
		const applicationMessage: ApplicationMessage = {
			sender: this._id,
			channel,
			message,
		};
		this._messenger.broadcastDel('agent', applicationMessage);
	}

}