
import { ILiveDocumentSubscriber } from "../document/LiveDocument"
import Delta from "../deltas/Delta";
import Resource from "../resources/Resource";
import Analytics from "./Analytics";
import Messenger from "./Messenger";
import ServerInterface from "./ServerInterface";
import SimpleScene from "../scene/SimpleScene";

/*
	When this device is responsible for saving
		on a change, start the update timer for UPDATE_INTERVAL millis (if not already started)
		when the timer goes off, save and send a "save" message to everyone else
	When this device is not responsible
		on a change
			start the checkSave timer for CHECK_SAVE_INTERVAL millis (if not already started)
			start the takeResponsibility timer for TAKE_RESPONSIBILITY_INTERVAL millis (if not already started)
		if a "save" message from someone else is received, cancel both timers
		when checkSave timer goes off
			send a "take responsibility" message with a random number
		if a "take responsibility" message with a lower number is received, cancel takeResponsibility timer
		when takeResponsibility timer goes off
			take responsibility and save
*/

export class Timer {
	public action = () => {};
	public running: boolean = false;
	private _timeout: any;
	constructor(public readonly intervalMillis: number) {}
	public startOrContinue() {
		if (!this._timeout) {
			this._timeout = setTimeout(() => {
				this._timeout = null;
				this.action();
			}, this.intervalMillis);
		}
	}
	public cancel() {
		if (this._timeout) {
			clearTimeout(this._timeout);
			this._timeout = null;
		}
	}
}

// constant intervals (in milliseconds)
// time between first change and save
const UPDATE_INTERVAL = 5000;
// time between when this device makes a change and when it checks that a device has saved
const CHECK_SAVE_INTERVAL = UPDATE_INTERVAL + 3000;
// time between when this device makes a change and when it takes responsibility if not hearing about another device taking responsibility or saving the board state
const TAKE_RESPONSIBILITY_INTERVAL = CHECK_SAVE_INTERVAL + 3000;

// time between edit and analytics event (to group edits)
const ANALYTICS_EDIT_INTERVAL = 15 * 1000;

export default class SaveManager implements ILiveDocumentSubscriber {

	private _updateTimer: Timer = new Timer(UPDATE_INTERVAL);
	private _checkSaveTimer: Timer = new Timer(CHECK_SAVE_INTERVAL);
	private _takeResponsibilityTimer: Timer = new Timer(TAKE_RESPONSIBILITY_INTERVAL);
	private _analyticsTimer: Timer = new Timer(ANALYTICS_EDIT_INTERVAL);

	private _responsible: boolean = false;

	private _serverInterface: ServerInterface;
	private _messenger: Messenger;
	private _boardUrlId: string;
	private _scene: SimpleScene;

	constructor(messenger: Messenger, analytics: Analytics, serverInterface: ServerInterface, boardUrlId: string, scene: SimpleScene) {
		this._serverInterface = serverInterface;
		this._messenger = messenger;
		this._boardUrlId = boardUrlId;
		this._scene = scene;
		const number = Math.random();
		this._updateTimer.action = () => {
			this._doFullUpdate();
		}
		this._checkSaveTimer.action = () => {
			messenger.broadcast("save: take responsibility", { number });
		}
		this._takeResponsibilityTimer.action = () => {
			this._takeResponsibility();
			this._doFullUpdate();
		}
		this._analyticsTimer.action = () => {
			analytics.event("Edit");
		}
		messenger.onMessage("save: take responsibility", (message) => {
			if (message.number < number) {
				this._takeResponsibilityTimer.cancel();
			}
		});
		messenger.onMessage("save: saved", (message) => {
			this._checkSaveTimer.cancel();
			this._takeResponsibilityTimer.cancel();
		});
	}

	//////////////////////
	// public interface //
	//////////////////////

	public firstToJoin() {
		this._takeResponsibility();
	}

	public beginChanges() {
	}

	public endChanges() {
	}

	public onDelta(delta: Delta) {
		if (this._responsible) {
			this._updateTimer.startOrContinue();
		} else {
			this._checkSaveTimer.startOrContinue();
			this._takeResponsibilityTimer.startOrContinue();
		}
		if (delta.local) {
			this._analyticsTimer.startOrContinue();
		}
	}

	public onResource(resource: Resource) {
	}

	/////////////
	// private //
	/////////////

	private _takeResponsibility() {
		console.log('I am now responsible for saving.');
		this._responsible = true;
	}

	private _doFullUpdate() {
		this._messenger.broadcast("save: saved", {});
		const state = JSON.stringify(this._scene.getBoardState());
		this._serverInterface.saveBoardState(state, this._boardUrlId);
	}

}
