
import Item from './Item';
import Matrix from '../geometry/Matrix';
import Rect from '../geometry/Rect';
import { ShapeItemState } from '../state/items';
import Shape from '../geometry/shapes/Shape';
import Parallelogram from '../geometry/Parallelogram';
import { Canvas } from '../Canvas';
import Shape_fromState from "../geometry/shapes/factory";
import Point from "../geometry/Point";

export default class ShapeItem extends Item {

	public readonly scaleInvariant = false;
	public readonly isErasable = true;

	private _shape: Shape;

	constructor(id: string, matrix: Matrix, shape: Shape) {
		super(id, matrix);
		this._shape = shape;
	}

	static fromState(state: ShapeItemState) {
		let id = state.id;
		let matrix = Matrix.fromState(state.m);
		let shape = Shape_fromState(state.s);
		return new ShapeItem(id, matrix, shape);
	}

	public get state(): ShapeItemState {
		return {
			t: 'shape',
			id: this.id,
			m: this.matrix.state,
			s: this._shape.minify(),
		};
	}

	public drawOnCanvas(canvas: Canvas, matrix: Matrix) {
		this._shape.drawOnCanvas(canvas, matrix.times(this.matrix));
	}

	protected _computeBoundingRect(): Rect {
		return this._shape.getBoundingRect(this.matrix);
	}

	public intersectsRect(rect: Rect) {
		return this._shape.intersectsParallelogram(new Parallelogram(rect, this.matrix));
	}

	public intersectsSegment(end1: Point, end2: Point): boolean {
		// TODO use Shape.intersectsSegment (not yet implemented)
		return this.intersectsRect(Rect.fromXYXY(end1.x, end1.y, end2.x, end2.y));
	}

	/*
	public getPdfgenData(matrix: Matrix) {
		return this._shape.getPdfgenData(matrix.times(this.matrix));
	}
	*/

}