
import PrePathItemT from '../itemTs/PrePathItemT'
import Item from '../items/Item'
import SelectionItemT from '../itemTs/SelectionItemT'
import HaloItemT from '../itemTs/HaloItemT';


export default class Agent {

	// Note: I'm not sure if this class is appropriate
	//       (Is it really necessary to have LocalAgent and RemoteAgent share a superclass?)
	//       (consider storing the ItemTs in a dedicated class)
	//       I'll commit this, since I'm facing a big refactor and it's not worth getting everything perfect right now

	public haloItemT: HaloItemT | null = null;
	public prePathItemT: PrePathItemT | null = null;
	public item: Item | null = null; // used by AddItemDelta, DeleteItemDelta
	public items: Item[] | null = null; // used by GrabItemsDelta
	public selectionItemT: SelectionItemT | null = null; // used by GrabItemsDelta, ReleaseItemsDelta, TransformItemsDelta

}