
import { Canvas } from '../Canvas'
import Color from '../geometry/Color'
import Point from '../geometry/Point'
import PointBufferResource from '../resources/PointBufferResource'
import Viewport from '../geometry/Viewport'
import ItemT from './ItemT'

export default class PrePathItemT extends ItemT {

  private color: Color;
  private size: number;

  // data to construct the PointBuffer
  private xs: Array<number> = [];
  private ys: Array<number> = [];
  private viewport: Viewport;

  // used only for rendering PrePathItemT
  private scenePoints: Array<Point> = [];

  constructor(devId: string, color: Color, size: number, viewport: Viewport) {
    super(devId);
    this.color = color;
    this.size = size;
    this.viewport = viewport;
  }

  public drawOnCanvas(canvas: Canvas, viewport: Viewport) {
    if (this.xs.length < 2) {
      return;
    }
    canvas.save();
    canvas.transform(viewport);
    canvas.setPathColor(this.color);
    canvas.setPathWidth(this.size);
    canvas.drawLinearPath(this.scenePoints);
    canvas.restore();
  }

  public addXY(x: number, y: number, viewport: Viewport) {
    const viewPoint = new Point(x, y);
    const scenePoint = viewport.viewToSceneMatrix().timesPoint(viewPoint);
    this.scenePoints.push(scenePoint);

    // should be true almost all the time (unless user pans/zooms while drawing)
    if (viewport.equals(this.viewport)) {
      this.xs.push(x);
      this.ys.push(y);
    } else {
      const dataPoint = this.viewport.sceneToViewMatrix().timesPoint(scenePoint);
      this.xs.push(dataPoint.x);
      this.ys.push(dataPoint.y);
    }

    if (this.scene) {
      this.scene.redisplay();
    }
  }

  public makePointBufferResource(id: string) {
    return new PointBufferResource(id, this.xs, this.ys, this.viewport.viewToSceneMatrix());
  }

}