import Rect from "../geometry/Rect";
import { Canvas } from "../Canvas";
import { PageState } from "../state/background";

export default abstract class Page {

	public readonly uid: string;
	public readonly rect: Rect;

	constructor(uid: string, rect: Rect) {
		this.uid = uid;
		this.rect = rect;
	}

	public abstract get state(): PageState;

	public abstract drawOnCanvas(canvas: Canvas, x: number, y: number, s: number): void;

}