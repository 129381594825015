import { DocumentState } from "../state/background";
import Rect from "../geometry/Rect";
import { Canvas } from "../Canvas";
import Page from "./Page";
import Page_fromState from "./factory";

export default class BackgroundDocument {

	public readonly rect: Rect;
	private _pages: Page[] = [];
	private _layoutMode: 'book'|'vc'|'hc';
	private _uid: string;
	private _fileIds: string[];

	constructor(state: DocumentState) {
		this._uid = state.uid;
		this._fileIds = state.fileIds;
		this._layoutMode = state.layoutMode;
		for (const pageState of state.pages) {
			this._pages.push(Page_fromState(pageState));
		}
		// calculate this.rect by unioning all rects and shifting by state.rect's upper left corner
		const pageRects = this._pages.map(page => page.rect);
		const pagesLeft = Math.min(...pageRects.map(r => r.left));
		const pagesRight = Math.max(...pageRects.map(r => r.right()));
		const pagesTop = Math.min(...pageRects.map(r => r.top));
		const pagesBottom = Math.max(...pageRects.map(r => r.bottom()));
		const docRect = Rect.fromState(state.rect);
		this.rect = new Rect(
			docRect.left,
			docRect.top,
			pagesRight-pagesLeft,
			pagesBottom-pagesTop,
		);
	}

	public drawOnCanvas(canvas: Canvas, x: number, y: number, s: number) {
		// find the view in document coordinates
		var viewRect = new Rect(x, y, canvas.width() / s, canvas.height() / s);
		var ctx = canvas.getContext('2d');
		ctx.save();
		ctx.strokeStyle = 'black';
		// TODO use layout to more intelligently determine which pages to draw
		// for each page that overlaps viewRect, draw the page and the frame
		for (var i = 0; i < this._pages.length; i++) {
			var rect = this._pages[i].rect;
			if (viewRect.intersects(rect)) {
				this._pages[i].drawOnCanvas(canvas,
					x - rect.left,
					y - rect.top,
					s);
				var left = (rect.left - x) * s;
				var top = (rect.top - y) * s;
				var width = rect.width * s;
				var height = rect.height * s;
				ctx.beginPath();
				ctx.rect(left, top, width, height);
				ctx.stroke();
			}
		}
		ctx.restore();
	};

	public get state(): DocumentState {
		return {
			uid: this._uid,
			rect: this.rect.state,
			pages: this._pages.map(p => p.state),
			fileIds: [...this._fileIds],
			layoutMode: this._layoutMode,
		}
	}

	public relayout(mode: 'book'|'vc'|'hc') {
		this._layoutMode = mode;
		var pageCount = this._pages.length;
		if (mode === 'book') {
			// handle first page
			let maxWidth = this._pages[0].rect.width;
			let totalHeight = this._pages[0].rect.height;
			// handle even-odd page pairs
			for (var i = 1; i + 1 < pageCount; i += 2) {
				maxWidth = Math.max(maxWidth, this._pages[i].rect.width + this._pages[i + 1].rect.width);
				totalHeight += Math.max(this._pages[i].rect.height, this._pages[i + 1].rect.height);
			}
			// handle last page (if even count)
			if (pageCount % 2 === 0) {
				maxWidth = Math.max(maxWidth, this._pages[pageCount - 1].rect.width);
				totalHeight += this._pages[pageCount - 1].rect.height;
			}
			// handle first page
			var rect = this._pages[0].rect;
			rect.left = (maxWidth - rect.width) / 2;
			rect.top = 0;
			let t = rect.height;
			// handle even-odd page pairs
			for (var i = 1; i + 1 < pageCount; i += 2) {
				var rectl = this._pages[i].rect;
				var rectr = this._pages[i + 1].rect;
				rectl.top = t;
				rectr.top = t;
				rectl.left = maxWidth / 2 - rectl.width;
				rectr.left = maxWidth / 2;
				t += Math.max(rectl.height, rectr.height);
			}
			// handle last page (if even count)
			if (pageCount % 2 === 0) {
				rect = this._pages[pageCount - 1].rect;
				rect.top = t;
				rect.left = (maxWidth - rect.width) / 2;
			}
			this.rect.width = maxWidth;
			this.rect.height = totalHeight;
		} else if (mode === 'hc') {
			var totalWidth = 0;
			var maxHeight = 0;
			for (var i = 0; i < pageCount; i++) {
				totalWidth += this._pages[i].rect.width;
				maxHeight = Math.max(maxHeight, this._pages[i].rect.height);
			}
			var l = 0;
			for (var i = 0; i < pageCount; i++) {
				var rect = this._pages[i].rect;
				rect.left = l;
				rect.top = (maxHeight - rect.height) / 2;
				l += rect.width;
			}
			this.rect.width = totalWidth;
			this.rect.height = maxHeight;
		} else if (mode === 'vc') {
			let maxWidth = 0;
			let totalHeight = 0;
			for (var i = 0; i < pageCount; i++) {
				maxWidth = Math.max(maxWidth, this._pages[i].rect.width);
				totalHeight += this._pages[i].rect.height;
			}
			let t = 0;
			for (var i = 0; i < pageCount; i++) {
				var rect = this._pages[i].rect;
				rect.left = (maxWidth - rect.width) / 2;
				rect.top = t;
				t += rect.height;
			}
			this.rect.width = maxWidth;
			this.rect.height = totalHeight;
		} else {
			console.log(`Document.relayout(): unknown mode: ${ mode }`);
		}
	}


}