import ServerInterface, { PDFJS_PDF, PDFJS_PAGE } from "../context/ServerInterface";
import { DocumentState, PdfPageState } from "../state/background";
import IdSource from "../IdSource";
import BackgroundDocument from "./BackgroundDocument";

export default class PdfFileManager {

	// indexed by urlId. if entry is null, then it is loading
  private _pdfs: { [urlId: string]: PDFJS_PDF|null } = {};
	private _pdfCallbacks: { [urlId: string]: ((pdf: PDFJS_PDF)=>void)[] } = {};
	
	// indexed by url, then pageIndex. if entry is null, then it is loading
	private _pages: { [urlId: string]: { [pageIdx: string]: PDFJS_PAGE|null } } = {};
	private _pageCallbacks: { [urlId: string]: { [pageIdx: number]: ((pdf: PDFJS_PAGE)=>void)[] } } = {};

	private _serverInterface: ServerInterface;

	constructor(serverInterface: ServerInterface) {
		this._serverInterface = serverInterface;
	}

	public async getPage(urlId: string, boardUrlId: string, pageIndex: number): Promise<PDFJS_PAGE> {
    if (!(urlId in this._pages)) {
      this._pages[urlId] = {};
    }
    if (!(pageIndex in this._pages[urlId])) {
      // this is the first request for the page
			this._pages[urlId][pageIndex] = null;
			this._getPdf(urlId, boardUrlId).then(async (pdf) => {
				const page = await pdf.getPage(pageIndex + 1);
				if (page.pageNumber-1 !== pageIndex) {
					console.warn('mismatch between page indices', pageIndex, page.pageNumber-1);
				}
				this._pages[urlId][pageIndex] = page;
				if (urlId in this._pageCallbacks) {
					if (pageIndex in this._pageCallbacks[urlId]) {
						// if anyone was waiting for the page, notify them now
						for (const cb of this._pageCallbacks[urlId][pageIndex]) {
							cb(page);
						}
						delete this._pageCallbacks[urlId][pageIndex];
					}
				}
			});
		}
		const pageOrNull = this._pages[urlId][pageIndex]
    if (pageOrNull == null) {
      // the page is loading--join the wait list
      if (!(urlId in this._pageCallbacks)) {
        this._pageCallbacks[urlId] = {};
      }
      if (!(pageIndex in this._pageCallbacks[urlId])) {
        this._pageCallbacks[urlId][pageIndex] = [];
			}
			return new Promise<PDFJS_PAGE>((resolve, reject) => {
				this._pageCallbacks[urlId][pageIndex].push(resolve);
			})
    } else {
      // the page has already been loaded
      return pageOrNull;
    }
	}

	public async createBackgroundDocument(urlId: string, boardUrlId: string, idSource: IdSource): Promise<BackgroundDocument> {
		const pdf = await this._getPdf(urlId, boardUrlId);
		const numPages = pdf.numPages;
		const promises = new Array(numPages);
		for (var i = 0; i < numPages; i++) {
			promises[i] = new Promise<PdfPageState>(async (resolve, reject) => {
				const page = await this.getPage(urlId, boardUrlId, i);
				const vp = page.getViewport({scale:1});
				resolve({
					uid: idSource.newId(),
					type: 'pdf',
					fileId: urlId,
					index: page.pageNumber-1,
					rect: [0, 0, vp.width, vp.height],
				});
			});
		}
		const pagesData = await Promise.all(promises);

		var json: DocumentState = {
			uid: idSource.newId(),
			rect: [0, 0, 0, 0], // gets set later
			pages: pagesData,
			fileIds: [urlId],
			layoutMode: 'book',
		};
		// create a document to layout the pages
		const doc = new BackgroundDocument(json);
		// layout the pages so they don't overlap
		doc.relayout('book');
		// return the document
		return doc;
	}

	private async _getPdf(urlId: string, boardUrlId: string): Promise<PDFJS_PDF> {
		if (!(urlId in this._pdfs)) {
      // this is the first request for the pdf
      this._pdfs[urlId] = null;
      if (!(urlId in this._pdfCallbacks)) {
        this._pdfCallbacks[urlId] = [];
			}
			return new Promise<PDFJS_PDF>(async (resolve, reject) => {
				this._pdfCallbacks[urlId].push(resolve);
				const pdf = await this._serverInterface.getPdfFile(urlId, boardUrlId);
				this._pdfs[urlId] = pdf;
				// if anyone was waiting for the pdf, notify them now
				if (urlId in this._pdfCallbacks) {
					for (const cb of this._pdfCallbacks[urlId]) {
						cb(pdf);
					}
					delete this._pdfCallbacks[urlId];
				}
			});
    } else {
			const pdfOrNull = this._pdfs[urlId];
      if (pdfOrNull == null) {
				// the file is loading--join the wait list
				return new Promise<PDFJS_PDF>((resolve, reject) => {
					if (!(urlId in this._pdfCallbacks)) {
						this._pdfCallbacks[urlId] = [];
					}
					this._pdfCallbacks[urlId].push(resolve);
				});
      } else {
				// the file has already been loaded
				return pdfOrNull;
      }
    }
	}

}