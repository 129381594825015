
import { ILiveDocumentSubscriber, LiveDocument } from '../document/LiveDocument'
import Resource from '../resources/Resource'
import Delta from '../deltas/Delta'
import { ResourceState } from '../state/resources'
import { DeltaState } from '../state/deltas'
import Resource_fromState from '../resources/factory'
import Delta_fromState from '../deltas/factory'
import Scene from '../scene/Scene'
import LocalAgent from '../agent/local/LocalAgent'

interface DocumentChanges {
	resources: ResourceState[],
	deltas: DeltaState[],
}

export class DocumentChangesRelay implements ILiveDocumentSubscriber {

	private semaphore: number = 0;
	private resourcesToSend: Resource[] = [];
	private deltasToSend: Delta[] = [];

	constructor(private document: LiveDocument,
							private localAgent: LocalAgent,
							private scene: Scene, // TODO replace with ItemProvider
							)
	{
	}

	public beginChanges() {
		this.semaphore += 1;
	}
	
	public endChanges() {
		this.semaphore -= 1;
		if (this.semaphore === 0) {
			if (this.resourcesToSend.length > 0 || this.deltasToSend.length > 0) {
				this.send();
			}
		}
	}

	public onDelta(delta: Delta) {
		if (delta.local) {
			this.deltasToSend.push(delta);
			if (this.semaphore === 0) {
				this.send();
			}
		}
	}

	public onResource (resource: Resource) {
		if (resource.local) {
			this.resourcesToSend.push(resource);
			if (this.semaphore === 0) {
				this.send();
			}
		}
	}

	private send() {
		this.localAgent.addDocumentChanges(this.resourcesToSend, this.deltasToSend);
		this.resourcesToSend = [];
		this.deltasToSend = [];
	}

}