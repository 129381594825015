
import Channel from './Channel'
import Sender from '../local/Sender'

// the halo channel is sequenced, but may have gaps (out-of-order messages are dropped)

type HaloMessage = {
	x: number,
	y: number,
	seq: number,
};

function isHaloMessage(m: any): m is HaloMessage {
	return 'x' in m && 'y' in m && 'seq' in m;
}

export class HaloSender {

	private sequence: number = 0;

	constructor(private _sender: Sender) {
	}

	public send(x: number, y: number) {
		const message: HaloMessage = {
			x,
			y,
			seq: ++this.sequence,
		};
		this._sender.broadcastDel('halo', message);
	}

}

export class HaloReceiverChannel extends Channel {
	
	private sequence: number = 0;

	constructor(private _handler: (x: number, y: number) => void) {
		super();
	}

	public receive(message: any) {
		if (isHaloMessage(message)) {
			if (message.seq > this.sequence) {
				this.sequence = message.seq;
				this._handler(message.x, message.y);
			}
		}
	}

}