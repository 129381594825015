
import Channel from "./Channel"
import Device from "./Device"
import WebsocketClient from "./websockets/WebsocketClient"
import { DeviceData, DeviceChannelInfo } from "./websockets/interface";

export default class RealtimeService {

	public thisDevice: Device|null = null;
	public readonly otherDevices: { [deviceId: string]: Device } = {};
	public readonly channels: { [channelId: string]: Channel } = {};

	private _websocketClient: WebsocketClient;

	constructor(wsUrl: string) {
		this._websocketClient = new WebsocketClient(wsUrl);
		this._setupWebsocketHandlers();
	}

	// creates channel if it does not exist, otherwise returns existing channel
	public subscribe(channelId: string, channelToken: string): Channel {
		const channel = this._getOrMakeChannel(channelId, channelToken);
		if (this._websocketClient.isConnected()) {
			this._websocketClient.subscribe([channelId], [channelToken]);
		}
		return channel;
	}

	private _getOrMakeOtherDevice(deviceId: string, socketIdIfConstructing: string|null): Device {
		if (!(deviceId in this.otherDevices)) {
			this.otherDevices[deviceId] = new Device(deviceId, socketIdIfConstructing, false, this._websocketClient);
		}
		return this.otherDevices[deviceId];
	}

	private _getOrMakeChannel(channelId: string, channelTokenIfConstructing: string = ""): Channel {
		if (!(channelId in this.channels)) {
			this.channels[channelId] = new Channel(channelId, channelTokenIfConstructing, this._websocketClient);
		}
		return this.channels[channelId];
	}

	///////////////////////////////
	// notifications from server //
	///////////////////////////////

	private _setupWebsocketHandlers() {

		this._websocketClient.onConnected = () => {
			const channelIds = Object.values(this.channels).map(channel => channel.channelId);
			const channelTokens = Object.values(this.channels).map(channel => channel.channelToken).filter(t => t!=="");
			this._websocketClient.subscribe(channelIds, channelTokens);
		}

		this._websocketClient.onDeviceId = (deviceId: string) => {
			this.thisDevice = new Device(deviceId, null, true, this._websocketClient);
		}

		this._websocketClient.onSubscribe = (channelId: string, devices: DeviceData[]) => {
			const channel = this._getOrMakeChannel(channelId);
			for (const data of devices) {
				const deviceId = data.deviceId;
				// checking just to be safe
				if (deviceId !== this.thisDevice?.deviceId) {
					const device = this._getOrMakeOtherDevice(deviceId, data.socketId);
					channel.addDevice(device);
				}
			}
			channel.notifySubscribed();
		}

		this._websocketClient.onDeviceSubscribed = (socketId: string, deviceId: string, channelId: string, info: DeviceChannelInfo) => {
			// checking just to be safe
			if (deviceId !== this.thisDevice?.deviceId) {
				const channel = this._getOrMakeChannel(channelId);
				const device = this._getOrMakeOtherDevice(deviceId, socketId);
				channel.notifyDeviceJoined(device);
			}
		}

		this._websocketClient.onDeviceUnsubscribed = (deviceId: string, channelIds: string[]) => {
			if (channelIds.length === 0) {
				for (const channel of Object.values(this.channels)) {
					const device = channel.getDeviceOrNull(deviceId);
					if (device) {
						channel.notifyDeviceUnsubscribed(deviceId);
					}
				}
			} else {
				for (const channelId of channelIds) {
					const channel = this._getOrMakeChannel(channelId);
					channel.notifyDeviceUnsubscribed(deviceId);
				}
			}
		}

		this._websocketClient.onDeviceDisconnected = (socketId: string) => {
			for (const device of Object.values(this.otherDevices)) {
				if (device.socketId === socketId) {
					// TODO notify device
					device.socketId = null;
				}
			}
			// TODO notify channels of disconnection?
		}

		this._websocketClient.onMessage = (message: any, deviceId: string, channelId: string) => {
			const sender = this._getOrMakeOtherDevice(deviceId, null);
			sender.notifyRelayFromServer(channelId, message);
		}

	}

}