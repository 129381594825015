
/*
  WebRTCLogger is a class that manages WebRTCLoggerConnections, which contain data for debugging a WebRTCDataConnection.

 */

// determines whether to print log as it happens
var printWebRTCLogAsItHappens = false;

////////////////////////////
// WebRTCLoggerConnection //
////////////////////////////

type LogData = {
	localDescription: string,
	remoteDescription: string,
	candidates: any[],
	errors: any[],
	status: { [key: string]: any },
	whatsNext: string,
	log: any[],
};

// used by the logger to represent a single connection.
export class WebRTCLoggerConnection {

	private _devId: string;
	private _data: LogData;

	constructor(devId: string, data: LogData|null) {
		this._devId = devId;
		if (data) {
			this._data = data;
		} else {
			this._data = {
				localDescription: '(local description was never set)',
				remoteDescription: '(remote description was never set)',
				candidates: [],
				errors: [],
				status: {},
				whatsNext: '(what\'s next was never set)',
				log: [],
			};
		}
	}

	public get devId() {
		return this._devId;
	}

	//////////////
	// printing //
	//////////////

  public printLocalDescription() {
    console.log(this._data.localDescription);
    return 'done';
  }

  public printRemoteDescription() {
    console.log(this._data.remoteDescription);
    return 'done';
  }

  public printCandidates() {
    this._data.candidates.forEach((c) => {
      console.log(c);
    });
    return 'done';
  }

  public printErrors() {
    this._data.errors.forEach((e) => {
      console.log(`At ${ e[1] }:`);
      console.log(e[0]);
    });
    return 'done';
  }

  public printStatus() {
    var status = this._data.status;
    if (Object.keys(status).length === 0) {
      console.log('(status was never set)');
    } else {
      var s = '';
      for (var key in status) {
        s += `${ key }: ${ status[key] } `;
      }
      console.log(s);
    }
    return 'done';
  }

  public printWhatsNext() {
    console.log(this._data.whatsNext);
    return 'done';
  }

  public printLog() {
    this._data.log.forEach(function(msg) {
      console.log(msg);
    });
    return 'done';
	}
	
	///////////////
	// modifying //
	///////////////

	public setLocalDescription(d: any) { this._data.localDescription = d; }
	public setRemoteDescription(d: any) { this._data.remoteDescription = d; }
	public addCandidate(c: any) { this._data.candidates.push(c); }
	public setStatus(key: string, val: any) { this._data.status[key] = val; }
	public setWhatsNext(w: string) { this._data.whatsNext = w; }
	public getData() { return this._data }

	public addError(error: Error, location: any) {
    this._data.errors.push([error.toString(), location]);
	}
	
	public log(msg: any) {
    if (printWebRTCLogAsItHappens) {
      console.log(msg);
    }
    this._data.log.push(msg);
  }

}

//////////////////
// WebRTCLogger //
//////////////////


export class WebRTCLogger {

	private _connections: { [devId: string]: WebRTCLoggerConnection } = {};
	// current connection being debugged
	private _connection: WebRTCLoggerConnection|null = null;

	constructor() {
		// TODO support sending and receiving logger
		/*
		messenger.onMessage('webrtclogger', (message, from) => {
			var vname = `remote${ from }`;
			console.log(`Device ${ from } sent debug data. It is stored in the variable '${ vname }'.`);
			// @ts-ignore
			window[vname] = new WebRTCLoggerConnection(from, message);
		});
		*/
	}

	public newConnection(devId: string) {
    this._connections[devId] = new WebRTCLoggerConnection(devId, null);
    if (!this._connection) {
      this._connection = this._connections[devId];
    }
    return this._connections[devId];
  }

  /////////////////////////
  // printing to console //
  /////////////////////////

  public switchTo(devId: string) {
    if (devId in this._connections) {
      this._connection = this._connections[devId];
      console.log(`Successfully switched to ${ devId }.`);
    } else {
      var keys = Object.keys(this._connections);
      if (keys.length === 0) {
        console.log('There are no connections to debug.');
      } else {
        console.log('Invalid connection. Try rtc.listConnections().');
      }
    }
    return 'done';
  }

  public listConnections() {
    var keys = Object.keys(this._connections);
    var keysString = '';
    keys.forEach((key) => {
      keysString = `${ keysString + key } `;
    });
    console.log(`Available connections: ${ keysString }`);
    return 'done';
  }

  public localDescription() {
    if (!this._connection) {
      console.log('There are no connections to debug.');
      return;
    }
    console.log(`Connection ${ this._connection.devId } local description:`);
    this._connection.printLocalDescription();
    return 'done';
  }

  public remoteDescription() {
    if (!this._connection) {
      console.log('There are no connections to debug.');
      return;
    }
    console.log(`Connection ${ this._connection.devId } remote description:`);
    this._connection.printRemoteDescription();
    return 'done';
  }

  public candidates() {
    if (!this._connection) {
      console.log('There are no connections to debug.');
      return;
    }
    console.log(`Connection ${ this._connection.devId } list of ice candidates:`);
    this._connection.printCandidates();
    return 'done';
  }

  public errors() {
    if (!this._connection) {
      console.log('There are no connections to debug.');
      return;
    }
    console.log(`Connection ${ this._connection.devId } list of errors:`);
    this._connection.printErrors();
    return 'done';
  }

  public status() {
    if (!this._connection) {
      console.log('There are no connections to debug.');
      return;
    }
    console.log(`Connection ${ this._connection.devId } status:`);
    this._connection.printStatus();
    return 'done';
  }

  public whatsNext() {
    if (!this._connection) {
      console.log('There are no connections to debug.');
      return;
    }
    console.log(`Connection ${ this._connection.devId } next step:`);
    this._connection.printWhatsNext();
    return 'done';
  }

  public log() {
    if (!this._connection) {
      console.log('There are no connections to debug.');
      return;
    }
    console.log(`Printing log for connection with id ${ this._connection.devId }...`);
    this._connection.printLog();
    console.log(`... end log for connection with id ${ this._connection.devId }.`);
    return 'done';
  }

  public send() {
    if (!this._connection) {
      console.log('There are no connections to debug.');
      return;
		}
		// TODO the following is old code
		// this._messenger.sendMessageTo('webrtclogger', this._connection.getData(), this._connection.devId);
		console.log("TODO send() failed because messaging mechanism not set up yet")
    return 'done';
  }


  public all() {
    var keys = Object.keys(this._connections);
    if (keys.length === 0) {
      console.log('There are no connections.');
    } else {
      for (var key in this._connections) {
        console.log(`device ${ key } status:`);
        this._connections[key].printStatus();
      }
    }
    return 'done';
  }

}
