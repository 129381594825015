
import Path from './Path'
import Point from '../Point'
import { Canvas } from '../../Canvas'
import Matrix from '../Matrix'
import Rect from '../Rect'
import Parallelogram from '../Parallelogram'
import { LinearPathState } from '../../state/structs'
import PointBufferResource from '../../resources/PointBufferResource'
import SimpleDocument from '../../document/SimpleDocument'

export default class LinearPath extends Path {

  private points: Array<Point>;

  constructor(private pbr: PointBufferResource) {
    super();
    this.points = pbr.points;
  }

  public static fromState(state: LinearPathState, document: SimpleDocument) {
    let pointBufferResource = document.getResource(state.pb);
    if (pointBufferResource) {
      return new LinearPath(pointBufferResource as PointBufferResource);
    } else {
      throw Error(`Unable to load path resource with id ${ state.pb }`);
    }
  }

  public get state(): LinearPathState {
    return {
      t: 'lin',
      pb: this.pbr.id,
    }
  }

  public trace(canvas: Canvas) {
    canvas.drawLinearPath(this.points);
  }

  public computeBoundingRect(matrix: Matrix) {
    let p0 = matrix.timesPoint(this.points[0]);
    let left = p0.x;
    let right = p0.x;
    let top = p0.y;
    let bottom = p0.y;
    for (let i = 1; i < this.points.length; i++) {
      let p = matrix.timesPoint(this.points[i]);
      left = Math.min(left, p.x);
      right = Math.max(right, p.x);
      top = Math.min(top, p.y);
      bottom = Math.max(bottom, p.y);
    }
    return new Rect(left, top, right - left, bottom - top);
  }

  public intersectsParallelogram(parallelogram: Parallelogram) {
    const rect = parallelogram.rect;
    const matrix = parallelogram.inverseMatrix;
    const left = rect.left;
    const top = rect.top;
    const right = rect.right();
    const bottom = rect.bottom();

    // define the states
    type StateType = (p0: Point, p1: Point) => any;
    function stateDoesIntersect(p0: Point, p1: Point): StateType {
      return stateDoesIntersect;
    };
    function stateAbove(p0: Point, p1: Point): StateType {
      if (p1.y < top) {
        return stateAbove;
      }
      if (p0.x < left && p1.x < left) {
        return stateOnLeft;
      }
      if (p0.x > right && p1.x > right) {
        return stateOnRight;
      }
      return stateDoesIntersect;
    }
    function stateOnLeft(p0: Point, p1: Point): StateType {
      if (p1.x < left) {
        return stateOnLeft;
      }
      if (p0.y < top && p1.y < top) {
        return stateAbove;
      }
      if (p0.y > bottom && p1.y > bottom) {
        return stateBelow;
      }
      return stateDoesIntersect;
    }
    function stateOnRight(p0: Point, p1: Point): StateType {
      if (p1.x > right) {
        return stateOnRight;
      }
      if (p0.y < top && p1.y < top) {
        return stateAbove;
      }
      if (p0.y > bottom && p1.y > bottom) {
        return stateBelow;
      }
      return stateDoesIntersect;
    }
    function stateBelow(p0: Point, p1: Point): StateType {
      if (p1.y > bottom) {
        return stateBelow;
      }
      if (p0.x < left && p1.x < left) {
        return stateOnLeft;
      }
      if (p0.x > right && p1.x > right) {
        return stateOnRight;
      }
      return stateDoesIntersect;
    }

    // determine the start state
    let state = stateDoesIntersect;
    let p0 = matrix.timesPoint(this.points[0]);
    if (p0.x < left) {
      state = stateOnLeft;
    }
    if (p0.x > right) {
      state = stateOnRight;
    }
    if (p0.y < top) {
      state = stateAbove;
    }
    if (p0.y > bottom) {
      state = stateBelow;
    }

    // do the iteration
    let i = 1;
    let p1;
    while (state !== stateDoesIntersect
          && i < this.points.length)
    {
      p1 = matrix.timesPoint(this.points[i]);
      state = state(p0, p1);
      p0 = p1;
      i++;
    }

    return state === stateDoesIntersect;
  }

}