
/*
  configuration for webrtc

  + define webRTCIsSupported variable
  + define WebRTC_configuration variable (with ICE servers)
 */

// define a global variable stating whether the webrtc is supported
export let webRTCIsSupported = true;
try { RTCPeerConnection; }
catch(error) {
    webRTCIsSupported = false;
}

// configuration for WebRTC connections
export const WebRTC_configuration = {
	// to check configuration:
	//   https://webrtc.github.io/samples/src/content/peerconnection/trickle-ice/
	// running list of stun servers:
	//   https://gist.github.com/yetithefoot/7592580
	iceServers: [
		{urls: "stun:stun.l.google.com:19302"},
		{urls: "stun:stun1.l.google.com:19302"},
		{urls: "stun:stun2.l.google.com:19302"},
		{urls: "stun:stun3.l.google.com:19302"},
		{urls: "stun:stun4.l.google.com:19302"},
		// TODO turn servers go here
	],
};

// for a good set of sample constraint settings, see
//   https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia

export const WebRTC_constraints_with_video = {
    // TODO consider improving audio constraints
    audio: true,
    video: {
        facingMode: "user",
        frameRate: { ideal: 20, max: 30 },
        width: {ideal: 360}, // matches video width when video is enlarged
        aspectRatio: {ideal: 1.5 },
    },
};

export const WebRTC_constraints_without_video = {
    // TODO consider improving audio constraints
    audio: true,
    video: false,
};
