import Point from './Point';
import { MatrixState } from '../state/structs'

/* Using the same convention as in the canvas, the matrix is defined as
 *
 *     / a c e \
 *     | b d f |
 *     \ 0 0 1 /
 */

export default class Matrix {

  a: number;
  b: number;
  c: number;
  d: number;
  e: number;
  f: number;

  // Represents an affine linear transformation of the two dimensional plane as a 3x3 matrix with 6 parameters, a,b,c,d,e,f. The full matrix is [a c e; b d f; 0 0 1], that is the first, second and third row of this matrix, respectively, are [a c e], [b d f] and [0 0 1]. Every linear transformation of the plane can be represented as a 2x2 matrix [a c; b d], and the two parameters e,f correspond to an affine shifts of the x and y coordinates, respectively.
  constructor(a: number, b: number, c: number, d: number, e: number, f: number) {
    this.a = a;
    this.b = b;
    this.c = c;
    this.d = d;
    this.e = e;
    this.f = f;
  }

  public static fromState(a: MatrixState) {
    return new Matrix(a[0], a[1], a[2], a[3], a[4], a[5]);
  }

  get state(): MatrixState {
    return [this.a, this.b, this.c, this.d, this.e, this.f];
  }

  public static identityMatrix() {
    return new Matrix(1, 0, 0, 1, 0, 0);
  }

  // Returns a scaling matrix. When applied, this matrix takes every point (x,y) to the point (x*scale,y*scale). Note that if the scaling parameter is negative, this is both a scaling of the magnitude of each vector, as well as a reflection.
  public static scaleMatrix(scale: number) {
    return new Matrix(scale, 0, 0, scale, 0, 0);
  }

  // Returns a translation matrix. When applied, this takes every point (x,y) to (x+dx,y+dy).
  public static translateMatrix(dx: number, dy: number) {
    return new Matrix(1, 0, 0, 1, dx, dy);
  }

  // Returns a rotation matrix that rotates by angle theta in the clockwise direction. Remember that since the y coordinate is flipped, a positive rotation will appear to be clockwise.
  public static rotateMatrix(theta: number) {
    var cos = Math.cos(theta);
    var sin = Math.sin(theta);
    return new Matrix(cos, sin, -sin, cos, 0, 0);
  }

  // Returns matrix product this*that.
  public times(that: Matrix) {
    return new Matrix(this.a * that.a + this.c * that.b,
      this.b * that.a + this.d * that.b,
      this.a * that.c + this.c * that.d,
      this.b * that.c + this.d * that.d,
      this.a * that.e + this.c * that.f + this.e,
      this.b * that.e + this.d * that.f + this.f);
  }

  // Returns the matrix-vector product this*point.
  public timesPoint(point: Point) {
    return new Point(this.a * point.x + this.c * point.y + this.e,
                    this.b * point.x + this.d * point.y + this.f);
  }

  public copy() {
    return new Matrix(this.a, this.b, this.c, this.d, this.e, this.f);
  }

  // Returns the determinant of the matrix.
  public det() {
    return this.a * this.d - this.b * this.c;
  }

  public inverse() {
    var det = this.det();
    return new Matrix(this.d / det,
      -this.b / det,
      -this.c / det,
      this.a / det,
      (this.c * this.f - this.d * this.e) / det,
      (this.b * this.e - this.a * this.f) / det);
  }

}
