
/*
	Events triggering a load
	+ WebsocketClient joined, but no other devices are present
			(load from server)
	+ first webrtc connection formed
			(load from the other device)
	+ 3 seconds pass (just to be safe)
			(load from server)
*/

import ServerInterface from "./ServerInterface";
import { BoardState } from "../state/filestructs";
import MutableScene from "../scene/MutableScene";

import { Timer } from "./SaveManager"
import Messenger from "./Messenger";
import Device from "./realtime/Device";


export default class LoadManager {

	private _loadFromServerTimer: Timer = new Timer(3 * 1000);

	constructor(private _boardUrlId: string, private _serverInterface: ServerInterface, private _scene: MutableScene, messenger: Messenger) {
		this._loadFromServerTimer.action = () => {
			console.log("Reached timeout -- will load board file from server.");
			this._loadFromServer();
		}
		this._loadFromServerTimer.startOrContinue();
		messenger.onSubscribe((devices: Device[]) => {
			if (devices.length === 0) {
				console.log("No other devices -- will load board file from server.");
				this._loadFromServerTimer.cancel();
				this._loadFromServer();
			} else {
				// TODO ask devices for state file on webrtc connection
			}
		});
	}

	private async _loadFromServer() {
		const result = await this._serverInterface.getBoardState(this._boardUrlId);
		const boardState = JSON.parse(result);
		this._loadBoardState(boardState);
	}

	private async _loadBoardState(boardState: BoardState) {
		this._scene.loadBoardState(boardState);
	}

}