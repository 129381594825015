
// TODO consider using screenfull (https://www.npmjs.com/package/screenfull)

export function beginFullscreen() {
  const el = document.body as any;
	const f = el.requestFullscreen || el.webkitRequestFullscreen || el.mozRequestFullScreen || el.msRequestFullscreen;
	f.call(el);
}

export function endFullscreen() {
	const doc = document as any;
	const f = document.exitFullscreen || doc.webkitExitFullscreen || doc.mozCancelFullScreen || doc.msExitFullscreen;
	f.call(doc);
}

export function toggleFullscreen() {
	if (isFullscreen()) {
		endFullscreen();
	} else {
		beginFullscreen();
	}
}

export function isFullscreen() {
	const doc = document as any;
	const fullscreenElement = document.fullscreenElement || doc.webkitFullscreenElement || doc.mozFullScreenElement || doc.msFullscreenElement;
	return fullscreenElement ? true : false;
}