
const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz=_";

export default class IdSource {

  public readonly deviceId: string;
  private counter: number = 0; // TODO seed start with deviceId

  constructor(seed: number = -1) {
    if (seed < 0) {
      seed = Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER/2));
    }
    this.counter = seed;
    this.deviceId = this.newId();
  }

  public newId() {
    let str = '';
    let residual = this.counter++;
    while(residual > 0) {
      str += chars[residual % 64];
      residual = Math.floor(residual / 64);
    }
    return str;
  }

}