import Matrix from "./Matrix";
import Point from "./Point";
import { RectState } from "../state/structs";

export default class Rect {

	constructor(public left: number,
							public top: number,
							public width: number,
							public height: number)
	{
	}

	public get state(): RectState {
		return [this.left, this.top, this.width, this.height];
	}

	public static fromState(state: RectState): Rect {
		return new Rect(...state);
	}

	public static fromXYXY(x0: number, y0: number, x1: number, y1: number) {
		let left, right, top, bottom;
		if (x0 < x1) {
			left = x0;
			right = x1;
		} else {
			right = x0;
			left = x1;
		}
		if (y0 < y1) {
			top = y0;
			bottom = y1;
		} else {
			top = y1;
			bottom = y0;
		}
		return new Rect(left, top, right - left, bottom - top);
	}

	public copy(): Rect {
		return new Rect(this.left, this.top, this.width, this.height);
	}

	// Returns a rectangle expanded by a given number of units in each direction. The left and top parameters are substracted by mdl and mdt units respectively, so that the left side and top side are expanded by mdl units and mdt units. The bottom and right parameters are increased by db and dr respectively so that the bottom and right side are expanded by db and dr respectively. For example, if all parameters are set to 5, then the rectangle will be expanded by 5 units in each direction. The function will accept negative numbers as parameters, however there is no check in place to return null if the resulting rectangle does not exist, and so negative numbers could result in an error.
	public expandedBy(mdl: number, mdt: number, dr: number, db: number) {
		return new Rect(this.left - mdl, this.top - mdt, this.width + mdl + dr, this.height + mdt + db);
	}

	public right() {
		return this.left + this.width;
	}

	public bottom() {
		return this.top + this.height;
	}

	public center() {
		return new Point(this.left + this.width / 2, this.top + this.height / 2);
	}

	public intersects(that: Rect) {
		return !(this.right() < that.left ||
						that.right() < this.left ||
						this.bottom() < that.top ||
						that.bottom() < this.top );
	}

	public containsPointXY(x: number, y: number) {
		return (this.left <= x      &&
						this.right() >= x   &&
						this.top <= y       &&
						this.bottom() >= y  );
	};

	public union(that: Rect) {
		const left = Math.min(this.left, that.left);
		const top = Math.min(this.top, that.top);
		const right = Math.max(this.right(), that.right());
		const bottom = Math.max(this.bottom(), that.bottom());
		return new Rect(left, top, right - left, bottom - top);
	}

	public boundsAfterMatrix(matrix: Matrix): Rect {
		const ulPoint = matrix.timesPoint(new Point(this.left, this.top));
		let left = ulPoint.x;
		let right = ulPoint.x;
		let top = ulPoint.y;
		let bottom = ulPoint.y;
		const points = [
			matrix.timesPoint(new Point(this.right(), this.top)),
			matrix.timesPoint(new Point(this.right(), this.bottom())),
			matrix.timesPoint(new Point(this.left, this.bottom())),
		];
		for (let point of points) {
			left = Math.min(left, point.x);
			right = Math.max(right, point.x);
			top = Math.min(top, point.y);
			bottom = Math.max(bottom, point.y);
		}
		return new Rect(left, top, right - left, bottom - top);
	}

}