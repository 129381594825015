import React from "react"

// This manages UI state, and constructs/owns UserInterface and ServerInterface.

import { wsUrl } from "../../services/environment"

// components
import Menubar from "./components/menubar"
import Tools from "./components/tools"
import SceneViewComponent from "./components/sceneview/SceneViewComponent"
import { ShareBtn } from "./components/misc/share"
import { BoardName } from "./components/misc/board_name"

// classes
import { Helmet } from "react-helmet"
import Analytics from "./server/Analytics"
import RealtimeService from "./core/context/realtime/RealtimeService"
import SharedContext from "./core/context/SharedContext"
import ServerInterface from "./server/ServerInterface"
import MathJaxWrapper from "./MathJaxWrapper"
import boards from "../../services/lib/boards"

type Props = {
	boardId: string,
}

type State = {
	tool: { type: string, details?: object },
	selectionAvailable: boolean,
	clipboardAvailable: boolean,
	boardName: string,
	boardRenamable: boolean,
}

export default class BoardContext extends React.Component<Props, State> {

	private _sharedContext: SharedContext;

	// refs
	private _uploadPdfInput: HTMLInputElement | null = null;

	constructor(props) {
		super(props);
		const analytics = new Analytics(/*debug=*/true); // TODO pass ga instead of debug
		const realtimeService = new RealtimeService(wsUrl);
		const serverInterface = new ServerInterface();
		const mathJaxWrapper = new MathJaxWrapper(window);
		this._sharedContext = new SharedContext(analytics, realtimeService, serverInterface, mathJaxWrapper);
		this.state = {
			tool: { type: "selector" },
			selectionAvailable: false,
			clipboardAvailable: false,
			boardName: "",
			boardRenamable: false,
		}
	}

	public async componentDidMount() {
		const board = boards.getBoard(this.props.boardId);
		const boardName = await board.getName();
		const boardRenamable = await board.allowRenaming();
		this.setState({...this.state, boardName, boardRenamable});
	}

	public componentWillUnmount() {
		// TODO disconnect the websocket
	}

	public render() {
		const menubarHeightPx = 32;
		return (
			<div style={{backgroundColor:"grey", width:"100vw", height:"100vh"}}>
				<Helmet>
					<title>{this.state.boardName}</title>
				</Helmet>
				<Menubar
					heightPx={menubarHeightPx}
					addCtx={f=>this._addCtx(f)}
				/>
				<Tools
					tool={this.state.tool}
					addCtx={f=>this._addCtx(f)}
					selectionAvailable={this.state.selectionAvailable}
					clipboardAvailable={this.state.clipboardAvailable}
				/>
				<BoardName editable={this.state.boardRenamable} initialName={this.state.boardName} onNameChange={name=>this._onNameChange(name)} />
				<ShareBtn boardUrlId={this.props.boardId} />
				<div style={{backgroundColor:"green", width:"100%", height:"100%", paddingTop:`${menubarHeightPx}px`}}>
					<SceneViewComponent ref="sceneview" boardContext={this} sharedContext={this._sharedContext} />
				</div>
				<input ref={input => this._uploadPdfInput=input} onChange={(event)=>this._handlePdfUpload(event)} style={{display:"none"}} type="file" accept="application/pdf" />
			</div>
		);
	}

	public triggerPdfUpload() {
		if (this._uploadPdfInput) {
			// the following line is required for 2 reasons.
    	// 1. so if the user cancels the change() event below doesn't trigger
			// 2. so if the user selects the same file, the change() event does trigger
			this._uploadPdfInput.value = "";
			this._uploadPdfInput.click();
		}
	}

	private _handlePdfUpload(event) {
		const canvasContext = this._sceneView().canvasContext;
		canvasContext.onPdfFiles(event.target.files);
	}

	private _sceneView() {
		return this.refs.sceneview as SceneViewComponent;
	}

	private _addCtx(f) {
		return (event) => {
			// TODO This is a bit of a hack
			const canvasContext = this._sceneView().canvasContext;
			if (canvasContext !== null) {
				f(canvasContext);
			} else {
				console.error("canvasContext not available");
			}
		};
	}

	private async _onNameChange(name: string) {
		const board = boards.getBoard(this.props.boardId);
		const success = await board.setName(name);
		if (success) {
			this.setState({...this.state, boardName: name});
		}
	}

}