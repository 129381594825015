
import AbstractUserInterface from "../core/context/UserInterface"
import BoardContext from "../BoardContext";
import Tool from "../core/tools/Tool";

export default class UserInterface extends AbstractUserInterface {

	private _boardContext: BoardContext;
	
	constructor(boardContext: BoardContext) {
		super();
		this._boardContext = boardContext;
	}

	public onToolChange(tool: Tool) {
		this._boardContext.setState({tool:tool.getState()});
	}

	public selectionAvailable() {
		this._boardContext.setState({selectionAvailable: true});
	}

	public selectionNotAvailable() {
		this._boardContext.setState({selectionAvailable: false});
	}

	public clipboardDataAvailable() {
		this._boardContext.setState({clipboardAvailable: true});
	}

	public uploadPdf() {
		this._boardContext.triggerPdfUpload();
	}

}