
/*
	The MatrixControl class represents a draggable rectangle with buttons that can be used to change an item's matrix.
	A MatrixControl is not an Item or ItemT, but is used by ItemTs that want to give the user the ability to move, resize, etc. something.

  The constructor requires
  + initialRect: the initial rectangle in scene coordinates (eg. a bounding rect for a selection)

  Note: Someone else is responsible for reporting mouse events to the MatrixControl.

  To get the total matrix
  + MatrixController.totalMatrix
*/

import Matrix from '../geometry/Matrix'
import Point from '../geometry/Point'
import Rect from '../geometry/Rect'
import { Canvas } from '../Canvas'
import Color from '../geometry/Color'
import Viewport from '../geometry/Viewport'

/*
  __Summary of coordinates and matrices__
  + In the following, "local coordinates" refers to a coordinate system in which button positions are invariant for the duration of the MatrixControl instance.
  + The variable 'matrix' is a transformation from local coordinates to Scene coordinates. If 'matrix' is the identity, the current rectangle is centered at the origin.
  + The variable 'initialTranslateMatrix' is the (translation) matrix translating the initialRect to local coordinates at the time of construction (so that initialRect is centered at the origin).
  + At any given moment, the variable 'totalMatrix' is the matrix transforming initialRect to the "rectangle" that the user sees in scene coordinates. ("rectangle" is in quotes, because it could a priori be a rotated version of a Rect.)
  + Outside of a mouse event sequence, totalMatrix is the composition of matrix with initialTranslateMatrix.
  + During a mouse event sequence, totalMatrix is the composition of postMatrix (defined by a MatrixControlButton during the most recent onDrag() event) with downPreMatrix (the totalMatrix during the most recent onDown() event).
*/

// the (visible) size of a button in pixels
const BTN_SIZE = 17.5;
const LINE_THICKNESS = 1.5;
const BUTTON_VISIBLE_TO_CLICK_RATIO = 0.4; // if <1, then button has a larger click target than it appears

// TODO when allowing rotation
//   calculations for zoomX and zoomY
//   check for angle when returning cursor
//   update acceptsHover to account for protruding button

// A function to draw a rectangle with varying line thickness to account for the zoom
function drawRectAccountingForZooms(canvas: Canvas, rect: Rect, lineWidth: number, zoomX: number, zoomY: number) {
	// Draw horizontal lines
	canvas.setPathWidth(lineWidth / zoomY);
	canvas.drawPath([rect.left, rect.right()], [rect.top, rect.top], 'butt');
	canvas.drawPath([rect.left, rect.right()], [rect.bottom(), rect.bottom()], 'butt');
	// Draw vertical lines
	canvas.setPathWidth(lineWidth / zoomX);
	canvas.drawPath([rect.left, rect.left], [rect.top, rect.bottom()], 'butt');
	canvas.drawPath([rect.right(), rect.right()], [rect.top, rect.bottom()], 'butt');
}

/**
 * class MatrixControlButton (helper for MatrixControl)
 * @param cx x-coordinate of centre of button
 * @param cy y-coordinate of centre of button
 * @param width (clickable) button width
 * @param height (clickable) button height
 * @param thickness thickness of button outline
 * @param color color of button outline
 * @param isCenter true if it is a center button, false otherwise
 */
class MatrixControlButton {

  constructor(
		private cx: number,
		private cy: number,
		private width: number,
		private height: number,
		private thickness: number,
		private color: Color,
		public isCenter: boolean
	)
	{}

  // Note: some of these functions need zoomX and zoomY, because their width in Scene coordinates changes depending on the SceneView's zoom
  containsPoint(x: number, y: number, zoomX: number, zoomY: number) {
    const left = this.cx - this.width / (2 * zoomX);
    const right = this.cx + this.width / (2 * zoomX);
    const top = this.cy - this.height / (2 * zoomY);
    const bottom = this.cy + this.height / (2 * zoomY);
    return (left < x   &&
						right > x  &&
						top < y    &&
						bottom > y );
  }

  draw(canvas: Canvas, zoomX: number, zoomY: number) {
    const radiusX = BUTTON_VISIBLE_TO_CLICK_RATIO * this.width / (2 * zoomX);
    const radiusY = BUTTON_VISIBLE_TO_CLICK_RATIO * this.height / (2 * zoomY);
    const rect = new Rect(this.cx - radiusX, this.cy - radiusY, 2 * radiusX, 2 * radiusY);
		// Draw the white rectangle
		canvas.setFillColor(Color.fromCss('white'));
		// ctx.lineWidth = this.thickness; // what was this for?
    canvas.fillRect(rect);
    // Draw the coloured border
		canvas.setPathColor(this.color);
    drawRectAccountingForZooms(canvas, rect, this.thickness, zoomX, zoomY);
  }

  // Override the following two methods to provide functionality for the button.
  getCursor(matrix: Matrix) {
    return 'default';
	}
	computePostMatrix(p0: Point, p1: Point) {
    return Matrix.identityMatrix();
	}

}


/**
 * class MatrixControl
 * @param buttonOptions an array of booleans specifying which buttons to use
 * [Center, NW, N, NE, E, SE, S, SW, W]
 */
export default class MatrixControl {

	private readonly initialTranslateMatrix: Matrix;
	private _totalMatrix: Matrix;
	private _matrix: Matrix;
	private _inverseMatrix: Matrix;
	private readonly rect: Rect;

	private buttons: MatrixControlButton[];

	private down: null | {
		point: Point,
		matrix: Matrix,
		inverseMatrix: Matrix,
		button: MatrixControlButton,
	};

  constructor(
		private initialRect: Rect,
		private color: Color,
    private showButtons: boolean,
    buttonOptionsArg = [true, true, true, true, true, true, true, true, true],
    )
	{
    this.initialRect = initialRect;
    // attributes for drawing
    this.color = color;
		// which buttons are used? Default: show all nine buttons.
		const buttonOptions = showButtons ? buttonOptionsArg : [];

    // initialize the Matrix Control
    // define the matrices
    const cx = initialRect.left + initialRect.width / 2;
    const cy = initialRect.top + initialRect.height / 2;
    // never changes, translates initialRect to be centered at the origin
    this.initialTranslateMatrix = Matrix.translateMatrix(-cx, -cy);
    // most recently computed total matrix
    this._totalMatrix = Matrix.identityMatrix();
    // this.matrix stores the current transformation from local coordinates to scene coordinates
		this._matrix = Matrix.translateMatrix(cx, cy);
		this._inverseMatrix = this._matrix.inverse();

    // define the rect box in local coordinates
    const wo2 = initialRect.width / 2;
    const ho2 = initialRect.height / 2;
    // never changes, initialRect translated so as to be centered at the origin
    this.rect = new Rect(-wo2, -ho2, wo2 * 2, ho2 * 2);

    // for handling dragging
    // down is an object with point, matrix, inverseMatrix, and button
    this.down = null;

    // define the buttons
    // this.buttons is a list of all buttons that respond to mouse events
    this.buttons = [];

    // center button hack to make the whole thing movable
    if (buttonOptions[0]) {
      const cButton = new MatrixControlButton(0, 0, wo2 * 2, ho2 * 2,
																							LINE_THICKNESS, this.color, // not used
																							true);
      cButton.getCursor = () => { return 'move'; };
      cButton.computePostMatrix = this.drag;
      cButton.draw = () => {};
      // unlike the other buttons, this button will scale with the transformation
      cButton.containsPoint = (x, y, zoomX, zoomY) => {
        const left = -wo2;
        const right = wo2;
        const top = -ho2;
        const bottom = ho2;
        return (left < x   &&
								right > x  &&
								top < y    &&
								bottom > y );
      };
      this.buttons.push(cButton);
		}
		
    // northwest button
    if (buttonOptions[1]) {
      this.pushButton(-wo2, -ho2, 'nw');
    }

    // north button
    if (buttonOptions[2]) {
      this.pushButton(0, -ho2, 'n');
    }

    // northeast button
    if (buttonOptions[3]) {
      this.pushButton(wo2, -ho2, 'ne');
    }

    // east button
    if (buttonOptions[4]) {
      this.pushButton(wo2, 0, 'e');
    }

    // southeast button
    if (buttonOptions[5]) {
      this.pushButton(wo2, ho2, 'se');
    }

    // south button
    if (buttonOptions[6]) {
      this.pushButton(0, ho2, 's');
    }

    // southwest button
    if (buttonOptions[7]) {
      this.pushButton(-wo2, ho2, 'sw');
    }

    // west button
    if (buttonOptions[8]) {
      this.pushButton(-wo2, 0, 'w');
    }
  }

  pushButton(x: number, y: number, buttonName: string) {
    const button = new MatrixControlButton(x, y,
																					 BTN_SIZE, BTN_SIZE, LINE_THICKNESS,
																					 this.color, false);
    button.getCursor = () => { return buttonName.concat('-resize'); };
    button.computePostMatrix = (p0, p1) => {
      const shift = Matrix.translateMatrix(x, y);
      const scaleX = (this.rect.width + Math.sign(x) * (p1.x - p0.x)) / this.rect.width;
      const scaleY = (this.rect.height + Math.sign(y) * (p1.y - p0.y)) / this.rect.height;
      const m = new Matrix(scaleX, 0, 0, scaleY, 0, 0);
      const unshift = Matrix.translateMatrix(-x, -y);
      return unshift.times(m.times(shift));
    };
    this.buttons.push(button);
  }

  // define single drag function for all bar buttons
  drag(p0: Point, p1: Point) {
    return Matrix.translateMatrix(p1.x - p0.x, p1.y - p0.y);
  }

  acceptsHover(x: number, y: number, zoom: number) {
    const localPoint = this._inverseMatrix.timesPoint(new Point(x, y));
    const zoomX = zoom * this._matrix.a;
    const zoomY = zoom * this._matrix.d;

    // If the cursor isn't over the large rectangle, don't check each button.
    const dx = BTN_SIZE / (2 * zoomX);
    const dy = BTN_SIZE / (2 * zoomY);
    if (!this.rect.expandedBy(dx, dy, dx, dy).containsPointXY(localPoint.x, localPoint.y)) {
      return false;
    }

    // Check the buttons
    for (const button of this.buttons) {
      if (button.containsPoint(localPoint.x, localPoint.y, zoomX, zoomY)) {
        return true;
      }
    }
    return false;
  }

  getCursor(x: number, y: number, zoom: number) {
    const localPoint = this._inverseMatrix.timesPoint(new Point(x, y));
    const zoomX = zoom * this._matrix.a;
    const zoomY = zoom * this._matrix.d;

    // Return that button's cursor
    const button = this.getButton(localPoint.x, localPoint.y, zoomX, zoomY);
    return button ? button.getCursor(this._matrix) : 'default';
  }

  /**
   * Returns the button at the point (x, y)
   * @param {number} x in local coords
   * @param {number} y in local coords
   * @param {number} zoomX
   * @param {number} zoomY
   */
  getButton(x: number, y: number, zoomX: number, zoomY: number) {
    const buttonsHit = this.buttons.filter(button => button.containsPoint(x, y, zoomX, zoomY));
    if (!buttonsHit) {
      return null;
    }
    if (buttonsHit.length === 1) {
      return buttonsHit[0];
    }
    // EDGE CASE: if the selection is too small (so that two outside buttons overlap),
    // and there is a center button, use it: shift takes precedence
    const outsideButtonsHit = buttonsHit.filter(button => !button.isCenter);
    if (outsideButtonsHit.length === 1) {
      // only one outside button is hit, use it (even though it overlaps with center)
      return outsideButtonsHit[0];
    }
    // otherwise, two outside buttons overlap, so use the center button, if available
    const centerButtonsHit = buttonsHit.filter(button => button.isCenter);
    return centerButtonsHit ? centerButtonsHit[0] : null;
  }

  acceptsClick(x: number, y: number, zoom: number) {
    return this.acceptsHover(x, y, zoom);
  }

  onDown(x: number, y: number, zoom: number) {
    const localPoint = this._inverseMatrix.timesPoint(new Point(x, y));
    const zoomX = zoom * this._matrix.a;
    const zoomY = zoom * this._matrix.d;

		// Save the initial data, including the button that was hit
		const button = this.getButton(localPoint.x, localPoint.y, zoomX, zoomY);
		if (button) {
			this.down = {
				point: new Point(localPoint.x, localPoint.y),
				matrix: this._matrix.copy(),
				inverseMatrix: this._inverseMatrix.copy(),
				button: button,
			};
		}
  }

  onDrag(x: number, y: number, zoom: number) {
    // If we force stop a drag before onUp is called, this.down might be null.
    // Don't throw an error in this case.
    if (this.down) {
      const localPoint = this.down.inverseMatrix.timesPoint(new Point(x, y));
      const postMatrix = this.down.button.computePostMatrix(this.down.point, localPoint);
      this.matrix = this.down.matrix.times(postMatrix);
      this.totalMatrix = this._matrix.times(this.initialTranslateMatrix);
    }
  }

  onUp() {
    this.down = null;
  }

  drawOnCanvas(canvas: Canvas, viewport: Viewport) {
		const left = viewport.left;
		const top = viewport.top;
		const zoom = viewport.zoom;
    canvas.save();
    canvas.translate(-left * zoom, -top * zoom);
		canvas.setPathColor(this.color);
    canvas.setPathWidth(LINE_THICKNESS);
    // we cannot draw a rectangle if there is rotation, so we just draw four segments
    // start with the points in the rectangle
    const points = [
      new Point(this.initialRect.left, this.initialRect.top),
      new Point(this.initialRect.right(), this.initialRect.top),
      new Point(this.initialRect.right(), this.initialRect.bottom()),
      new Point(this.initialRect.left, this.initialRect.bottom()),
		].map(point => this._totalMatrix.timesPoint(point));
		canvas.drawPath([
			points[0].x * zoom,
			points[1].x * zoom,
			points[2].x * zoom,
			points[3].x * zoom,
			points[0].x * zoom,
		],
		[
			points[0].y * zoom,
			points[1].y * zoom,
			points[2].y * zoom,
			points[3].y * zoom,
			points[0].y * zoom,
		]);
    canvas.restore();
    if (this.showButtons) {
      canvas.save();
      canvas.transform(viewport);
			// switch to local coordinates
			canvas.transformMatrix(this._matrix);
      const zoomX = zoom * this._matrix.a;
			const zoomY = zoom * this._matrix.d;
			canvas.setFillColor(this.color);
      for (var i = this.buttons.length - 1; i >= 0; i--) {
        this.buttons[i].draw(canvas, zoomX, zoomY);
      }
      canvas.restore();
    }
  }

  private set matrix(matrix: Matrix) {
    this._matrix = matrix;
    this._inverseMatrix = matrix.inverse();
  }

  // assuming this is not during a drag
  set totalMatrix(matrix: Matrix) {
    this._totalMatrix = matrix.copy();
    this.matrix = this.totalMatrix.times(this.initialTranslateMatrix.inverse());
  }

  get totalMatrix() {
    return this._totalMatrix;
  }

  get isDragging() {
    return !this.down;
	}

}

