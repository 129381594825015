
import Viewport from './geometry/Viewport'
import { Canvas, ICanvasEventHandler, IMouseEventHandler, IKeyEventHandler, KeyEvent } from './Canvas'
import Scene from './scene/Scene'
import Tool from './tools/Tool'
import LocalAgent from './agent/local/LocalAgent'
import Point from './geometry/Point'
import UserInterface from './context/UserInterface'
import Rect from './geometry/Rect'

export interface ICapabilities {
  mouse?: boolean,
  wheel?: boolean,
  keyboard?: boolean,
}

const DISCRETE_ZOOM_FACTOR = 1.25;
const DEFAULT_ZOOM = DISCRETE_ZOOM_FACTOR * DISCRETE_ZOOM_FACTOR; // why?
const MAX_ZOOM = DEFAULT_ZOOM * Math.pow(DISCRETE_ZOOM_FACTOR, 8);
const MIN_ZOOM = DEFAULT_ZOOM * Math.pow(DISCRETE_ZOOM_FACTOR, -8);

export default class SceneView implements ICanvasEventHandler, IMouseEventHandler, IKeyEventHandler
{

  public tool: Tool | null;

  readonly canvas: Canvas;
  private scene: Scene;
  public viewport: Viewport; // probably should be private
  private sceneGrabbedMouse: boolean = false;

  constructor(scene: Scene, canvas: Canvas, capabilities: ICapabilities, private localAgent: LocalAgent, public readonly ui: UserInterface) {
    this.tool = null;
    this.canvas = canvas;
    this.scene = scene;
    this.viewport = new Viewport(0, 0, DEFAULT_ZOOM);
    canvas.registerCanvasEvents(this);
    if (capabilities.mouse) {
      canvas.registerMouseEvents(this);
    }
    if (capabilities.wheel) {
      canvas.registerWheelEvents(this);
    }
    if (capabilities.keyboard) {
      canvas.registerKeyEvents(this);
    }
    scene.addSceneView(this);
  }

  public getViewRect() {
    // Note: This calls canvas.width(), which currently returns canvas.clientWidth
    //       There is also the canvas.width property. It is not clear if one choice suffices.
    //       The choice of canvas.clientWidth was made so zoomIn() and zoomOut() work.
    return new Rect(
      this.viewport.left,
      this.viewport.top,
      this.canvas.width() / this.viewport.zoom,
      this.canvas.height() / this.viewport.zoom,
    );
  }

  public zoomIn() {
    const center = this.getViewRect().center();
    this.onZoom(center.x, center.y, DISCRETE_ZOOM_FACTOR);
  }

  public zoomOut() {
    const center = this.getViewRect().center();
    this.onZoom(center.x, center.y, 1.0 / DISCRETE_ZOOM_FACTOR);
  }

  ////////////////////
  // event handling //
  ////////////////////

  public onCanvasLoaded() {
    // this may never get called -- canvas could load before canvas.registerCanvasEvents(this)
    this.scene.displayOnSceneView(this);
  }

  public onCanvasResized() {
    this.scene.displayOnSceneView(this);
  }

  public onMouseHover(x: number, y: number) {
    const p = this.viewport.viewToSceneMatrix().timesPoint(new Point(x, y));
    this.localAgent.updateHaloXY(p.x, p.y);
  }

  public onMouseDown(x: number, y: number, event: MouseEvent) {
    this.sceneGrabbedMouse = this.scene.onDown(x, y, this.viewport, event);
    if (!this.sceneGrabbedMouse && this.tool) {
      this.tool.onDown(x, y, this.viewport, this.scene);
    }
  }

  public onMouseDrag(x: number, y: number) {
    if (this.sceneGrabbedMouse) {
      this.scene.onDrag(x, y, this.viewport);
    } else if (this.tool) {
      this.tool.onDrag(x, y, this.viewport, this.scene);
    }
  }

  public onMouseUp() {
    if (this.sceneGrabbedMouse) {
      this.scene.onUp();
    } else if (this.tool) {
      this.tool.onUp(this.scene);
    }
    this.sceneGrabbedMouse = false;
  }

  public onPan(dx: number, dy: number) {
    this.viewport = this.viewport.pannedBy(dx, dy);
    this.scene.displayOnSceneView(this);
  }

  public onZoom(x: number, y: number, factor: number) {
    let oldZoom = this.viewport.zoom;
    let newZoom = Math.min(MAX_ZOOM, Math.max(MIN_ZOOM, oldZoom * factor));
    this.viewport = this.viewport.zoomedTo(newZoom, x , y);
    this.scene.displayOnSceneView(this);
  }

  public onKeyDown(event: KeyEvent) {
    if (event.metaKey && event.key==="x") {
      this.scene.doCopy();
      return this.scene.doDelete();
    }
    if (event.metaKey && event.key==="c") {
      return this.scene.doCopy();
    }
    if (event.metaKey && event.key==="v") {
      return this.scene.doPaste(this);
    }
    return this.scene.onKeyDown(event);
  }

  public doCopy() {
    this.scene.doCopy();
  }

  public doPaste() {
    this.scene.doPaste(this);
  }

  public doDelete() {
    this.scene.doDelete();
  }
 
}