////////////////////////////////////////////////////////////////////////////////
// TextNewLineInput                                                           //
// + default length and minify methods                                        //
////////////////////////////////////////////////////////////////////////////////

import TextInput, { TextInputType } from './TextInput';
import { NewLineTextInputState } from '../../state/text';

export default class NewLineTextInput extends TextInput {

  constructor() {
    super(TextInputType.new_line);
  }

  static fromState(state: NewLineTextInputState) {
    return new NewLineTextInput();
  }

  get state(): NewLineTextInputState {
    return {
      t: 'nl',
    };
  }

  get length() {
    return 0;
  }

  public charAt(charPos: number) {
    return (charPos === 0 ? '\n' : '');
  }

  toString(beginCharPos: number, endCharPos: number) {
    if (beginCharPos === 0 && endCharPos > 0) {
      return '\n';
    }
    return '';
  }
}