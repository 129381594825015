
import { PathState } from "../../state/structs"
import LinearPath from "./LinearPath"
import SimpleDocument from "../../document/SimpleDocument"

// this helps avoid a circular dependency in which Path must import eg. LinearPath
export default function Path_fromState(state: PathState, document: SimpleDocument) {
	switch(state.t) {
		case 'lin':
			return LinearPath.fromState(state, document);
			break;
	}
}