////////////////////////////////////////////////////////////////////////////////
// LatexTextInput                                                             //
////////////////////////////////////////////////////////////////////////////////

import TextInput, { TextInputType } from './TextInput';
import TextTextInput from './TextTextInput';
import { LatexTextInputState } from '../../state/text';

export default class LatexTextInput extends TextInput {

  constructor(public latex: string) {
    super(TextInputType.latex);
  }

  static fromState(state: LatexTextInputState) {
    return new LatexTextInput(state.l);
  }

  get state(): LatexTextInputState {
    return {
      t: 'latex',
      l: this.latex,
    };
  }

  get length() {
    return this.latex.length; // TODO: fix me? assumes latex is a string?
  }

  public charAt() {
    return '';
  }

  public toString(beginCharPos: number, endCharPos: number) {
    if (beginCharPos === 0 && endCharPos > 0) {
      return `$${ this.latex }$`;
    }
    return '';
  }

  public revertToText() {
    return new TextTextInput(`$${ this.latex }$`);
  }

}
