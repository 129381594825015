////////////////////////////////////////////////////////////////////////////////
//  MutableText methods:                                                      //
//      + drawOnContext(ctx)                                                  //
//      + isLatex()                                                           //
//      + getLatex()                                                          //
//      + getHeight()                                                         //
//      + insert(rawInput)                                                    //
//      + delete(isBackspace)                                                 //
//      + moveCursorHorizontally(dCol)                                        //
//      + moveCursorHorizontallyByWords(dWords)                               //
//      + moveCursorToStart()                                                 //
//      + moveCursorToEnd()                                                   //
//      + updatePosition(newOverallCharPos, makeSelection)                    //
//      + updateLines()                                                       //
//      + setCursor()                                                         //
//      + placeCursor(x, y)                                                   //
//      + preprocessLatex()                                                   //
//      + isEmpty()                                                           //
//      + moveCursor(dRow)      TODO                                          //
//      + selectTo(x, y)        TODO                                          //
//      + format(property, value)       TODO                                  //
//      + getSelection()         TODO                                         //
////////////////////////////////////////////////////////////////////////////////

import TextBuffer, { RawInput } from './TextBuffer';
import TextLines from './TextLines';
import TextInput, { TextInputType } from './input/TextInput';
import BufferPosition from './BufferPosition';
import CursorPosition from './CursorPosition';
import MutableScene from '../scene/MutableScene';
import { SvgCache } from './_svg';
import Scene from '../scene/Scene';
import { FormattingObject } from './TextFormatting';

const CURSOR_THICKNESS = 0.8;
const CURSOR_ASCENT_RATIO = 0.8; // cursor ascent : line ascent ratio
const CURSOR_DESCENT_RATIO = 0.5; // cursor descent : line descent ratio

export default class MutableText {

  // I made guesses for these types
  private _changeListener: () => void;
  private _semaphore: number;
  private _changePending: boolean;
  private _buffer: TextBuffer;
  private _cursorPos: CursorPosition;
  private _lines: TextLines;
  private cursorTimer: any; // output of setInterval()
  private cursorIsShowing: boolean = false;
  private cursor: { x: number, y: number, ascent: number, descent: number } | null = null;
  private _width: number;
  private _scene: Scene;

  constructor(scene: Scene, initialWidth: number, buffer: TextBuffer|null, private _svgCache: SvgCache) {
    this._scene = scene;
    this._changeListener = () => {};
    this._semaphore = 0;
    this._changePending = false;
    this.beginChanges();
    // buffer stores the inputs
    this._buffer = (buffer ? buffer : new TextBuffer([]));
    // TODO: when a TextItem converts to a PreTextItemT, we want to
    // pace the initial cursor corresponding to the click position

    // cursorPos points to the current position or selection.
    const bufferPos = new BufferPosition(0, 0);
    this._cursorPos = new CursorPosition(bufferPos, bufferPos);

    // lines[i].buffer is an array of outputs to be printed on the ith line.
    // Each line has properties buffer, ascent, and descent
    this._width = initialWidth; // needed to satisfy constructor constraint
    this._lines = new TextLines(this._buffer, this.width, this._svgCache, scene);
    this.width = initialWidth; // calls updateLines() and setCursor()

    //////////////////////////////////////////////////////////////////////////////
    // the cursor has properties (all distances in scene coordinates)           //
    // + line: the line containing the cursor                                   //
    // + horizDist: horizontal distance between cursor and left margin          //
    // + ascent: the ascent of the output containing the cursor                 //
    // + descent: the descent of the output containing the cursor               //
    // + x: x-coordinate of the cursor                                          //
    // + y: y-coordinate of the baseline of the output containing the cursor    //
    //////////////////////////////////////////////////////////////////////////////
    this.cursorTimer = setInterval(() => {
      this.cursorIsShowing = !this.cursorIsShowing;
      scene.redisplay();
    }, 500);
    this.endChanges();
  }

  public get lines() {
    return this._lines;
  }

  public get buffer() {
    return this._buffer;
  }

  /**
   * Draws the MutableText and the flashing cursor onto the canvas.
   */
  drawOnContext(ctx: CanvasRenderingContext2D) {
    this._lines.drawOnContext(ctx);

    // draw cursor
    if (this.cursorIsShowing && !!this.cursor) {
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = CURSOR_THICKNESS;
      ctx.strokeStyle = 'black';
      ctx.moveTo(this.cursor.x,
        this.cursor.y + CURSOR_DESCENT_RATIO * this.cursor.descent);
      ctx.lineTo(this.cursor.x,
        this.cursor.y - CURSOR_ASCENT_RATIO * this.cursor.ascent);
      ctx.stroke();
      ctx.restore();
    }

    // draw selection
    if (this.cursorPos.isSelection) {
      ctx.save();
      ctx.globalAlpha = 0.4;
      ctx.fillStyle = 'rgb(0, 200, 255)';
      const rects = this._lines.getSelectionRects(this.cursorPos);
      for (const rect of rects) {
        ctx.fillRect(rect.left, rect.top, rect.width, rect.height);
      }
      ctx.restore();
    }
  }

  /**
   * called by PreTextItemT.onClickedAway(), to decide whether to convert
   * to an EquationItem or a TextItem.
   */
  isLatex() {
    let inputCount = 0;
    for (var input of this._buffer.inputs) {
      if (input.length !== 0) {
        inputCount++;
        // if its a second nontrivial input or if it's the first and it isn't latex, we stop.
        if (inputCount > 1 || input.type !== TextInputType.latex) { return false; }
      }
    }

    return (inputCount === 1);
  }

  /**
   * Called only if isLatex is true
   */
  // FIXME: we should have getLatex return the latex input if isLatex is true, else null.
  // This is only called in PreTextItem, so doing this saves us work.
  /*
  getLatex() {
    for (let input of this.buffer.inputs) {
      if (input.length !== 0) {
        return input.latex;
      }
    }
    // if we get here, isLatex is false
    return null;
  }
  */

  get height() {
    return this._lines.height;
  }

  /**
   * @param rawInput an object with property type, and some data.
   * At present, supports rawInput of types 'string' and 'formatting'.
   * 'string' rawInputs have a string property, which can include new lines
   * 'formatting' rawInputs have a formatting property, which is a property-value
   * pair.
   */
  insert(rawInput: RawInput) {
    this.beginChanges();
    if (!this.cursorPos.isSelection) {
      const bufferPos = this._buffer.insert(this.cursorPos.pivotPos, rawInput);
      this.cursorPos = new CursorPosition(bufferPos, bufferPos);
    } else {
      // Unreachable given our usage...an insertion when there's a selection always
      // triggers deletion of the selection, so that this.cursorPos.isSelection is always false.
    }
    this.updateLines();
    this.endChanges();
  }

  /**
   * Delete a character.
   * @param {boolean} isBackspace true if this resulted from pressing backspace instead of delete, else false
   */
  delete(isBackspace: boolean) {
    this.beginChanges();
    let bufferPos;
    if (!this.cursorPos.isSelection) {
      // backspace and delete have different meanings
      if (isBackspace) {
        bufferPos = this._buffer.deleteCharBefore(this.cursorPos.pivotPos);
      } else {
        bufferPos = this._buffer.deleteCharAfter(this.cursorPos.pivotPos);
      }
    } else {
      // delete the selection
      bufferPos = this._buffer.deleteSelection(this.cursorPos);
    }
    this.cursorPos = new CursorPosition(bufferPos, bufferPos);
    this.updateLines();
    this.endChanges();
  }

  /**
   * Move the bufferPos horizontally by dCol (right if positive, left if
   * negative). Clamped by 0 <= charPos <= this.buffer.length
   * @param dCol number of columns to move by.
   */
  moveCursorHorizontally(dCol: number, makeSelection: boolean) {
    this.beginChanges();
    const oldOverallCharPos = this._buffer.getOverallCharPos(this.cursorPos.reachPos);
    const newOverallCharPos =
      Math.min(Math.max(0, oldOverallCharPos + dCol), this._buffer.length);
    this.updatePosition(newOverallCharPos, makeSelection);
    this.endChanges();
  }

  /**
   * Moves the bufferPos vertically by dRow (down if positive, up if negative).
   * @param dRow number of rows to move by.
   * Updates this.cursorPos
   */
  moveCursorVertically(dRow: number, makeSelection: boolean) {
    this.beginChanges();
    const line = this._lines.getLineContainingBufferPos(this.cursorPos.reachPos);
    const lastLine = this._lines.lines.length - 1;
    if (dRow < 0 && line === 0) {
      this.moveCursorToStart(makeSelection);
    } else if (dRow > 0 && line === this._lines.lines.length - 1) {
      this.moveCursorToEnd(makeSelection);
    } else {
      const newLine = Math.max(Math.min(line + dRow, lastLine), 0);
      const horizDist = this._lines.getHorizontalDistInLine(line, this.cursorPos.reachPos);
      const bufferPos = this._lines.getPositionInLine(newLine, horizDist);
      // only change pivotPos if not making a selection
      this.cursorPos = this.cursorPos.updatePos(bufferPos, !makeSelection);
    }
    this.endChanges();
  }

  /**
   * Move the charPos horizontally by dWords words (right if positive, left if negative).
   * Moves right until the first space after this word (but not just simply whitespace).
   * Analogously, moves left until the last space before this word.
   * @param dWords number of words to move by; positive for forward, negative for backward
   * @param makeSelection whether to make a selection (moving the range only) or not
   * (moving both pivot and range).
   */
  moveCursorHorizontallyByWords(dWords: number, makeSelection: boolean) {
    if (dWords === 0) { // NOP
      return;
    }
    let wordsToMove = Math.abs(dWords);
    let seenNonWhitespace = false;
    let overallCharPos = this._buffer.getOverallCharPos(this.cursorPos.reachPos);
    if (dWords > 0) {
      while (wordsToMove > 0 && overallCharPos < this._buffer.length) {
        const c = this._buffer.charAt(overallCharPos);
        if (c === ' ' || c === '\n') {
          if (seenNonWhitespace) {
            wordsToMove--;
            if (wordsToMove > 0) {
              seenNonWhitespace = false;
            } else {
              break;
            }
          }
        } else {
          seenNonWhitespace = true;
        }
        overallCharPos++;
      }
    } else {
      while (wordsToMove > 0 && overallCharPos > 0) {
        const c = this._buffer.charAt(overallCharPos - 1);
        if (c === ' ' || c === '\n') {
          if (seenNonWhitespace) {
            wordsToMove--;
            if (wordsToMove > 0) {
              seenNonWhitespace = false;
            } else {
              break;
            }
          }
        } else {
          seenNonWhitespace = true;
        }
        overallCharPos--;
      }
    }
    // TODO: this doesn't actually
  }

  moveCursorToStart(makeSelection: boolean) {
    this.updatePosition(0, makeSelection);
  }

  moveCursorToEnd(makeSelection: boolean) {
    this.updatePosition(this._buffer.length, makeSelection);
  }

  selectAll() {
    this.beginChanges();
    // update in two steps
    this.moveCursorToStart(false);
    this.moveCursorToEnd(true);
    this.endChanges();
  }

  /**
   * This method is called when the cursor is moved. It only takes effect if the
   * overall character position will be changed. In this case, it recalculates
   * the buffer position.
   * @param {number} newOverallCharPos
   * @param {Boolean} makeSelection
   */
  updatePosition(newOverallCharPos: number, makeSelection: boolean) {
    if (this.cursorPos.isSelection || (newOverallCharPos !== this._buffer.getOverallCharPos(this.cursorPos.pivotPos))) {
      const bufferPos = this._buffer.getBufferPos(newOverallCharPos);
      // only change pivotPos if not making a selection
      this.cursorPos = this.cursorPos.updatePos(bufferPos, !makeSelection);
    }
  }

  updateLines() {
    this._lines = new TextLines(this._buffer, this.width, this._svgCache, this._scene);
    this.makeDirty();
  }

  /**
   * reset the cursor after finding a new buffer pos
   */
  setCursor() {
    this.cursor = this._lines.getCursor(this.cursorPos.reachPos);
    this.cursorIsShowing = true;
  }

  /**
   * @param {Number} x x-coordinate (in lines coordinates)
   * @param {Number} y y-coordinate (in lines coordinates)
   * @param {Boolean} makeSelection true to make a selection, false otherwise
   * side effects: this.cursorPos is updated to point to the character in the
   * input buffer closest to (x, y) and is made a non-selection (pivot === reach)
   * this.cursor is updated to reflect the update to bufferPos
   */
  placeCursor(x: number, y: number, makeSelection: boolean) {
    const newPosData = this._lines.placeCursor(x, y);
    const bufferPos = newPosData.bufferPos;
    this.cursorPos = this.cursorPos.updatePos(bufferPos, !makeSelection);
    this.cursor = this._lines.getCursor(bufferPos, newPosData.line);
  }

  /*
  preprocessLatex() {
    this.buffer = this.buffer.preprocessLatex();
  }
  */

  isEmpty() {
    return this._buffer.length === 0;
  }

  turnOffCursor() {
    clearInterval(this.cursorTimer);
  }

  get cursorPos() {
    return this._cursorPos;
  }

  set cursorPos(newPos) {
    if (!this._cursorPos.equals(newPos)) {
      this._cursorPos = newPos;
      this.setCursor();
      this.makeDirty();
    }
  }

  set width(newWidth) {
    this._width = newWidth;
    this.updateLines();
    this.setCursor();
  }

  get width() {
    return this._width;
  }

  getSelectionAsText() {
    return this._buffer.getSelectionText(this.cursorPos);
  }

  get changeListener() {
    return this._changeListener;
  }

  // sets the function handler to be called when this MutableText changes.
  set changeListener(handler) {
    this._changeListener = handler || (() => {});
  }

  beginChanges() {
    this._semaphore++;
  }

  endChanges() {
    this._semaphore--;
    if (this._semaphore <= 0 && this._changePending) {
      this._changeListener();
      this._changePending = false;
    }
  }

  makeDirty() {
    if (this._semaphore <= 0) {
      // fire immediately
      this._changeListener();
    } else {
      // fire only at the end of the transaction
      this._changePending = true;
    }
  }
}