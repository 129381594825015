import React, { useState, useEffect, useRef } from "react"

const css = require("./board_name.module.css") as any;

type Props = {
	editable: boolean,
	initialName: string,
	onNameChange: (newName: string) => void,
}

export const BoardName = (props: Props) => {
	const [state, setState] = useState({
		name: props.initialName,
		oldName: props.initialName,
		initialName: props.initialName,
		width: 0,
	});
	const inputRef = useRef(null);
	const spanRef = useRef(null);
	
	useEffect(() => {
		// detect whether initialName prop changed and update accordingly
		if (props.initialName !== state.initialName) {
			setState({
				...state,
				name: props.initialName,
				oldName: props.initialName,
				initialName: props.initialName,
			});
			return;
		}
		// every time, we should check to make sure the state width matches the span's width
		if (spanRef.current) {
			const newWidth = spanRef.current.offsetWidth as number;
			if (state.width !== newWidth) {
				setState({...state, width: newWidth});
			}
		}
	});

	const onChange = (event) => {
		setState({...state, name: event.target.value});
	}

	const onKeyDown = (event) => {
		if (event.keyCode === 13) {
			if (inputRef.current) {
				inputRef.current.blur();
			}
		}
	}

	const onBlur = (event) => {
		if (state.name !== state.oldName) {
			props.onNameChange(state.name);
			setState({...state, oldName: state.name});
		}
	}

	return (
		<div className={css.board_name_div}>
			<input
				ref={inputRef}
				className={css.board_name}
				style={{width:`${state.width+10}px`}}
				value={state.name}
				onChange={onChange}
				onKeyDown={onKeyDown}
				onBlur={onBlur}
				disabled={!props.editable}
			/>
			<div>
				<span ref={spanRef} className={css.get_text_width}>{state.name}</span>
			</div>
		</div>
	);
}