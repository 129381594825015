import React from "react"

const css = require("../toolbar.module.css");
const segmentImg = require("../icons/segment.svg");
const arrowImg = require("../icons/arrow.svg");
const rectangleImg = require("../icons/rectangle.svg");
const ellipseImg = require("../icons/ellipse.svg");

import {
	onShapeArrowToolBtnClicked,
	onShapeEllipseToolBtnClicked,
	onShapeRectangleToolBtnClicked,
	onShapeSegmentToolBtnClicked,
} from "../../../ui/buttonCallbacks"

export default ({tool, addCtx}) => (
	<div
		className={css.horizontalButtons}
		style={{position: "fixed", left:"45px", top:"37px"}}
	>
		<button
			className={`${css.btn} ${tool.details.subType==="segment" && css.btnOn}`}
			onClick={addCtx(onShapeSegmentToolBtnClicked)}
		>
			<img style={{width:"20px", height:"20px"}} src={segmentImg} />
		</button>
		<button
			className={`${css.btn} ${tool.details.subType==="arrow" && css.btnOn}`}
			onClick={addCtx(onShapeArrowToolBtnClicked)}
		>
			<img style={{width:"20px", height:"20px"}} src={arrowImg} />
		</button>
		<button
			className={`${css.btn} ${tool.details.subType==="rectangle" && css.btnOn}`}
			onClick={addCtx(onShapeRectangleToolBtnClicked)}
		>
			<img style={{width:"20px", height:"20px"}} src={rectangleImg} />
		</button>
		<button
			className={`${css.btn} ${tool.details.subType==="ellipse" && css.btnOn}`}
			onClick={addCtx(onShapeEllipseToolBtnClicked)}
		>
			<img style={{width:"20px", height:"20px"}} src={ellipseImg} />
		</button>
	</div>
);