////////////////////////////////////////////////////////////////////////////////
// TextFormatting                                                             //
////////////////////////////////////////////////////////////////////////////////

// Note: currently, the TextTool adds the default formatting to a buffer. (See TextTool.applyFormatting().)

// Note: some formatting is not actually supported in rendering yet. To see how formatting gets used for rendering, look at TextTextOutput.drawOnCanvas().

import Color from '../geometry/Color';

export enum TextFormattingType {
  Size = 'size',
  Font = 'font',
  Color = 'color',
  Bold = 'bold',
  Italic = 'italic',
  Underline = 'underline',
  Strikeout = 'strikeout',
  Align = 'align',
  Script = 'script',
}

export type FormattingObject = {
  property: TextFormattingType,
  value: any,
}

export default class TextFormatting {

  public size: number;
  public font: string;
  public color: Color;
  public bold: boolean;
  private italic: boolean;
  private underline: boolean;
  private strikeout: boolean;
  private align: string;
  private script: string;

  constructor() {
    // default formatting
    this.size = 10;
    this.font = 'sans-serif';
    this.color = new Color(0, 0, 0); // black
    this.bold = false;
    this.italic = false;
    this.underline = false;
    this.strikeout = false;
    this.align = 'left';
    this.script = 'normal';
  }

  /**
   * from carota/src/text.js, getFontString()
   * TODO: deal with super/subscripts
   */
  get fontString() {
    return `${ (this.italic ? 'italic ' : '') + (this.bold ? 'bold ' : '')  } ${
      this.size  }px ${  this.font }`;
  }

  /**
   * TextTextOutput constructor uses this method so that TextTextOutputs
   * are immutable.
   */
  copy() {
    const copy = new TextFormatting();
    copy.size = this.size;
    copy.font = this.font;
    copy.color = this.color;
    copy.bold = this.bold;
    copy.italic = this.italic;
    copy.underline = this.underline;
    copy.strikeout = this.strikeout;
    copy.align = this.align;
    copy.script = this.script;
    return copy;
  }

  public formats(): FormattingObject[] {
    return [
      { property: TextFormattingType.Size, value: this.size },
      { property: TextFormattingType.Font, value: this.font },
      { property: TextFormattingType.Color, value: this.color },
      { property: TextFormattingType.Bold, value: this.bold },
      { property: TextFormattingType.Italic, value: this.italic },
      { property: TextFormattingType.Underline, value: this.underline },
      { property: TextFormattingType.Strikeout, value: this.strikeout },
      { property: TextFormattingType.Align, value: this.align },
      { property: TextFormattingType.Script, value: this.script },
    ];
  }

}