
export interface PDFJS_PAGE {
	pageNumber: number,
	getViewport: (params: {scale: number}) => { width: number, height: number },
}

export interface PDFJS_PDF {
	getPage: (pageIndex: number) => Promise<PDFJS_PAGE>,
	numPages: number,
}

export default abstract class ServerInterface {

	public abstract async saveBoardState(state: string, boardUrlId: string): Promise<void>;
	public abstract async getBoardState(boardUrlId: string): Promise<string>;

	public abstract async uploadPdfFile(file: File, boardUrlId: string): Promise<{ pdfUrlId: string }>;
	public abstract async getPdfFile(pdfUrlId: string, boardUrlId: string): Promise<PDFJS_PDF>;

}